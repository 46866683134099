import * as React from 'react';
type IconErrorProps = React.SVGProps<SVGSVGElement>;

export const IconError: React.FC<IconErrorProps> = (props) => (
	<svg
		width="42"
		height="43"
		viewBox="0 0 42 43"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21 42.5C26.5695 42.5 31.911 40.2875 35.8492 36.3492C39.7875 32.411 42 27.0695 42 21.5C42 15.9305 39.7875 10.589 35.8492 6.65076C31.911 2.71249 26.5695 0.5 21 0.5C15.4305 0.5 10.089 2.71249 6.15076 6.65076C2.21249 10.589 0 15.9305 0 21.5C0 27.0695 2.21249 32.411 6.15076 36.3492C10.089 40.2875 15.4305 42.5 21 42.5ZM21 11C22.091 11 22.9688 11.8777 22.9688 12.9688V22.1562C22.9688 23.2473 22.091 24.125 21 24.125C19.909 24.125 19.0312 23.2473 19.0312 22.1562V12.9688C19.0312 11.8777 19.909 11 21 11ZM18.375 29.375C18.375 28.6788 18.6516 28.0111 19.1438 27.5188C19.6361 27.0266 20.3038 26.75 21 26.75C21.6962 26.75 22.3639 27.0266 22.8562 27.5188C23.3484 28.0111 23.625 28.6788 23.625 29.375C23.625 30.0712 23.3484 30.7389 22.8562 31.2312C22.3639 31.7234 21.6962 32 21 32C20.3038 32 19.6361 31.7234 19.1438 31.2312C18.6516 30.7389 18.375 30.0712 18.375 29.375Z"
			fill="#F8A0D2"
		/>
	</svg>
);
export default IconError;
