import { useTranslation } from "react-i18next";

interface FriendlyDateProps {
  date: string; // Formato esperado: "DD-MM-YYYY"
  locale?: string; // Opcional, por defecto "es-ES"
}

export const FriendlyDate: React.FC<FriendlyDateProps> = ({ date, locale = "es-ES" }) => {
  const { i18n } = useTranslation(); // Obtener el idioma actual de i18n

  const formatDate = (dateString: string, locale: string): string => {
    // Validar formato de fecha (DD-MM-YYYY)
    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
    if (!datePattern.test(dateString)) {
      
      return dateString; // Retorna un mensaje predeterminado o vacío
    }

    const [day, month, year] = dateString.split("-").map(Number); // Convertir a números
    const dateObj = new Date(year, month - 1, day); // Crear un objeto Date

    // Verificar si la fecha es válida
    if (isNaN(dateObj.getTime())) {
      
      return dateString; // Retorna un mensaje predeterminado o vacío
    }

    return new Intl.DateTimeFormat(locale, {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(dateObj);
  };

  return <>{formatDate(date, i18n.language)}</>;
};
