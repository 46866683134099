import * as React from 'react';

type IconCirclexmarkProps = React.SVGProps<SVGSVGElement>;

export const IconCirclexmark: React.FC<IconCirclexmarkProps> = (props) => (
	<svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clip-path="url(#clip0_5130_12590)">
			<path d="M9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM6.15234 6.15234C6.48281 5.82188 7.01719 5.82188 7.34414 6.15234L8.99648 7.80469L10.6488 6.15234C10.9793 5.82188 11.5137 5.82188 11.8406 6.15234C12.1676 6.48281 12.1711 7.01719 11.8406 7.34414L10.1883 8.99648L11.8406 10.6488C12.1711 10.9793 12.1711 11.5137 11.8406 11.8406C11.5102 12.1676 10.9758 12.1711 10.6488 11.8406L8.99648 10.1883L7.34414 11.8406C7.01367 12.1711 6.4793 12.1711 6.15234 11.8406C5.82539 11.5102 5.82188 10.9758 6.15234 10.6488L7.80469 8.99648L6.15234 7.34414C5.82188 7.01367 5.82188 6.4793 6.15234 6.15234Z" fill="#ECEEEF"/>
		</g>
		<defs>
			<clipPath id="clip0_5130_12590">
				<rect width="18" height="18" fill="white"/>
			</clipPath>
		</defs>
	</svg>
);


