/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEventHandler, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg'

import './PriceCalculator.css';
import { formatNumber, numberRegex } from "../../utils/helpers";
import { useVideo } from "../../context/VideoContext";
import { Country } from './PriceCalculator.types';
import CircularLoading from "../../shared/CircularLoading/CircularLoading";
import { getApiSubRegionRegionName } from '../../api/interconectar-api'
import IconError from "../Icons/IconError";



const PriceCalculatorStepThree = ({ handleClickOpen, handleContinueClick }:{  handleClickOpen: MouseEventHandler<HTMLDivElement>, handleContinueClick: () => void }) => {
    const { t } = useTranslation();
    const { setVideoName } = useVideo();
    const [formThreeValues, setFormThreeValues] = useState<any>();
    const [newCountries, setCountries] = useState<any[]>([]);
    const [loadingCountries, setLoadingCountries] = useState<boolean>(true);
    const [errorCountries, setErrorCountries] = useState<string | null>(null);

    const formTwo = JSON.parse(window.localStorage.getItem("formTwo")!);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = (data: any) => {
        localStorage.setItem("formThree", JSON.stringify(data));
        setFormThreeValues(data);
        handleContinueClick();
    };

    useEffect(() => {
        const formThree = localStorage.getItem("formThree")
            ? JSON.parse(localStorage.getItem("formThree")!)
            : "";
        if (Object.keys(formThree).length) setFormThreeValues(formThree);
    }, []);

    useEffect(() => {
        if (formThreeValues) {
            reset((prev) => ({ ...prev, ...formThreeValues }));
        }
    }, [formThreeValues, reset]);

    useEffect(() => {
        formTwo.conveyance === "maritimo" || formTwo.conveyance === "multimodal"
            ? setVideoName("thirdBarcoVideo")
            : formTwo.conveyance === "aereo"
            ? setVideoName("thirdAvionVideo")
            : setVideoName("");
    }, []);

    

    useEffect(() => {
        const fetchCountries = async () => {
            
            try {
                
                // Llamada a la API con la región "Europa"
                const data = await getApiSubRegionRegionName("Europa");

                const storedData = JSON.parse(localStorage.getItem("formOne") || "{}");
                const excludedId = storedData?.countryOriginId;

                if (data.results) {
                    const filteredCountries = data.results.map((region: any) => ({
                        ...region,
                        countries: region.countries.filter(
                            (country: any) => country.id !== excludedId
                        ),
                    }));

                    setCountries(filteredCountries);
                }
                
            } catch (error) {
                setErrorCountries(t('price_calculator.step_3.error_message_fetch'));
            } finally {
                setLoadingCountries(false);
            }
        };

        fetchCountries();
    }, []);

    useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

    if (loadingCountries) {
        return <CircularLoading />;
    }

    if (errorCountries) {

        return <div className='errorcalculator'>
			<div className='errorcalculator__icon'>
				<IconError />	
			</div>			
			<p className='errorcalculator__txt'>{errorCountries}</p>
		</div>;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="exporter-test__read-only">
                <div className='price-calculator__container-main'>
                    <div className="price-calculator___inner">
                        <div className="container price-calculator___container">
                            <div className="row">
                                <div className='col-12'>
                                    <span className='price-calculator__container-title'>{t('price_calculator.step_3.subtitle')}</span>
                                </div>
                            </div>

                            <div className="row price-calculator__row mb-4">
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon label-container'>
                                        <label className="label-input">{t('price_calculator.step_3.portExpensesUsd.placeholder')}</label>
                                        <input
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            {...register('portExpensesUsd', { required: true })}
                                            value={!!formThreeValues?.portExpensesUsd ? formThreeValues?.portExpensesUsd : ''}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormThreeValues({...formThreeValues, portExpensesUsd: formatNumber(e.target.value)})
                                                }
                                            }}
                                        />
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_3.portExpensesUsd.tooltip')}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCircleQuestion}
                                                    className="font-size: 18px"
                                                />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.portExpensesUsd && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_3.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon label-container'>
                                        <label className="label-input">{t('price_calculator.step_3.customersExpensesValueUsd.placeholder')}</label>
                                        <input
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            value={!!formThreeValues?.customersExpensesValueUsd ? formThreeValues?.customersExpensesValueUsd : ''}
                                            {...register('customersExpensesValueUsd', { required: true })}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormThreeValues({...formThreeValues, customersExpensesValueUsd: formatNumber(e.target.value)})
                                                }
                                            }}
                                        />
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_3.customersExpensesValueUsd.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.customersExpensesValueUsd && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_3.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className='row price-calculator__row mb-4'>
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon'>
                                       

                                <select
                                        {...register('countryDestinationId', { required: true })}
                                        value={formThreeValues?.countryDestinationId || ''} // Valor controlado del select
                                        onChange={(e) => {
                                            const selectedCountryId = e.target.value;
                                            setFormThreeValues({
                                            ...formThreeValues,
                                            countryDestinationId: selectedCountryId, 
                                            country: selectedCountryId,
                                            countryOriginId: selectedCountryId,
                                            });
                                        }}
                                        className="price-calc__dropdown-general price-calculator__select-font"
                                        >
                                        <option value="" disabled>
                                            {t('price_calculator.step_3.countryDestinationId.placeholder')}
                                        </option>
                                        
                                        {newCountries.map((region) => (
                                        <optgroup key={region.nameEs} label={region.nameEs}>
                                            {region.countries.map((country: Country) => (
                                            <option
                                                key={country.id}
                                                value={country.id}
                                                className="price-calculator__select-font"
                                            >
                                                {country.name}
                                            </option>
                                            ))}
                                        </optgroup>
                                        ))}
                                    </select>
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_3.countryDestinationId.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.countryDestinationId && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_3.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-md-3 label-container'>
                                    <label className="label-input">{t('price_calculator.step_3.adValoremTax.placeholder')}</label>
                                    <input
                                        type="text"
                                        className="price-calculator__textbox-general-col2"
                                        {...register('adValoremTax', { required: true })}
                                        value={!!formThreeValues?.adValoremTax ? formThreeValues?.adValoremTax : ''}
                                        onChange={ (e) => {
                                            if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                setFormThreeValues({...formThreeValues, adValoremTax: formatNumber(e.target.value)})
                                            }
                                        }}
                                    />
                                    {!!errors?.adValoremTax && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_3.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-md-3 label-container'>
                                    <label className="label-input">{t('price_calculator.step_3.taxDeterminedByWeight.placeholder')}</label>
                                    <input
                                        type="text"
                                        className="price-calculator__textbox-general-col2"
                                        {...register('taxDeterminedByWeight', { required: true })}
                                        value={!!formThreeValues?.taxDeterminedByWeight ? formThreeValues?.taxDeterminedByWeight : ''}
                                        onChange={ (e) => {
                                            if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                setFormThreeValues({...formThreeValues, taxDeterminedByWeight: formatNumber(e.target.value)})
                                            }
                                        }}
                                    />
                                    {!!errors?.taxDeterminedByWeight && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_3.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                            
                            <div className="row price-calculator__row mb-4">
                                <div className='col-12'>

                                    <div className='price-calculator__field-with-icon label-container'>
                                        <label className="label-input">{t('price_calculator.step_3.costTransportationPortWarehouse.placeholder')}</label>
                                        <input
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            {...register('costTransportationPortWarehouse', { required: true })}
                                            value={!!formThreeValues?.costTransportationPortWarehouse ? formThreeValues?.costTransportationPortWarehouse : ''}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormThreeValues({...formThreeValues, costTransportationPortWarehouse: formatNumber(e.target.value)})
                                                }
                                            }}
                                        />

                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_3.costTransportationPortWarehouse.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {(!!errors?.distance || !!errors?.costTransportationPortWarehouse) && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_3.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="price-calculator__container-footer">
                        <div
                            className="price-calculator__footer-link"
                            onClick={handleClickOpen}
                        >{t('price_calculator.footer.link')}</div>
                        <button type="submit" className="price-calc__next">
                            <div className="btn-test-next-text">{t('price_calculator.footer.button')}</div>
                            <FontAwesomeIcon icon={faArrowRight} color="black" />
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default PriceCalculatorStepThree