/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Interconectar.API
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import type {
  ActivateUserCommand,
  AddOrUpdateUserPreferenceCommand,
  BooleanValueResult,
  ContainerBriefViewModelCollectionResult,
  ContainerViewModelValueResult,
  CountriesWithPortsViowModelCollectionResult,
  CountryBriefViewModelCollectionResult,
  CountryViewModelValueResult,
  CreatePriceCalculatorCommand,
  DailyParamViewModelCollectionResult,
  DailyParamViewModelValueResult,
  EditPriceCalculatorCommand,
  GetApiContainerTypeParams,
  GetApiCountryParams,
  GetApiPalletParams,
  GetApiUserPreferenceParams,
  GetApiUserUserIdPriceCalculatorParams,
  GetNewsQuery,
  GetSubscriptionsByUserQuery,
  LoginCommand,
  LoginResponseViewModelValueResult,
  NewsResponseViewModelCollectionResult,
  PersonalInformationCommand,
  PreferenceOptionViewModelCollectionResult,
  PriceCalculatorBriefViewModelCollectionResult,
  PriceCalculatorViewModelValueResult,
  ProductViewModelCollectionResult,
  RecoverPasswordCommand,
  RefreshTokenCommand,
  RegisterUserCommand,
  ResetPasswordCommand,
  SubscriptionPaymentCommand,
  SubscriptionUserViewModelCollectionResult,
  ValidateDiscountCommand,
  ValidateDiscountResponseViewModelValueResult,
} from "./models";
import getApiContainerTypeContainerTyoeIdMutator from "./mutator/customInstance";
import type { ErrorType as GetApiContainerTypeContainerTyoeIdErrorType } from "./mutator/customInstance";
import getApiContainerTypeMutator from "./mutator/customInstance";
import type { ErrorType as GetApiContainerTypeErrorType } from "./mutator/customInstance";
import getApiCountryCountryIdMutator from "./mutator/customInstance";
import type { ErrorType as GetApiCountryCountryIdErrorType } from "./mutator/customInstance";
import getApiCountryMutator from "./mutator/customInstance";
import type { ErrorType as GetApiCountryErrorType } from "./mutator/customInstance";
import getApiDailyParamGetAllMutator from "./mutator/customInstance";
import type { ErrorType as GetApiDailyParamGetAllErrorType } from "./mutator/customInstance";
import getApiDailyParamNameMutator from "./mutator/customInstance";
import type { ErrorType as GetApiDailyParamNameErrorType } from "./mutator/customInstance";
import postApiNewsMutator from "./mutator/customInstance";
import type { ErrorType as PostApiNewsErrorType } from "./mutator/customInstance";
import getApiPalletPalletIdMutator from "./mutator/customInstance";
import type { ErrorType as GetApiPalletPalletIdErrorType } from "./mutator/customInstance";
import getApiPalletMutator from "./mutator/customInstance";
import type { ErrorType as GetApiPalletErrorType } from "./mutator/customInstance";
import getApiPortsCountriesMutator from "./mutator/customInstance";
import type { ErrorType as GetApiPortsCountriesErrorType } from "./mutator/customInstance";
import getApiPreferenceOptionMutator from "./mutator/customInstance";
import type { ErrorType as GetApiPreferenceOptionErrorType } from "./mutator/customInstance";
import postApiPriceCalculatorMutator from "./mutator/customInstance";
import type { ErrorType as PostApiPriceCalculatorErrorType } from "./mutator/customInstance";
import putApiPriceCalculatorIdMutator from "./mutator/customInstance";
import type { ErrorType as PutApiPriceCalculatorIdErrorType } from "./mutator/customInstance";
import getApiPriceCalculatorCalculatorIdMutator from "./mutator/customInstance";
import type { ErrorType as GetApiPriceCalculatorCalculatorIdErrorType } from "./mutator/customInstance";
import getApiUserUserIdPriceCalculatorMutator from "./mutator/customInstance";
import type { ErrorType as GetApiUserUserIdPriceCalculatorErrorType } from "./mutator/customInstance";
import getApiRegionCountriesMutator from "./mutator/customInstance";
import type { ErrorType as GetApiRegionCountriesErrorType } from "./mutator/customInstance";
import getApiSubRegionRegionNameMutator from "./mutator/customInstance";
import type { ErrorType as GetApiSubRegionRegionNameErrorType } from "./mutator/customInstance";
import postApiSubscriptionMutator from "./mutator/customInstance";
import type { ErrorType as PostApiSubscriptionErrorType } from "./mutator/customInstance";
import postApiSubscriptionProductsMutator from "./mutator/customInstance";
import type { ErrorType as PostApiSubscriptionProductsErrorType } from "./mutator/customInstance";
import postApiSubscriptionPaymentMutator from "./mutator/customInstance";
import type { ErrorType as PostApiSubscriptionPaymentErrorType } from "./mutator/customInstance";
import postApiSubscriptionValidateDiscountMutator from "./mutator/customInstance";
import type { ErrorType as PostApiSubscriptionValidateDiscountErrorType } from "./mutator/customInstance";
import getApiTablesTableNameMutator from "./mutator/customInstance";
import type { ErrorType as GetApiTablesTableNameErrorType } from "./mutator/customInstance";
import postApiUserRegisterMutator from "./mutator/customInstance";
import type { ErrorType as PostApiUserRegisterErrorType } from "./mutator/customInstance";
import postApiUserActivateMutator from "./mutator/customInstance";
import type { ErrorType as PostApiUserActivateErrorType } from "./mutator/customInstance";
import postApiUserLoginMutator from "./mutator/customInstance";
import type { ErrorType as PostApiUserLoginErrorType } from "./mutator/customInstance";
import postApiUserPersonalInformationMutator from "./mutator/customInstance";
import type { ErrorType as PostApiUserPersonalInformationErrorType } from "./mutator/customInstance";
import postApiUserRecoverPasswordMutator from "./mutator/customInstance";
import type { ErrorType as PostApiUserRecoverPasswordErrorType } from "./mutator/customInstance";
import postApiUserResetPasswordMutator from "./mutator/customInstance";
import type { ErrorType as PostApiUserResetPasswordErrorType } from "./mutator/customInstance";
import postApiUserRefreshTokenMutator from "./mutator/customInstance";
import type { ErrorType as PostApiUserRefreshTokenErrorType } from "./mutator/customInstance";
import getApiUserPreferenceMutator from "./mutator/customInstance";
import type { ErrorType as GetApiUserPreferenceErrorType } from "./mutator/customInstance";
import postApiUserPreferenceAddOrUpdateMutator from "./mutator/customInstance";
import type { ErrorType as PostApiUserPreferenceAddOrUpdateErrorType } from "./mutator/customInstance";

export const getApiContainerTypeContainerTyoeId = (
  containerTyoeId: string,
  signal?: AbortSignal,
) => {
  return getApiContainerTypeContainerTyoeIdMutator<ContainerViewModelValueResult>(
    { url: `/api/ContainerType/${containerTyoeId}`, method: "GET", signal },
  );
};

export const getGetApiContainerTypeContainerTyoeIdQueryKey = (
  containerTyoeId: string,
) => {
  return [`/api/ContainerType/${containerTyoeId}`] as const;
};

export const getGetApiContainerTypeContainerTyoeIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiContainerTypeContainerTyoeId>>,
  TError = GetApiContainerTypeContainerTyoeIdErrorType<unknown>,
>(
  containerTyoeId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiContainerTypeContainerTyoeId>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiContainerTypeContainerTyoeIdQueryKey(containerTyoeId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiContainerTypeContainerTyoeId>>
  > = ({ signal }) =>
    getApiContainerTypeContainerTyoeId(containerTyoeId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!containerTyoeId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiContainerTypeContainerTyoeId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiContainerTypeContainerTyoeIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiContainerTypeContainerTyoeId>>
>;
export type GetApiContainerTypeContainerTyoeIdQueryError =
  GetApiContainerTypeContainerTyoeIdErrorType<unknown>;

export const useGetApiContainerTypeContainerTyoeId = <
  TData = Awaited<ReturnType<typeof getApiContainerTypeContainerTyoeId>>,
  TError = GetApiContainerTypeContainerTyoeIdErrorType<unknown>,
>(
  containerTyoeId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiContainerTypeContainerTyoeId>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiContainerTypeContainerTyoeIdQueryOptions(
    containerTyoeId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiContainerType = (
  params?: GetApiContainerTypeParams,
  signal?: AbortSignal,
) => {
  return getApiContainerTypeMutator<ContainerBriefViewModelCollectionResult>({
    url: `/api/ContainerType`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetApiContainerTypeQueryKey = (
  params?: GetApiContainerTypeParams,
) => {
  return [`/api/ContainerType`, ...(params ? [params] : [])] as const;
};

export const getGetApiContainerTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiContainerType>>,
  TError = GetApiContainerTypeErrorType<unknown>,
>(
  params?: GetApiContainerTypeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiContainerType>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiContainerTypeQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiContainerType>>
  > = ({ signal }) => getApiContainerType(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiContainerType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiContainerTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiContainerType>>
>;
export type GetApiContainerTypeQueryError =
  GetApiContainerTypeErrorType<unknown>;

export const useGetApiContainerType = <
  TData = Awaited<ReturnType<typeof getApiContainerType>>,
  TError = GetApiContainerTypeErrorType<unknown>,
>(
  params?: GetApiContainerTypeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiContainerType>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiContainerTypeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiCountryCountryId = (
  countryId: string,
  signal?: AbortSignal,
) => {
  return getApiCountryCountryIdMutator<CountryViewModelValueResult>({
    url: `/api/Country/${countryId}`,
    method: "GET",
    signal,
  });
};

export const getGetApiCountryCountryIdQueryKey = (countryId: string) => {
  return [`/api/Country/${countryId}`] as const;
};

export const getGetApiCountryCountryIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiCountryCountryId>>,
  TError = GetApiCountryCountryIdErrorType<unknown>,
>(
  countryId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiCountryCountryId>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiCountryCountryIdQueryKey(countryId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiCountryCountryId>>
  > = ({ signal }) => getApiCountryCountryId(countryId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!countryId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiCountryCountryId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiCountryCountryIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiCountryCountryId>>
>;
export type GetApiCountryCountryIdQueryError =
  GetApiCountryCountryIdErrorType<unknown>;

export const useGetApiCountryCountryId = <
  TData = Awaited<ReturnType<typeof getApiCountryCountryId>>,
  TError = GetApiCountryCountryIdErrorType<unknown>,
>(
  countryId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiCountryCountryId>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiCountryCountryIdQueryOptions(
    countryId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiCountry = (
  params?: GetApiCountryParams,
  signal?: AbortSignal,
) => {
  return getApiCountryMutator<CountryBriefViewModelCollectionResult>({
    url: `/api/Country`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetApiCountryQueryKey = (params?: GetApiCountryParams) => {
  return [`/api/Country`, ...(params ? [params] : [])] as const;
};

export const getGetApiCountryQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiCountry>>,
  TError = GetApiCountryErrorType<unknown>,
>(
  params?: GetApiCountryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiCountry>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiCountryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiCountry>>> = ({
    signal,
  }) => getApiCountry(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiCountry>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiCountryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiCountry>>
>;
export type GetApiCountryQueryError = GetApiCountryErrorType<unknown>;

export const useGetApiCountry = <
  TData = Awaited<ReturnType<typeof getApiCountry>>,
  TError = GetApiCountryErrorType<unknown>,
>(
  params?: GetApiCountryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiCountry>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiCountryQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiDailyParamGetAll = (signal?: AbortSignal) => {
  return getApiDailyParamGetAllMutator<DailyParamViewModelCollectionResult>({
    url: `/api/DailyParam/GetAll`,
    method: "GET",
    signal,
  });
};

export const getGetApiDailyParamGetAllQueryKey = () => {
  return [`/api/DailyParam/GetAll`] as const;
};

export const getGetApiDailyParamGetAllQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiDailyParamGetAll>>,
  TError = GetApiDailyParamGetAllErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiDailyParamGetAll>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiDailyParamGetAllQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiDailyParamGetAll>>
  > = ({ signal }) => getApiDailyParamGetAll(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiDailyParamGetAll>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiDailyParamGetAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiDailyParamGetAll>>
>;
export type GetApiDailyParamGetAllQueryError =
  GetApiDailyParamGetAllErrorType<unknown>;

export const useGetApiDailyParamGetAll = <
  TData = Awaited<ReturnType<typeof getApiDailyParamGetAll>>,
  TError = GetApiDailyParamGetAllErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiDailyParamGetAll>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiDailyParamGetAllQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiDailyParamName = (name: string, signal?: AbortSignal) => {
  return getApiDailyParamNameMutator<DailyParamViewModelValueResult>({
    url: `/api/DailyParam/${name}`,
    method: "GET",
    signal,
  });
};

export const getGetApiDailyParamNameQueryKey = (name: string) => {
  return [`/api/DailyParam/${name}`] as const;
};

export const getGetApiDailyParamNameQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiDailyParamName>>,
  TError = GetApiDailyParamNameErrorType<unknown>,
>(
  name: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiDailyParamName>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiDailyParamNameQueryKey(name);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiDailyParamName>>
  > = ({ signal }) => getApiDailyParamName(name, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!name,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiDailyParamName>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiDailyParamNameQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiDailyParamName>>
>;
export type GetApiDailyParamNameQueryError =
  GetApiDailyParamNameErrorType<unknown>;

export const useGetApiDailyParamName = <
  TData = Awaited<ReturnType<typeof getApiDailyParamName>>,
  TError = GetApiDailyParamNameErrorType<unknown>,
>(
  name: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiDailyParamName>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiDailyParamNameQueryOptions(name, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiNews = (getNewsQuery: GetNewsQuery) => {
  return postApiNewsMutator<NewsResponseViewModelCollectionResult>({
    url: `/api/News`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: getNewsQuery,
  });
};

export const getPostApiNewsMutationOptions = <
  TError = PostApiNewsErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiNews>>,
    TError,
    { data: GetNewsQuery },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiNews>>,
  TError,
  { data: GetNewsQuery },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiNews>>,
    { data: GetNewsQuery }
  > = (props) => {
    const { data } = props ?? {};

    return postApiNews(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiNewsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiNews>>
>;
export type PostApiNewsMutationBody = GetNewsQuery;
export type PostApiNewsMutationError = PostApiNewsErrorType<unknown>;

export const usePostApiNews = <
  TError = PostApiNewsErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiNews>>,
    TError,
    { data: GetNewsQuery },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiNews>>,
  TError,
  { data: GetNewsQuery },
  TContext
> => {
  const mutationOptions = getPostApiNewsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getApiPalletPalletId = (
  palletId: string,
  signal?: AbortSignal,
) => {
  return getApiPalletPalletIdMutator<CountryViewModelValueResult>({
    url: `/api/Pallet/${palletId}`,
    method: "GET",
    signal,
  });
};

export const getGetApiPalletPalletIdQueryKey = (palletId: string) => {
  return [`/api/Pallet/${palletId}`] as const;
};

export const getGetApiPalletPalletIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiPalletPalletId>>,
  TError = GetApiPalletPalletIdErrorType<unknown>,
>(
  palletId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiPalletPalletId>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiPalletPalletIdQueryKey(palletId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiPalletPalletId>>
  > = ({ signal }) => getApiPalletPalletId(palletId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!palletId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiPalletPalletId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiPalletPalletIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiPalletPalletId>>
>;
export type GetApiPalletPalletIdQueryError =
  GetApiPalletPalletIdErrorType<unknown>;

export const useGetApiPalletPalletId = <
  TData = Awaited<ReturnType<typeof getApiPalletPalletId>>,
  TError = GetApiPalletPalletIdErrorType<unknown>,
>(
  palletId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiPalletPalletId>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPalletPalletIdQueryOptions(palletId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiPallet = (
  params?: GetApiPalletParams,
  signal?: AbortSignal,
) => {
  return getApiPalletMutator<CountryBriefViewModelCollectionResult>({
    url: `/api/Pallet`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetApiPalletQueryKey = (params?: GetApiPalletParams) => {
  return [`/api/Pallet`, ...(params ? [params] : [])] as const;
};

export const getGetApiPalletQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiPallet>>,
  TError = GetApiPalletErrorType<unknown>,
>(
  params?: GetApiPalletParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiPallet>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPalletQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPallet>>> = ({
    signal,
  }) => getApiPallet(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiPallet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiPalletQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiPallet>>
>;
export type GetApiPalletQueryError = GetApiPalletErrorType<unknown>;

export const useGetApiPallet = <
  TData = Awaited<ReturnType<typeof getApiPallet>>,
  TError = GetApiPalletErrorType<unknown>,
>(
  params?: GetApiPalletParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiPallet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPalletQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiPortsCountries = (signal?: AbortSignal) => {
  return getApiPortsCountriesMutator<CountriesWithPortsViowModelCollectionResult>(
    { url: `/api/Ports/countries`, method: "GET", signal },
  );
};

export const getGetApiPortsCountriesQueryKey = () => {
  return [`/api/Ports/countries`] as const;
};

export const getGetApiPortsCountriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiPortsCountries>>,
  TError = GetApiPortsCountriesErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiPortsCountries>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiPortsCountriesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiPortsCountries>>
  > = ({ signal }) => getApiPortsCountries(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiPortsCountries>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiPortsCountriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiPortsCountries>>
>;
export type GetApiPortsCountriesQueryError =
  GetApiPortsCountriesErrorType<unknown>;

export const useGetApiPortsCountries = <
  TData = Awaited<ReturnType<typeof getApiPortsCountries>>,
  TError = GetApiPortsCountriesErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiPortsCountries>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPortsCountriesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiPreferenceOption = (signal?: AbortSignal) => {
  return getApiPreferenceOptionMutator<PreferenceOptionViewModelCollectionResult>(
    { url: `/api/PreferenceOption`, method: "GET", signal },
  );
};

export const getGetApiPreferenceOptionQueryKey = () => {
  return [`/api/PreferenceOption`] as const;
};

export const getGetApiPreferenceOptionQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiPreferenceOption>>,
  TError = GetApiPreferenceOptionErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiPreferenceOption>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiPreferenceOptionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiPreferenceOption>>
  > = ({ signal }) => getApiPreferenceOption(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiPreferenceOption>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiPreferenceOptionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiPreferenceOption>>
>;
export type GetApiPreferenceOptionQueryError =
  GetApiPreferenceOptionErrorType<unknown>;

export const useGetApiPreferenceOption = <
  TData = Awaited<ReturnType<typeof getApiPreferenceOption>>,
  TError = GetApiPreferenceOptionErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiPreferenceOption>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPreferenceOptionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiPriceCalculator = (
  createPriceCalculatorCommand: CreatePriceCalculatorCommand,
) => {
  return postApiPriceCalculatorMutator<boolean>({
    url: `/api/PriceCalculator`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createPriceCalculatorCommand,
  });
};

export const getPostApiPriceCalculatorMutationOptions = <
  TError = PostApiPriceCalculatorErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiPriceCalculator>>,
    TError,
    { data: CreatePriceCalculatorCommand },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiPriceCalculator>>,
  TError,
  { data: CreatePriceCalculatorCommand },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiPriceCalculator>>,
    { data: CreatePriceCalculatorCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiPriceCalculator(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiPriceCalculatorMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiPriceCalculator>>
>;
export type PostApiPriceCalculatorMutationBody = CreatePriceCalculatorCommand;
export type PostApiPriceCalculatorMutationError =
  PostApiPriceCalculatorErrorType<unknown>;

export const usePostApiPriceCalculator = <
  TError = PostApiPriceCalculatorErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiPriceCalculator>>,
    TError,
    { data: CreatePriceCalculatorCommand },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiPriceCalculator>>,
  TError,
  { data: CreatePriceCalculatorCommand },
  TContext
> => {
  const mutationOptions = getPostApiPriceCalculatorMutationOptions(options);

  return useMutation(mutationOptions);
};

export const putApiPriceCalculatorId = (
  id: string,
  editPriceCalculatorCommand: EditPriceCalculatorCommand,
) => {
  return putApiPriceCalculatorIdMutator<boolean>({
    url: `/api/PriceCalculator/${id}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: editPriceCalculatorCommand,
  });
};

export const getPutApiPriceCalculatorIdMutationOptions = <
  TError = PutApiPriceCalculatorIdErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiPriceCalculatorId>>,
    TError,
    { id: string; data: EditPriceCalculatorCommand },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiPriceCalculatorId>>,
  TError,
  { id: string; data: EditPriceCalculatorCommand },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiPriceCalculatorId>>,
    { id: string; data: EditPriceCalculatorCommand }
  > = (props) => {
    const { id, data } = props ?? {};

    return putApiPriceCalculatorId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiPriceCalculatorIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiPriceCalculatorId>>
>;
export type PutApiPriceCalculatorIdMutationBody = EditPriceCalculatorCommand;
export type PutApiPriceCalculatorIdMutationError =
  PutApiPriceCalculatorIdErrorType<unknown>;

export const usePutApiPriceCalculatorId = <
  TError = PutApiPriceCalculatorIdErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiPriceCalculatorId>>,
    TError,
    { id: string; data: EditPriceCalculatorCommand },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiPriceCalculatorId>>,
  TError,
  { id: string; data: EditPriceCalculatorCommand },
  TContext
> => {
  const mutationOptions = getPutApiPriceCalculatorIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getApiPriceCalculatorCalculatorId = (
  calculatorId: string,
  signal?: AbortSignal,
) => {
  return getApiPriceCalculatorCalculatorIdMutator<PriceCalculatorViewModelValueResult>(
    { url: `/api/PriceCalculator/${calculatorId}`, method: "GET", signal },
  );
};

export const getGetApiPriceCalculatorCalculatorIdQueryKey = (
  calculatorId: string,
) => {
  return [`/api/PriceCalculator/${calculatorId}`] as const;
};

export const getGetApiPriceCalculatorCalculatorIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiPriceCalculatorCalculatorId>>,
  TError = GetApiPriceCalculatorCalculatorIdErrorType<unknown>,
>(
  calculatorId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiPriceCalculatorCalculatorId>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiPriceCalculatorCalculatorIdQueryKey(calculatorId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiPriceCalculatorCalculatorId>>
  > = ({ signal }) => getApiPriceCalculatorCalculatorId(calculatorId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!calculatorId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiPriceCalculatorCalculatorId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiPriceCalculatorCalculatorIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiPriceCalculatorCalculatorId>>
>;
export type GetApiPriceCalculatorCalculatorIdQueryError =
  GetApiPriceCalculatorCalculatorIdErrorType<unknown>;

export const useGetApiPriceCalculatorCalculatorId = <
  TData = Awaited<ReturnType<typeof getApiPriceCalculatorCalculatorId>>,
  TError = GetApiPriceCalculatorCalculatorIdErrorType<unknown>,
>(
  calculatorId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiPriceCalculatorCalculatorId>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiPriceCalculatorCalculatorIdQueryOptions(
    calculatorId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiUserUserIdPriceCalculator = (
  userId: string,
  params?: GetApiUserUserIdPriceCalculatorParams,
  signal?: AbortSignal,
) => {
  return getApiUserUserIdPriceCalculatorMutator<PriceCalculatorBriefViewModelCollectionResult>(
    {
      url: `/api/User/${userId}/PriceCalculator`,
      method: "GET",
      params,
      signal,
    },
  );
};

export const getGetApiUserUserIdPriceCalculatorQueryKey = (
  userId: string,
  params?: GetApiUserUserIdPriceCalculatorParams,
) => {
  return [
    `/api/User/${userId}/PriceCalculator`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetApiUserUserIdPriceCalculatorQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiUserUserIdPriceCalculator>>,
  TError = GetApiUserUserIdPriceCalculatorErrorType<unknown>,
>(
  userId: string,
  params?: GetApiUserUserIdPriceCalculatorParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiUserUserIdPriceCalculator>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiUserUserIdPriceCalculatorQueryKey(userId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiUserUserIdPriceCalculator>>
  > = ({ signal }) => getApiUserUserIdPriceCalculator(userId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiUserUserIdPriceCalculator>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiUserUserIdPriceCalculatorQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiUserUserIdPriceCalculator>>
>;
export type GetApiUserUserIdPriceCalculatorQueryError =
  GetApiUserUserIdPriceCalculatorErrorType<unknown>;

export const useGetApiUserUserIdPriceCalculator = <
  TData = Awaited<ReturnType<typeof getApiUserUserIdPriceCalculator>>,
  TError = GetApiUserUserIdPriceCalculatorErrorType<unknown>,
>(
  userId: string,
  params?: GetApiUserUserIdPriceCalculatorParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiUserUserIdPriceCalculator>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUserUserIdPriceCalculatorQueryOptions(
    userId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiRegionCountries = (signal?: AbortSignal) => {
  return getApiRegionCountriesMutator<CountryBriefViewModelCollectionResult>({
    url: `/api/Region/countries`,
    method: "GET",
    signal,
  });
};

export const getGetApiRegionCountriesQueryKey = () => {
  return [`/api/Region/countries`] as const;
};

export const getGetApiRegionCountriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiRegionCountries>>,
  TError = GetApiRegionCountriesErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiRegionCountries>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiRegionCountriesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiRegionCountries>>
  > = ({ signal }) => getApiRegionCountries(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiRegionCountries>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiRegionCountriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiRegionCountries>>
>;
export type GetApiRegionCountriesQueryError =
  GetApiRegionCountriesErrorType<unknown>;

export const useGetApiRegionCountries = <
  TData = Awaited<ReturnType<typeof getApiRegionCountries>>,
  TError = GetApiRegionCountriesErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiRegionCountries>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiRegionCountriesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiSubRegionRegionName = (
  regionName: string,
  signal?: AbortSignal,
) => {
  return getApiSubRegionRegionNameMutator<CountryBriefViewModelCollectionResult>(
    { url: `/api/SubRegion/${regionName}`, method: "GET", signal },
  );
};

export const getGetApiSubRegionRegionNameQueryKey = (regionName: string) => {
  return [`/api/SubRegion/${regionName}`] as const;
};

export const getGetApiSubRegionRegionNameQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiSubRegionRegionName>>,
  TError = GetApiSubRegionRegionNameErrorType<unknown>,
>(
  regionName: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiSubRegionRegionName>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiSubRegionRegionNameQueryKey(regionName);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiSubRegionRegionName>>
  > = ({ signal }) => getApiSubRegionRegionName(regionName, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!regionName,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiSubRegionRegionName>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiSubRegionRegionNameQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiSubRegionRegionName>>
>;
export type GetApiSubRegionRegionNameQueryError =
  GetApiSubRegionRegionNameErrorType<unknown>;

export const useGetApiSubRegionRegionName = <
  TData = Awaited<ReturnType<typeof getApiSubRegionRegionName>>,
  TError = GetApiSubRegionRegionNameErrorType<unknown>,
>(
  regionName: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiSubRegionRegionName>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiSubRegionRegionNameQueryOptions(
    regionName,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiSubscription = (
  getSubscriptionsByUserQuery: GetSubscriptionsByUserQuery,
) => {
  return postApiSubscriptionMutator<SubscriptionUserViewModelCollectionResult>({
    url: `/api/Subscription`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: getSubscriptionsByUserQuery,
  });
};

export const getPostApiSubscriptionMutationOptions = <
  TError = PostApiSubscriptionErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscription>>,
    TError,
    { data: GetSubscriptionsByUserQuery },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSubscription>>,
  TError,
  { data: GetSubscriptionsByUserQuery },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiSubscription>>,
    { data: GetSubscriptionsByUserQuery }
  > = (props) => {
    const { data } = props ?? {};

    return postApiSubscription(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiSubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiSubscription>>
>;
export type PostApiSubscriptionMutationBody = GetSubscriptionsByUserQuery;
export type PostApiSubscriptionMutationError =
  PostApiSubscriptionErrorType<unknown>;

export const usePostApiSubscription = <
  TError = PostApiSubscriptionErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscription>>,
    TError,
    { data: GetSubscriptionsByUserQuery },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiSubscription>>,
  TError,
  { data: GetSubscriptionsByUserQuery },
  TContext
> => {
  const mutationOptions = getPostApiSubscriptionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiSubscriptionProducts = () => {
  return postApiSubscriptionProductsMutator<ProductViewModelCollectionResult>({
    url: `/api/Subscription/products`,
    method: "POST",
  });
};

export const getPostApiSubscriptionProductsMutationOptions = <
  TError = PostApiSubscriptionProductsErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionProducts>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSubscriptionProducts>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiSubscriptionProducts>>,
    void
  > = () => {
    return postApiSubscriptionProducts();
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiSubscriptionProductsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiSubscriptionProducts>>
>;

export type PostApiSubscriptionProductsMutationError =
  PostApiSubscriptionProductsErrorType<unknown>;

export const usePostApiSubscriptionProducts = <
  TError = PostApiSubscriptionProductsErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionProducts>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiSubscriptionProducts>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getPostApiSubscriptionProductsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiSubscriptionPayment = (
  subscriptionPaymentCommand: SubscriptionPaymentCommand,
) => {
  return postApiSubscriptionPaymentMutator<BooleanValueResult>({
    url: `/api/Subscription/payment`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: subscriptionPaymentCommand,
  });
};

export const getPostApiSubscriptionPaymentMutationOptions = <
  TError = PostApiSubscriptionPaymentErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionPayment>>,
    TError,
    { data: SubscriptionPaymentCommand },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSubscriptionPayment>>,
  TError,
  { data: SubscriptionPaymentCommand },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiSubscriptionPayment>>,
    { data: SubscriptionPaymentCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiSubscriptionPayment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiSubscriptionPaymentMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiSubscriptionPayment>>
>;
export type PostApiSubscriptionPaymentMutationBody = SubscriptionPaymentCommand;
export type PostApiSubscriptionPaymentMutationError =
  PostApiSubscriptionPaymentErrorType<unknown>;

export const usePostApiSubscriptionPayment = <
  TError = PostApiSubscriptionPaymentErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionPayment>>,
    TError,
    { data: SubscriptionPaymentCommand },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiSubscriptionPayment>>,
  TError,
  { data: SubscriptionPaymentCommand },
  TContext
> => {
  const mutationOptions = getPostApiSubscriptionPaymentMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiSubscriptionValidateDiscount = (
  validateDiscountCommand: ValidateDiscountCommand,
) => {
  return postApiSubscriptionValidateDiscountMutator<ValidateDiscountResponseViewModelValueResult>(
    {
      url: `/api/Subscription/validate-discount`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: validateDiscountCommand,
    },
  );
};

export const getPostApiSubscriptionValidateDiscountMutationOptions = <
  TError = PostApiSubscriptionValidateDiscountErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionValidateDiscount>>,
    TError,
    { data: ValidateDiscountCommand },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSubscriptionValidateDiscount>>,
  TError,
  { data: ValidateDiscountCommand },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiSubscriptionValidateDiscount>>,
    { data: ValidateDiscountCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiSubscriptionValidateDiscount(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiSubscriptionValidateDiscountMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiSubscriptionValidateDiscount>>
>;
export type PostApiSubscriptionValidateDiscountMutationBody =
  ValidateDiscountCommand;
export type PostApiSubscriptionValidateDiscountMutationError =
  PostApiSubscriptionValidateDiscountErrorType<unknown>;

export const usePostApiSubscriptionValidateDiscount = <
  TError = PostApiSubscriptionValidateDiscountErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionValidateDiscount>>,
    TError,
    { data: ValidateDiscountCommand },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiSubscriptionValidateDiscount>>,
  TError,
  { data: ValidateDiscountCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiSubscriptionValidateDiscountMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getApiTablesTableName = (
  tableName: string,
  signal?: AbortSignal,
) => {
  return getApiTablesTableNameMutator<void>({
    url: `/api/Tables/${tableName}`,
    method: "GET",
    signal,
  });
};

export const getGetApiTablesTableNameQueryKey = (tableName: string) => {
  return [`/api/Tables/${tableName}`] as const;
};

export const getGetApiTablesTableNameQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiTablesTableName>>,
  TError = GetApiTablesTableNameErrorType<unknown>,
>(
  tableName: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiTablesTableName>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiTablesTableNameQueryKey(tableName);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiTablesTableName>>
  > = ({ signal }) => getApiTablesTableName(tableName, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tableName,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiTablesTableName>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiTablesTableNameQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiTablesTableName>>
>;
export type GetApiTablesTableNameQueryError =
  GetApiTablesTableNameErrorType<unknown>;

export const useGetApiTablesTableName = <
  TData = Awaited<ReturnType<typeof getApiTablesTableName>>,
  TError = GetApiTablesTableNameErrorType<unknown>,
>(
  tableName: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiTablesTableName>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTablesTableNameQueryOptions(tableName, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiUserRegister = (
  registerUserCommand: RegisterUserCommand,
) => {
  return postApiUserRegisterMutator<BooleanValueResult>({
    url: `/api/User/register`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: registerUserCommand,
  });
};

export const getPostApiUserRegisterMutationOptions = <
  TError = PostApiUserRegisterErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserRegister>>,
    TError,
    { data: RegisterUserCommand },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiUserRegister>>,
  TError,
  { data: RegisterUserCommand },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiUserRegister>>,
    { data: RegisterUserCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiUserRegister(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiUserRegisterMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiUserRegister>>
>;
export type PostApiUserRegisterMutationBody = RegisterUserCommand;
export type PostApiUserRegisterMutationError =
  PostApiUserRegisterErrorType<unknown>;

export const usePostApiUserRegister = <
  TError = PostApiUserRegisterErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserRegister>>,
    TError,
    { data: RegisterUserCommand },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiUserRegister>>,
  TError,
  { data: RegisterUserCommand },
  TContext
> => {
  const mutationOptions = getPostApiUserRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiUserActivate = (
  activateUserCommand: ActivateUserCommand,
) => {
  return postApiUserActivateMutator<BooleanValueResult>({
    url: `/api/User/activate`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: activateUserCommand,
  });
};

export const getPostApiUserActivateMutationOptions = <
  TError = PostApiUserActivateErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserActivate>>,
    TError,
    { data: ActivateUserCommand },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiUserActivate>>,
  TError,
  { data: ActivateUserCommand },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiUserActivate>>,
    { data: ActivateUserCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiUserActivate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiUserActivateMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiUserActivate>>
>;
export type PostApiUserActivateMutationBody = ActivateUserCommand;
export type PostApiUserActivateMutationError =
  PostApiUserActivateErrorType<unknown>;

export const usePostApiUserActivate = <
  TError = PostApiUserActivateErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserActivate>>,
    TError,
    { data: ActivateUserCommand },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiUserActivate>>,
  TError,
  { data: ActivateUserCommand },
  TContext
> => {
  const mutationOptions = getPostApiUserActivateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiUserLogin = (loginCommand: LoginCommand) => {
  return postApiUserLoginMutator<LoginResponseViewModelValueResult>({
    url: `/api/User/login`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: loginCommand,
  });
};

export const getPostApiUserLoginMutationOptions = <
  TError = PostApiUserLoginErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserLogin>>,
    TError,
    { data: LoginCommand },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiUserLogin>>,
  TError,
  { data: LoginCommand },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiUserLogin>>,
    { data: LoginCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiUserLogin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiUserLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiUserLogin>>
>;
export type PostApiUserLoginMutationBody = LoginCommand;
export type PostApiUserLoginMutationError = PostApiUserLoginErrorType<unknown>;

export const usePostApiUserLogin = <
  TError = PostApiUserLoginErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserLogin>>,
    TError,
    { data: LoginCommand },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiUserLogin>>,
  TError,
  { data: LoginCommand },
  TContext
> => {
  const mutationOptions = getPostApiUserLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiUserPersonalInformation = (
  personalInformationCommand: PersonalInformationCommand,
) => {
  return postApiUserPersonalInformationMutator<BooleanValueResult>({
    url: `/api/User/personalInformation`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: personalInformationCommand,
  });
};

export const getPostApiUserPersonalInformationMutationOptions = <
  TError = PostApiUserPersonalInformationErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserPersonalInformation>>,
    TError,
    { data: PersonalInformationCommand },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiUserPersonalInformation>>,
  TError,
  { data: PersonalInformationCommand },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiUserPersonalInformation>>,
    { data: PersonalInformationCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiUserPersonalInformation(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiUserPersonalInformationMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiUserPersonalInformation>>
>;
export type PostApiUserPersonalInformationMutationBody =
  PersonalInformationCommand;
export type PostApiUserPersonalInformationMutationError =
  PostApiUserPersonalInformationErrorType<unknown>;

export const usePostApiUserPersonalInformation = <
  TError = PostApiUserPersonalInformationErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserPersonalInformation>>,
    TError,
    { data: PersonalInformationCommand },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiUserPersonalInformation>>,
  TError,
  { data: PersonalInformationCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiUserPersonalInformationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiUserRecoverPassword = (
  recoverPasswordCommand: RecoverPasswordCommand,
) => {
  return postApiUserRecoverPasswordMutator<BooleanValueResult>({
    url: `/api/User/recoverPassword`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: recoverPasswordCommand,
  });
};

export const getPostApiUserRecoverPasswordMutationOptions = <
  TError = PostApiUserRecoverPasswordErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserRecoverPassword>>,
    TError,
    { data: RecoverPasswordCommand },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiUserRecoverPassword>>,
  TError,
  { data: RecoverPasswordCommand },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiUserRecoverPassword>>,
    { data: RecoverPasswordCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiUserRecoverPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiUserRecoverPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiUserRecoverPassword>>
>;
export type PostApiUserRecoverPasswordMutationBody = RecoverPasswordCommand;
export type PostApiUserRecoverPasswordMutationError =
  PostApiUserRecoverPasswordErrorType<unknown>;

export const usePostApiUserRecoverPassword = <
  TError = PostApiUserRecoverPasswordErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserRecoverPassword>>,
    TError,
    { data: RecoverPasswordCommand },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiUserRecoverPassword>>,
  TError,
  { data: RecoverPasswordCommand },
  TContext
> => {
  const mutationOptions = getPostApiUserRecoverPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiUserResetPassword = (
  resetPasswordCommand: ResetPasswordCommand,
) => {
  return postApiUserResetPasswordMutator<BooleanValueResult>({
    url: `/api/User/resetPassword`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: resetPasswordCommand,
  });
};

export const getPostApiUserResetPasswordMutationOptions = <
  TError = PostApiUserResetPasswordErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserResetPassword>>,
    TError,
    { data: ResetPasswordCommand },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiUserResetPassword>>,
  TError,
  { data: ResetPasswordCommand },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiUserResetPassword>>,
    { data: ResetPasswordCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiUserResetPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiUserResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiUserResetPassword>>
>;
export type PostApiUserResetPasswordMutationBody = ResetPasswordCommand;
export type PostApiUserResetPasswordMutationError =
  PostApiUserResetPasswordErrorType<unknown>;

export const usePostApiUserResetPassword = <
  TError = PostApiUserResetPasswordErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserResetPassword>>,
    TError,
    { data: ResetPasswordCommand },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiUserResetPassword>>,
  TError,
  { data: ResetPasswordCommand },
  TContext
> => {
  const mutationOptions = getPostApiUserResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postApiUserRefreshToken = (
  refreshTokenCommand: RefreshTokenCommand,
) => {
  return postApiUserRefreshTokenMutator<LoginResponseViewModelValueResult>({
    url: `/api/User/refresh-token`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: refreshTokenCommand,
  });
};

export const getPostApiUserRefreshTokenMutationOptions = <
  TError = PostApiUserRefreshTokenErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserRefreshToken>>,
    TError,
    { data: RefreshTokenCommand },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiUserRefreshToken>>,
  TError,
  { data: RefreshTokenCommand },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiUserRefreshToken>>,
    { data: RefreshTokenCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiUserRefreshToken(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiUserRefreshTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiUserRefreshToken>>
>;
export type PostApiUserRefreshTokenMutationBody = RefreshTokenCommand;
export type PostApiUserRefreshTokenMutationError =
  PostApiUserRefreshTokenErrorType<unknown>;

export const usePostApiUserRefreshToken = <
  TError = PostApiUserRefreshTokenErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserRefreshToken>>,
    TError,
    { data: RefreshTokenCommand },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiUserRefreshToken>>,
  TError,
  { data: RefreshTokenCommand },
  TContext
> => {
  const mutationOptions = getPostApiUserRefreshTokenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getApiUserPreference = (
  params?: GetApiUserPreferenceParams,
  signal?: AbortSignal,
) => {
  return getApiUserPreferenceMutator<BooleanValueResult>({
    url: `/api/UserPreference`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetApiUserPreferenceQueryKey = (
  params?: GetApiUserPreferenceParams,
) => {
  return [`/api/UserPreference`, ...(params ? [params] : [])] as const;
};

export const getGetApiUserPreferenceQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiUserPreference>>,
  TError = GetApiUserPreferenceErrorType<unknown>,
>(
  params?: GetApiUserPreferenceParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiUserPreference>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiUserPreferenceQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiUserPreference>>
  > = ({ signal }) => getApiUserPreference(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiUserPreference>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiUserPreferenceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiUserPreference>>
>;
export type GetApiUserPreferenceQueryError =
  GetApiUserPreferenceErrorType<unknown>;

export const useGetApiUserPreference = <
  TData = Awaited<ReturnType<typeof getApiUserPreference>>,
  TError = GetApiUserPreferenceErrorType<unknown>,
>(
  params?: GetApiUserPreferenceParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiUserPreference>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUserPreferenceQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiUserPreferenceAddOrUpdate = (
  addOrUpdateUserPreferenceCommand: AddOrUpdateUserPreferenceCommand,
) => {
  return postApiUserPreferenceAddOrUpdateMutator<BooleanValueResult>({
    url: `/api/UserPreference/addOrUpdate`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: addOrUpdateUserPreferenceCommand,
  });
};

export const getPostApiUserPreferenceAddOrUpdateMutationOptions = <
  TError = PostApiUserPreferenceAddOrUpdateErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserPreferenceAddOrUpdate>>,
    TError,
    { data: AddOrUpdateUserPreferenceCommand },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiUserPreferenceAddOrUpdate>>,
  TError,
  { data: AddOrUpdateUserPreferenceCommand },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiUserPreferenceAddOrUpdate>>,
    { data: AddOrUpdateUserPreferenceCommand }
  > = (props) => {
    const { data } = props ?? {};

    return postApiUserPreferenceAddOrUpdate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiUserPreferenceAddOrUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiUserPreferenceAddOrUpdate>>
>;
export type PostApiUserPreferenceAddOrUpdateMutationBody =
  AddOrUpdateUserPreferenceCommand;
export type PostApiUserPreferenceAddOrUpdateMutationError =
  PostApiUserPreferenceAddOrUpdateErrorType<unknown>;

export const usePostApiUserPreferenceAddOrUpdate = <
  TError = PostApiUserPreferenceAddOrUpdateErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUserPreferenceAddOrUpdate>>,
    TError,
    { data: AddOrUpdateUserPreferenceCommand },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiUserPreferenceAddOrUpdate>>,
  TError,
  { data: AddOrUpdateUserPreferenceCommand },
  TContext
> => {
  const mutationOptions =
    getPostApiUserPreferenceAddOrUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
