import React from 'react';
import './PopUp.css'; 
import { IconClose } from '../../components/Icons/IconClose'

interface PopUpProps {
  title?: string;  
  message?: string; 
  isOpen: boolean;
  onClose: () => void;
  classes?: string; // New prop for custom classes
  children?: React.ReactNode; // Para contenido personalizado
}

const PopUp: React.FC<PopUpProps> = ({ title, message, isOpen, onClose, classes, children }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Evitar cerrar el popup si se hace clic dentro del contenedor
    if (e.target === e.currentTarget) {
    onClose();
    }
  };

  return (
  <div className={`popup-overlay ${classes}`} onClick={handleOverlayClick}>
    <div className="popup-container">
    <button className="popup-close" onClick={onClose}>
      <IconClose />
    </button>
    {title && <h2 className="popup-title">{title}</h2>} 
    {message && <p className="popup-message">{message}</p>} 
    {children && <div className="popup-content">{children}</div>}
    </div>
  </div>
  );
};


export default PopUp;
