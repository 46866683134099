import * as React from "react";
type IconCircleCheckProps = React.SVGProps<SVGSVGElement>;

export const IconCircleCheck: React.FC<IconCircleCheckProps> = (props) => (
  <svg width={42} height={42} viewBox="0 0 42 42" fill="#ADCAB8" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1335_6358)">
    <path d="M21 42C26.5695 42 31.911 39.7875 35.8492 35.8492C39.7875 31.911 42 26.5695 42 21C42 15.4305 39.7875 10.089 35.8492 6.15076C31.911 2.21249 26.5695 0 21 0C15.4305 0 10.089 2.21249 6.15076 6.15076C2.21249 10.089 0 15.4305 0 21C0 26.5695 2.21249 31.911 6.15076 35.8492C10.089 39.7875 15.4305 42 21 42ZM30.2695 17.1445L19.7695 27.6445C18.9984 28.4156 17.7516 28.4156 16.9887 27.6445L11.7387 22.3945C10.9676 21.6234 10.9676 20.3766 11.7387 19.6137C12.5098 18.8508 13.7566 18.8426 14.5195 19.6137L18.375 23.4691L27.4805 14.3555C28.2516 13.5844 29.4984 13.5844 30.2613 14.3555C31.0242 15.1266 31.0324 16.3734 30.2613 17.1363L30.2695 17.1445Z" />
    </g>
    <defs>
    <clipPath id="clip0_1335_6358">
    <rect width="42" height="42" fill="white"/>
    </clipPath>
    </defs>
  </svg>

  
);
export default IconCircleCheck;