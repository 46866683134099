
import * as React from 'react';

type IconMailSuccesProps = React.SVGProps<SVGSVGElement>;

export const IconMailSucces: React.FC<IconMailSuccesProps> = (props) => (
	<svg width={44} height={43} viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M42.9776 16.0282C42.9756 16.0282 42.9737 16.0275 42.9722 16.0264L23.9083 1.19888C22.7856 0.325633 21.2134 0.325634 20.0906 1.19888L1.02676 16.0264C1.02523 16.0275 1.02335 16.0282 1.02141 16.0282C1.0166 16.0282 1.0127 16.0321 1.0127 16.0369V16.0373V39.3468C1.0127 41.064 2.40472 42.456 4.12185 42.456H39.8771C41.5942 42.456 42.9863 41.064 42.9863 39.3468V16.0373V16.0369C42.9863 16.0321 42.9824 16.0282 42.9776 16.0282Z" fill="#D9D9D9"/>
		<path d="M21.5528 30.0422C21.8235 30.2227 22.1761 30.2227 22.4468 30.0422L41.5438 17.3109C42.2072 16.8686 41.8941 15.8345 41.0968 15.8345H2.90283C2.10549 15.8345 1.79239 16.8686 2.45581 17.3109L21.5528 30.0422Z" fill="#8BA293"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6.68865 6.16431C5.79851 6.16431 5.0769 6.88591 5.0769 7.77605V15.8352H5.07617V19.0583L21.553 30.0429C21.8237 30.2233 22.1764 30.2233 22.4471 30.0429L38.9229 19.059V17.4956C38.9234 17.4793 38.9236 17.463 38.9236 17.4465V7.77605C38.9236 6.88591 38.202 6.16431 37.3119 6.16431H6.68865Z" fill="#FFA692"/>
		<path d="M11.5234 12.6112L32.4762 12.6112" stroke="#EFF4F1" stroke-width="2.87184" stroke-linecap="round"/>
		<path d="M11.5234 19.0582L32.4762 19.0582" stroke="#EFF4F1" stroke-width="2.87184" stroke-linecap="round"/>
	</svg>

);


