import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCircleQuestion,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import CustomMultiSelect, {
  Option,
  OptionSwitch,
} from "../../core/MultiSelect/multi-select";
import CustomizedSwitch from "../../core/CustomizedSwitch/CustomizedSwitch";
import AvatarMenIco from "../../assets/images/avatar-men-ico.png";
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";
import { DataOption } from "./DataOptions";
import { Category } from "../../interfaces";
import circleError from "../../assets/images/circle-exclamation-error.svg";
import companyVideo2 from "../../assets/videos/exporter_test/2.2.gif";
import initialVideo from "../../assets/videos/exporter_test/1.gif";
import exportToEurope from "../../assets/videos/exporter_test/11.1.gif";
import marketingRoutes from "../../assets/videos/exporter_test/13-video.gif";
import regulationKnowledge from "../../assets/videos/exporter_test/14.gif";
import elementsAvailable from "../../assets/videos/exporter_test/16.gif";
import businessPlanImage1 from "../../assets/videos/exporter_test/12.1.gif";
import businessPlanImage from "../../assets/videos/exporter_test/12.3.gif";
import certificationImage from "../../assets/videos/exporter_test/4-slides.gif";
import internationalBusiness from "../../assets/videos/exporter_test/17.gif";
import { APP_ROUTES } from "../../routes";
import { TExporterCategory } from "../../pages/Information/FinalReport/finalReport";

interface Tab {
  title: string;
  contentTitle: string;
  contentRows: QuestionData[][];
}

type ControlType =
  | "ComboSelect"
  | "ComboMultiSelect"
  | "TextBox"
  | "Switch"
  | "";

interface QuestionData {
  moduleName: string;
  index: number;
  question: string;
  class?: string;
  placeholder_1: string;
  placeholder_2?: string;
  controlType: ControlType;
  dataSource?: Option[];
  threeOptions?: boolean;
  allowAddControl?: boolean;
  lateralLabel?: string;
  conditionalAdditionalOptions?: boolean;
  AddFooter?: boolean;
  hasChild?: boolean;
  innerchild: boolean;
  setParent?: number;
  AdditionalOptions_true?: QuestionData[][];
  AdditionalOptions_false?: QuestionData[][];
  required?: boolean;
  tooltip?: boolean;
  tooltipText?: string;
  switchScore?: OptionSwitch[];
}

const imgFooterStyles: React.CSSProperties = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "50% 50%",
  borderRadius: "50%",
};

type IExporterTest = {
  onStepChange: (number: number) => void;
  onChangeImage: (image: string | null) => void;
};

interface ScoreModule {
  moduleName: string;
  questions: ScoreQuestion[];
}

interface ScoreQuestion {
  question: string;
  score: number;
  value?: string | Option[];
}

const CONTROL_TYPES = {
  SWITCH: "Switch",
  COMBO_SELECT: "ComboSelect",
  COMBO_MULTI_SELECT: "ComboMultiSelect",
};

const ExporterTest = ({ onStepChange, onChangeImage }: IExporterTest) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [scores, setScores] = useState<ScoreModule[]>([]);
  const {
    Categorias,
    destinos,
    RangoVentas,
    Canales,
    TiempoEsperado,
    ExporNoEU,
    Frecuencia,
    Elementos,
    BusinessPlan,
    certificaciones,
    RRHH,
    Promociones,
    FuentesFinanciamiento,
    Obstaculos
   } = DataOption()

  interface ModuleStates {
    mercado_europeo: boolean;
    exportaciones: boolean;
    comercio_internacional: boolean;
  }

  const [moduleStates, setModuleStates] = useState<ModuleStates>({
    mercado_europeo: false,
    exportaciones: false,
    comercio_internacional: false,
  });

  const [totalFormRows, setTotalFormRows] = useState<any[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [product, setProduct] = useState<string>("");
  const [checkExportEurope, setCheckExportEurope] = useState<boolean>(false);
  const [valueExportEurope, setValueExportEurope] = useState<boolean>(false);
  const [checkEstablishedCountry, setCheckEstablishedCountry] =
    useState<boolean>(false);
  const [checkMarketingChannels, setCheckMarketingChannels] =
    useState<boolean>(false);
  const [checkNecessaryRegulation, setCheckNecessaryRegulation] =
    useState<boolean>(false);
  const [selectedExportCountry, setSelectedExportCountry] = useState<any[]>([]);
  const [salesPerYear, setSalesPerYear] = useState({
    2020: "",
    2021: "",
    2022: "",
    2023: "",
  });
  const [countryNextGoalSelect, setCountryNextGoalSelect] = useState<any[]>([]);
  const [marketingChannelSelect, setMarketingChannelSelect] = useState<any[]>(
    []
  );
  const [checkExports, setCheckExports] = useState<boolean>(false);
  const [valueExports, setValueExports] = useState<boolean>(false);
  const [exportFrequencyList, setExportFrequencyList] = useState<any[]>([]);
  const [exportCountryList, setExportCountryList] = useState<any[]>([]);
  const [humanCapitalList, setHumanCapitalList] = useState<any[]>([]);
  const [companyElementList, setCompanyElementList] = useState<any[]>([]);
  const [companyCertification, setCompanyCertificationList] = useState<any[]>(
    []
  );
  const [companyResources, setCompanyResources] = useState<any[]>([]);
  const [businessPlan, setBusinessPlan] = useState<string>("");
  const [checkBusinessPlan, setCheckBusinessPlan] = useState<boolean>(false);
  const [businessPlanValue, setBusinessPlanValue] = useState<boolean>(false);
  const [marketingChannelValue, setMarketingChannelValue] =
    useState<boolean>(false);
  const [countryNextGoalValue, setCountryNextGoalValue] =
    useState<boolean>(false);
  const [businessReadyExport, setBusinessReadyExport] = useState<string>("");
  const [certificationEnough, setCertificationEnough] =
    useState<boolean>(false);

  const [promotionalActivitiesCheck, setPromotionalActivitiesCheck] =
    useState<boolean>(false);
  const [promotionalActivitiesValue, setPromotionalActivitiesValue] =
    useState<boolean>(false);
  const [promotionalActivitiesList, setPromotionalActivitiesList] = useState<
    any[]
  >([]);

  const [financingSourcesCheck, setFinancingSourcesCheck] =
    useState<boolean>(false);
  const [financingSourcesValue, setFinancingSourcesValue] =
    useState<boolean>(false);
  const [financingSourcesList, setFinancingSourcesList] = useState<any[]>([]);
  const [mainObstaclesList, setMainObstaclesList] = useState<any[]>([]);

  const [isErrorCategoryEmpty, setIsErrorCategoryEmpty] =
    useState<boolean>(false);
  const [isErrorProductEmpty, setIsErrorProductEmpty] =
    useState<boolean>(false);
  const [isErrorExportEurope, setIsErrorExportEurope] =
    useState<boolean>(false);
  const [isErrorEstablishedCountry, setIsErrorEstablishedCountry] =
    useState<boolean>(false);
  const [isErrorMarketingChannels, setIsErrorMarketingChannels] =
    useState<boolean>(false);
  const [isErrorNecessaryRegulation, setIsErrorNecessaryRegulation] =
    useState<boolean>(false);
  const [isErrorExportCountryEmpty, setIsErrorExportCountryEmpty] =
    useState<boolean>(false);
  const [isErrorSalesPerYear, setIsErrorSalesPerYear] =
    useState<boolean>(false);
  const [isErrorExports, setIsErrorExports] = useState<boolean>(false);
  const [isErrorExportFrequency, setIsErrorExportFrequency] =
    useState<boolean>(false);
  const [isErrorHumanCapital, setIsErrorHumanCapital] =
    useState<boolean>(false);
  const [isErrorCompanyElement, setIsErrorCompanyElement] =
    useState<boolean>(false);
  const [isErrorCompanyCertification, setIsErrorCompanyCertification] =
    useState<boolean>(false);
  const [isErrorCompanyResourcesEmpty, setIsErrorCompanyResourcesEmpty] =
    useState<boolean>(false);
  const [isErrorBusinessPlanEmpty, setIsErrorBusinessPlanEmpty] =
    useState<boolean>(false);
  const [isErrorCheckBusinessPlanEmpty, setIsErrorCheckBusinessPlanEmpty] =
    useState<boolean>(false);
  const [isErrorBusinessReadyExport, setIsErrorBusinessReadyExport] =
    useState<boolean>(false);
  const [isErrorCertificationEnough, setIsErrorCertificationEnough] =
    useState<boolean>(false);

  const [isErrorPromotionalActivities, setIsErrorPromotionalActivities] =
    useState<boolean>(false);
  const [isErrorFinancingSources, setIsErrorFinancingSources] =
    useState<boolean>(false);
  const [isErrorMainObstacles, setIsErrorMainObstacles] =
    useState<boolean>(false);

  useEffect(() => {
    onChangeTab(activeTab);
  }, [activeTab]);

  const onChangeTab = (activeTab: number) => {
    if (activeTab === 0) {
      onChangeImage(initialVideo);
    }
    if (activeTab === 3) {
      onChangeImage(internationalBusiness);
    }
  };

  const handleSwitchChange = (moduleName: string, isChecked: boolean) => {
    if (
      (valueExportEurope && moduleName === "mercado_europeo_4") ||
      moduleName === "mercado_europeo_2"
    ) {
      setCheckEstablishedCountry(true);
      setIsErrorEstablishedCountry(isChecked);
      setCountryNextGoalValue(isChecked);
    }

    if (
      moduleName === "mercado_europeo_6" ||
      (!valueExportEurope && moduleName === "mercado_europeo_4")
    ) {
      if (isChecked) {
        onChangeImage(marketingRoutes);
      } else {
        //ALEX onChangeImage(exportToEurope);
        onChangeImage(marketingRoutes);
      }
      setMarketingChannelValue(isChecked);
      setCheckMarketingChannels(true);
      setIsErrorMarketingChannels(isChecked);
    }

    if (
      moduleName === "mercado_europeo_9" ||
      moduleName === "mercado_europeo_5"
    ) {
      if (isChecked) {
        onChangeImage(regulationKnowledge);
      } else {
        // ALEX onChangeImage(exportToEurope);
        onChangeImage(regulationKnowledge);
      }
      setCheckNecessaryRegulation(true);
      setIsErrorNecessaryRegulation(false);
    }

    if (moduleName === "exportaciones_10" || moduleName === "exportaciones_4") {
      if (isChecked) {
        onChangeImage(businessPlanImage);
      } else {
        // ALEX else lo agregé yo
        onChangeImage(businessPlanImage1);
      }
      setCheckBusinessPlan(true);
      setBusinessPlanValue(isChecked);
      setIsErrorCheckBusinessPlanEmpty(false);
    }

    if (moduleName === "exportaciones_7" || moduleName === "exportaciones_12") {
      if (isChecked) {
        onChangeImage(certificationImage);
      } else {
        // ALEX else lo agregé yo
        onChangeImage(certificationImage);
      }
      setCertificationEnough(true);
      setIsErrorCertificationEnough(false);
    }

    if (moduleName === "comercio_internacional_0") {
      setPromotionalActivitiesCheck(true);
      setPromotionalActivitiesValue(isChecked);
      setIsErrorPromotionalActivities(false);
    }

    if (moduleName === "comercio_internacional_2") {
      setFinancingSourcesCheck(true);
      setFinancingSourcesValue(isChecked);
      setIsErrorFinancingSources(false);
    }

    setModuleStates((prevModuleStates) => ({
      ...prevModuleStates,
      [moduleName]: isChecked,
    }));
  };

  const [initialLoad_mercadoEuropeo, setInitialLoad_mercadoEuropeo] =
    useState(true);
  const [initialLoad_exportaciones, setInitialLoad_exportaciones] =
    useState(true);

  const handleSwitchChange_mercadoEuropeo = (checked: boolean) => {
    if (checked) {
      onChangeImage(exportToEurope);
    } else {
      // ALEX onChangeImage(companyVideo2);
      onChangeImage(exportToEurope);
    }
    setCheckExportEurope(true);
    setIsErrorExportEurope(false);
    setInitialLoad_mercadoEuropeo(false);
    handleSwitchChange("mercado_europeo", checked);
  };

  const handleSwitchChange_exportaciones = (checked: boolean) => {
    if (checked) {
      onChangeImage(elementsAvailable);
    }
    setCheckExports(true);
    setIsErrorExports(false);
    setInitialLoad_exportaciones(false);
    handleSwitchChange("exportaciones", checked);
  };

  const navigate = useNavigate();

  const [tabs] = useState<Tab[]>([
    {
      title: t("exporterTest.step_1.name"),
      contentTitle: "",
      contentRows: [
        [
          {
            moduleName: "empresa",
            index: 0,
            question: t("exporterTest.step_1.category.question"),
            placeholder_1: t("exporterTest.step_1.category.placeholder_1"),
            controlType: "ComboMultiSelect",
            dataSource: Categorias,
            class: "exporter-test__dropdown-general-middle",
            innerchild: false,
            required: true,
          },
          {
            moduleName: "empresa",
            index: 1,
            question: t("exporterTest.step_1.description.question"),
            placeholder_1: t("exporterTest.step_1.description.placeholder_1"),
            placeholder_2: t("exporterTest.step_1.description.placeholder_2"),
            controlType: "TextBox",
            allowAddControl: true,
            AddFooter: true,
            innerchild: false,
            required: true,
          },
        ],
      ],
    },
    {
      title: t("exporterTest.step_2.name"),
      contentTitle: "",
      contentRows: [
        [
          {
            moduleName: "mercado_europeo",
            index: 0,
            question: t("exporterTest.step_2.question_1.title"),
            placeholder_1: t("exporterTest.step_2.question_1.placeholder"),
            controlType: "Switch",
            threeOptions: false,
            hasChild: true,
            allowAddControl: false,
            innerchild: false,
            switchScore: [
              {
                label: "option1",
                score: 33,
              },
              {
                label: "option2",
                score: 0,
              },
            ],
            AdditionalOptions_true: [
              [
                {
                  moduleName: "mercado_europeo",
                  index: 2,
                  question: "",
                  placeholder_1: t(
                    "exporterTest.step_2.question_1.placeholder_1"
                  ),
                  dataSource: destinos,
                  controlType: "ComboMultiSelect",
                  class: "exporter-test__dropdown-general-full",
                  innerchild: false,
                },
                {
                  moduleName: "mercado_europeo",
                  index: 3,
                  question: t("exporterTest.step_2.question_2a.title"),
                  placeholder_1: t(
                    "exporterTest.step_2.question_2a.placeholder_1"
                  ),
                  controlType: "ComboSelect",
                  dataSource: RangoVentas,
                  class: "exporter-test__dropdown-general-middle",
                  lateralLabel: "2020,2021,2022,2023",
                  innerchild: false,
                  tooltip: true,
                  tooltipText:
                    "La precisión en la indicación del tramo de ventas anuales es crucial para adecuar nuestras recomendaciones a las necesidades específicas de tu empresa. Esta información se utiliza únicamente con fines estadísticos y estratégicos, asegurando la confidencialidad y privacidad de tus datos financieros.",
                },
                {
                  moduleName: "mercado_europeo",
                  index: 4,
                  question: t("exporterTest.step_2.question_3.title"),
                  placeholder_1: "",
                  controlType: "Switch",
                  switchScore: [
                    { label: "option1", score: 2 },
                    { label: "option2", score: 0 },
                    { label: "option3", score: 0 },
                  ],
                  class: "exporter-test__dropdown-general-middle",
                  hasChild: true,
                  innerchild: true,
                  tooltip: true,
                  tooltipText:
                    "Al tener un mercado objetivo claro, puedes concentrar tus esfuerzos en comprender las regulaciones, preferencias y comportamientos del consumidor de ese país en particular. Esta información te brindará una ventaja competitiva al abordar de manera más precisa las necesidades del mercado y facilitar una entrada exitosa.",
                  AdditionalOptions_true: [
                    [
                      {
                        moduleName: "mercado_europeo",
                        index: 5,
                        question: "",
                        placeholder_1: t(
                          "exporterTest.step_2.question_3.placeholder_1"
                        ),
                        dataSource: destinos,
                        controlType: "ComboMultiSelect",
                        class: "exporter-test__dropdown-general-full",
                        innerchild: false,
                      },
                    ],
                  ],
                },
                {
                  moduleName: "mercado_europeo",
                  index: 6,
                  question: t("exporterTest.step_2.question_4.title"),
                  placeholder_1: "",
                  controlType: "Switch",
                  class: "exporter-test__dropdown-general-middle",
                  hasChild: true,
                  innerchild: true,
                  switchScore: [
                    { label: "option1", score: 2 },
                    { label: "option2", score: 0 },
                    { label: "option3", score: 0 },
                  ],
                  AdditionalOptions_true: [
                    [
                      {
                        moduleName: "mercado_europeo",
                        index: 7,
                        question: "",
                        placeholder_1: t(
                          "exporterTest.step_2.question_4.placeholder_1"
                        ),
                        controlType: "ComboMultiSelect",
                        dataSource: Canales,
                        class: "exporter-test__dropdown-general-full",
                        innerchild: false,
                      },
                    ],
                  ],
                },
                {
                  moduleName: "mercado_europeo",
                  index: 9,
                  question: t("exporterTest.step_2.question_5.title"),
                  placeholder_1: "",
                  controlType: "Switch",
                  class: "exporter-test__dropdown-general-middle",
                  innerchild: false,
                  tooltip: true,
                  tooltipText:
                    "Las regulaciones son las leyes y normativas específicas que rigen la entrada de productos y servicios en el mercado europeo. Esto incluye requisitos de calidad, estándares de seguridad, etiquetado y otros aspectos legales que deben cumplirse.",
                  switchScore: [
                    { label: "option1", score: 8 },
                    { label: "option2", score: 0 },
                    { label: "option3", score: 0 },
                  ],
                },
              ],
            ],
            AdditionalOptions_false: [
              [
                {
                  moduleName: "mercado_europeo",
                  index: 1,
                  question: t("exporterTest.step_2.question_2b.title"),
                  placeholder_1: t(
                    "exporterTest.step_2.question_2b.placeholder_1"
                  ),
                  controlType: "ComboSelect",
                  dataSource: TiempoEsperado,
                  class: "exporter-test__dropdown-general-full",
                  innerchild: false,
                },
                {
                  moduleName: "mercado_europeo",
                  index: 2,
                  question: t("exporterTest.step_2.question_3.title"),
                  placeholder_1: "",
                  controlType: "Switch",
                  class: "exporter-test__dropdown-general-middle",
                  hasChild: true,
                  innerchild: true,
                  tooltip: true,
                  tooltipText:
                    "Al tener un mercado objetivo claro, puedes concentrar tus esfuerzos en comprender las regulaciones, preferencias y comportamientos del consumidor de ese país en particular. Esta información te brindará una ventaja competitiva al abordar de manera más precisa las necesidades del mercado y facilitar una entrada exitosa.",
                  switchScore: [
                    { label: "option1", score: 2 },
                    { label: "option2", score: 0 },
                    { label: "option3", score: 0 },
                  ],
                  AdditionalOptions_true: [
                    [
                      {
                        moduleName: "mercado_europeo",
                        index: 3,
                        question: "",
                        placeholder_1: t(
                          "exporterTest.step_2.question_3.placeholder_1"
                        ),
                        dataSource: destinos,
                        controlType: "ComboMultiSelect",
                        class: "exporter-test__dropdown-general-full",
                        innerchild: false,
                        tooltip: true,
                        tooltipText:
                          "La precisión en la indicación del tramo de ventas anuales es crucial para adecuar nuestras recomendaciones a las necesidades específicas de tu empresa. Esta información se utiliza únicamente con fines estadísticos y estratégicos, asegurando la confidencialidad y privacidad de tus datos financieros.",
                      },
                    ],
                  ],
                  AdditionalOptions_false: [
                    [
                      {
                        moduleName: "mercado_europeo",
                        index: 3,
                        question: "",
                        placeholder_1: "Países objetivos",
                        dataSource: destinos,
                        controlType: "ComboMultiSelect",
                        class: "exporter-test__dropdown-general-full",
                        innerchild: false,
                        tooltip: true,
                        tooltipText:
                          "La precisión en la indicación del tramo de ventas anuales es crucial para adecuar nuestras recomendaciones a las necesidades específicas de tu empresa. Esta información se utiliza únicamente con fines estadísticos y estratégicos, asegurando la confidencialidad y privacidad de tus datos financieros.",
                      },
                    ],
                  ],
                },
                {
                  moduleName: "mercado_europeo",
                  index: 4,
                  question: t("exporterTest.step_2.question_4.title"),
                  placeholder_1: "",
                  controlType: "Switch",
                  class: "exporter-test__dropdown-general-middle",
                  hasChild: true,
                  innerchild: true,
                  switchScore: [
                    { label: "option1", score: 2 },
                    { label: "option2", score: 0 },
                    { label: "option3", score: 0 },
                  ],
                  AdditionalOptions_true: [
                    [
                      {
                        moduleName: "mercado_europeo",
                        index: 7,
                        question: "",
                        placeholder_1: t(
                          "exporterTest.step_2.question_4.placeholder_1"
                        ),
                        controlType: "ComboMultiSelect",
                        dataSource: Canales,
                        class: "exporter-test__dropdown-general-full",
                        innerchild: false,
                      },
                    ],
                  ],
                },
                {
                  moduleName: "mercado_europeo",
                  index: 5,
                  question: t("exporterTest.step_2.question_5.title"),
                  placeholder_1: "",
                  controlType: "Switch",
                  class: "exporter-test__dropdown-general-middle",
                  innerchild: false,
                  tooltip: true,
                  tooltipText:
                    "Las regulaciones son las leyes y normativas específicas que rigen la entrada de productos y servicios en el mercado europeo. Esto incluye requisitos de calidad, estándares de seguridad, etiquetado y otros aspectos legales que deben cumplirse.",
                  switchScore: [
                    { label: "option1", score: 8 },
                    { label: "option2", score: 0 },
                    { label: "option3", score: 0 },
                  ],
                },
              ],
            ],
          },
        ],
      ],
    },
    {
      title: t("exporterTest.step_3.name"),
      contentTitle: "",
      contentRows: [
        [
          {
            moduleName: "exportaciones",
            index: 0,
            question: t("exporterTest.step_3.question_1.title"),
            placeholder_1: t("exporterTest.step_3.question_1.placeholder"),
            controlType: "Switch",
            threeOptions: false,
            hasChild: true,
            allowAddControl: false,
            innerchild: false,
            switchScore: [
              {
                label: "option1",
                score: 15,
              },
              {
                label: "option2",
                score: 0,
              },
            ],
            AdditionalOptions_true: [
              [
                {
                  moduleName: "exportaciones",
                  index: 1,
                  question: "",
                  //placeholder_1: "Países fuera de Europa",
                  placeholder_1: t(
                    "exporterTest.step_3.question_1.placeholder_1"
                  ),
                  controlType: "ComboMultiSelect",
                  dataSource: ExporNoEU,
                  class: "exporter-test__dropdown-general-full",
                  innerchild: false,
                },
                {
                  moduleName: "exportaciones",
                  index: 2,
                  question: t("exporterTest.step_3.question_2a.title"),
                  placeholder_1: t(
                    "exporterTest.step_3.question_2a.placeholder_1"
                  ),
                  controlType: "ComboSelect",
                  dataSource: Frecuencia,
                  class: "exporter-test__dropdown-general-full",
                  innerchild: false,
                  tooltip: false,
                  tooltipText: t("exporterTest.step_3.question_2a.tooltip"),
                },
                {
                  moduleName: "exportaciones",
                  index: 3,
                  question: `3. ${t("exporterTest.step_3.question_3.title")}`,
                  placeholder_1: t(
                    "exporterTest.step_3.question_3.placeholder_1"
                  ),
                  controlType: "ComboMultiSelect",
                  dataSource: Elementos,
                  class: "exporter-test__dropdown-general-full",
                  innerchild: false,
                  tooltip: true,
                  tooltipText: t("exporterTest.step_3.question_3.tooltip"),
                },
                {
                  moduleName: "exportaciones",
                  index: 4,
                  question: `4. ${t("exporterTest.step_3.question_4.title")}`,
                  placeholder_1: "",
                  controlType: "Switch",
                  class: "exporter-test__dropdown-general-middle",
                  hasChild: true,
                  innerchild: true,
                  tooltip: true,
                  tooltipText: t("exporterTest.step_3.question_4.tooltip"),
                  switchScore: [
                    {
                      label: "option1",
                      score: 5,
                    },
                    {
                      label: "option2",
                      score: 0,
                    },
                    {
                      label: "option3",
                      score: 0,
                    },
                  ],
                  AdditionalOptions_true: [
                    [
                      {
                        moduleName: "mercado_europeo",
                        index: 5,
                        question: "",
                        placeholder_1: t(
                          "exporterTest.step_3.question_4.placeholder_1"
                        ),
                        controlType: "ComboSelect",
                        dataSource: BusinessPlan,
                        class: "exporter-test__dropdown-general-full",
                        innerchild: false,
                      },
                    ],
                  ],
                },
                {
                  moduleName: "exportaciones",
                  index: 6,
                  question: `5. ${t("exporterTest.step_3.question_5.title")}`,
                  placeholder_1: t(
                    "exporterTest.step_3.question_5.placeholder_1"
                  ),
                  controlType: "ComboMultiSelect",
                  dataSource: certificaciones,
                  class: "exporter-test__dropdown-general-middle",
                  innerchild: false,
                },
                {
                  moduleName: "exportaciones",
                  index: 7,
                  question: `6. ${t("exporterTest.step_3.question_6.title")}`,
                  placeholder_1: "",
                  controlType: "Switch",
                  class: "exporter-test__dropdown-general-middle",
                  innerchild: false,
                  switchScore: [
                    {
                      label: "option1",
                      score: 0,
                    },
                    {
                      label: "option2",
                      score: 0,
                    },
                    {
                      label: "option3",
                      score: 0,
                    },
                  ],
                },
                {
                  moduleName: "exportaciones",
                  index: 8,
                  question: `7. ${t("exporterTest.step_3.question_7.title")}`,
                  placeholder_1: t(
                    "exporterTest.step_3.question_7.placeholder_1"
                  ),
                  controlType: "ComboSelect",
                  dataSource: RRHH,
                  class: "exporter-test__dropdown-general-full",
                  innerchild: false,
                  tooltip: true,
                  tooltipText: t("exporterTest.step_3.question_7.tooltip"),
                },
              ],
            ],
            AdditionalOptions_false: [
              [
                {
                  moduleName: "exportaciones",
                  index: 9,
                  question: t("exporterTest.step_3.question_2b.title"),
                  placeholder_1: t(
                    "exporterTest.step_3.question_2b.placeholder_1"
                  ),
                  controlType: "ComboMultiSelect",
                  dataSource: Elementos,
                  class: "exporter-test__dropdown-general-full",
                  innerchild: false,
                },
                {
                  moduleName: "exportaciones",
                  index: 10,
                  question: `3. ${t("exporterTest.step_3.question_4.title")}`,
                  placeholder_1: "",
                  controlType: "Switch",
                  class: "exporter-test__dropdown-general-full",
                  hasChild: true,
                  innerchild: true,
                  tooltip: true,
                  tooltipText: t("exporterTest.step_3.question_4.tooltip"),
                  AdditionalOptions_true: [
                    [
                      {
                        moduleName: "mercado_europeo",
                        index: 5,
                        question: "",
                        placeholder_1: t(
                          "exporterTest.step_3.question_4.placeholder_1"
                        ),
                        controlType: "ComboSelect",
                        dataSource: BusinessPlan,
                        class: "exporter-test__dropdown-general-full",
                        innerchild: false,
                      },
                    ],
                  ],
                },
                {
                  moduleName: "exportaciones",
                  index: 11,
                  question: `4. ${t("exporterTest.step_3.question_5.title")}`,
                  placeholder_1: t(
                    "exporterTest.step_3.question_5.placeholder_1"
                  ),
                  controlType: "ComboMultiSelect",
                  dataSource: certificaciones,
                  class: "exporter-test__dropdown-general-full",
                  innerchild: false,
                },
                {
                  moduleName: "exportaciones",
                  index: 12,
                  question: `5. ${t("exporterTest.step_3.question_6.title")}`,
                  placeholder_1: "",
                  controlType: "Switch",
                  class: "exporter-test__dropdown-general-middle",
                  innerchild: false,
                },
                {
                  moduleName: "exportaciones",
                  index: 13,
                  question: `6. ${t("exporterTest.step_3.question_7.title")}`,
                  placeholder_1: t(
                    "exporterTest.step_3.question_7.placeholder_1"
                  ),
                  controlType: "ComboSelect",
                  dataSource: RRHH,
                  class: "exporter-test__dropdown-general-full",
                  innerchild: false,
                  tooltip: true,
                  tooltipText: t("exporterTest.step_3.question_7.tooltip"),
                },
              ],
            ],
          },
        ],
      ],
    },
    {
      title: t("exporterTest.step_4.name"),
      contentTitle: "",
      contentRows: [
        [
          {
            moduleName: "comercio_internacional",
            index: 0,
            question: t("exporterTest.step_4.question_1.title"),
            placeholder_1: "",
            controlType: "Switch",
            class: "exporter-test__dropdown-general-full",
            hasChild: true,
            innerchild: true,
            switchScore: [
              {
                label: "option1",
                score: 0,
              },
              {
                label: "option2",
                score: 0,
              },
              {
                label: "option3",
                score: 0,
              },
            ],
            AdditionalOptions_true: [
              [
                {
                  moduleName: "comercio_internacional",
                  index: 1,
                  setParent: 0,
                  question: "",
                  placeholder_1: t(
                    "exporterTest.step_4.question_1.placeholder_1"
                  ),
                  dataSource: Promociones,
                  controlType: "ComboMultiSelect",
                  class: "exporter-test__dropdown-general-full",
                  innerchild: false,
                },
              ],
            ],
          },
          {
            moduleName: "comercio_internacional",
            index: 2,
            question: t("exporterTest.step_4.question_2.title"),
            placeholder_1: "",
            controlType: "Switch",
            threeOptions: true,
            allowAddControl: false,
            AddFooter: false,
            innerchild: true,
            hasChild: true,
            tooltip: true,
            tooltipText:
              "¿Tu empresa cuenta con fuentes de financiamiento sólidas para afrontar una demanda internacional próxima? Esto incluye acceso a créditos, programas de apoyo gubernamental u otras opciones financieras.",
            switchScore: [
              {
                label: "option1",
                score: 5,
              },
              {
                label: "option2",
                score: 0,
              },
              {
                label: "option3",
                score: 0,
              },
            ],
            AdditionalOptions_true: [
              [
                {
                  moduleName: "comercio_internacional",
                  index: 3,
                  setParent: 2,
                  question: "",
                  dataSource: FuentesFinanciamiento,
                  placeholder_1: t(
                    "exporterTest.step_4.question_2.placeholder_1"
                  ),
                  controlType: "ComboMultiSelect",
                  class: "exporter-test__dropdown-general-full",
                  innerchild: false,
                  tooltip: true,
                  tooltipText:
                    "¿Tu empresa cuenta con fuentes de financiamiento sólidas para afrontar una demanda internacional próxima? Esto incluye acceso a créditos, programas de apoyo gubernamental u otras opciones financieras.",
                },
              ],
            ],
          },
          {
            moduleName: "comercio_internacional",
            index: 4,
            question: t("exporterTest.step_4.question_3.title"),
            placeholder_1: t("exporterTest.step_4.question_3.placeholder_1"),
            controlType: "ComboMultiSelect",
            dataSource: Obstaculos,
            class: "exporter-test__dropdown-general-full",
            threeOptions: true,
            allowAddControl: false,
            AddFooter: false,
            innerchild: true,
            hasChild: true,
          },
        ],
      ],
    },
  ]);

  const handleContinueClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (activeTab === 0) {
      const isErrorCategory = selectedCategories.length === 0;
      const isErrorProduct = product.length === 0;

      setIsErrorCategoryEmpty(isErrorCategory);
      setIsErrorProductEmpty(isErrorProduct);

      if (isErrorCategory || isErrorProduct) {
        return;
      }
    }

    if (activeTab === 1) {
      const isErrorExportEurope = !checkExportEurope;
      const isErrorEstablishedCountry =
        !checkEstablishedCountry ||
        (countryNextGoalValue && countryNextGoalSelect.length === 0);
      const isErrorMarketingChannels =
        !checkMarketingChannels ||
        (marketingChannelValue && marketingChannelSelect.length === 0);
      const isErrorNecessaryRegulation = !checkNecessaryRegulation;
      const isErrorExportCountry = selectedExportCountry.length === 0;
      const isErrorSalesPerYear =
        salesPerYear[2020] === "" ||
        salesPerYear[2021] === "" ||
        salesPerYear[2022] === "" ||
        salesPerYear[2023] === "";
      const isErrorBusinessReadyExport = businessReadyExport.length === 0;

      setIsErrorExportEurope(isErrorExportEurope);
      setIsErrorEstablishedCountry(isErrorEstablishedCountry);
      setIsErrorMarketingChannels(isErrorMarketingChannels);
      setIsErrorNecessaryRegulation(isErrorNecessaryRegulation);
      setIsErrorExportCountryEmpty(isErrorExportCountry);
      setIsErrorSalesPerYear(isErrorSalesPerYear);
      setIsErrorBusinessReadyExport(isErrorBusinessReadyExport);

      if (
        isErrorExportEurope ||
        isErrorEstablishedCountry ||
        (!valueExportEurope &&
          countryNextGoalValue &&
          countryNextGoalSelect.length === 0) ||
        isErrorMarketingChannels ||
        (!valueExportEurope &&
          marketingChannelValue &&
          marketingChannelSelect.length === 0) ||
        isErrorNecessaryRegulation ||
        (valueExportEurope && isErrorExportCountry) ||
        (valueExportEurope && isErrorSalesPerYear) ||
        (!valueExportEurope && isErrorBusinessReadyExport)
      ) {
        return;
      }
    }

    if (activeTab === 2) {
      const isErrorExports = !checkExports;
      const isErrorExportCountry = exportCountryList.length === 0;
      const isErrorExportFrequency = exportFrequencyList.length === 0;
      const isErrorHumanCapital = humanCapitalList.length === 0;
      const isErrorCompanyElement = companyElementList.length === 0;
      const isErrorCompanyCertification = companyCertification.length === 0;
      const isErrorCompanyResources = companyResources.length === 0;
      const isErrorBussinessPlan =
        (businessPlanValue && businessPlan.length === 0) || !checkBusinessPlan;
      const isErrorCertificationEnough = !certificationEnough;

      setIsErrorExports(isErrorExports);
      setIsErrorExportCountryEmpty(isErrorExportCountry);
      setIsErrorExportFrequency(isErrorExportFrequency);
      setIsErrorHumanCapital(isErrorHumanCapital);
      setIsErrorCompanyElement(isErrorCompanyElement);
      setIsErrorCompanyCertification(isErrorCompanyCertification);
      setIsErrorCompanyResourcesEmpty(isErrorCompanyResources);
      setIsErrorBusinessPlanEmpty(isErrorBussinessPlan);
      setIsErrorCheckBusinessPlanEmpty(isErrorBussinessPlan);
      setIsErrorCertificationEnough(isErrorCertificationEnough);

      if (
        isErrorExports ||
        (valueExports && isErrorExportFrequency) ||
        isErrorHumanCapital ||
        (valueExports && isErrorCompanyElement) ||
        isErrorCompanyCertification ||
        isErrorBussinessPlan ||
        (!valueExports && isErrorCompanyResourcesEmpty) ||
        isErrorCertificationEnough ||
        (valueExports && isErrorExportCountry)
      ) {
        return;
      }
    }

    if (activeTab === 3) {
      const isErrorPromotionalActivities =
        (promotionalActivitiesValue &&
          promotionalActivitiesList.length === 0) ||
        !promotionalActivitiesCheck;
      const isErrorFinancingSources =
        (financingSourcesValue && financingSourcesList.length === 0) ||
        !financingSourcesCheck;
      const isErrorMainObstacles = mainObstaclesList.length === 0;

      setIsErrorPromotionalActivities(isErrorPromotionalActivities);
      setIsErrorFinancingSources(isErrorFinancingSources);
      setIsErrorMainObstacles(isErrorMainObstacles);

      if (
        isErrorPromotionalActivities ||
        isErrorFinancingSources ||
        isErrorMainObstacles
      ) {
        return;
      }
    }

    if (activeTab < tabs.length - 1) {
      setActiveTab(activeTab + 1);
      updatePercentage(activeTab);
    } else {
      const [category, level] = evaluateExporterCategory();
      if (category) {
        navigate(APP_ROUTES.finalReportTestExporter, {
          state: {
            variation: "register_ok",
            category: category,
            level: level
          },
        });
      }
    }
  };

  const handleTabClick = (index: number) => {
    if (index <= activeTab) {
      setActiveTab(index);
      index === 0
        ? onStepChange(0)
        : index === 1
          ? onStepChange(25)
          : index === 2
            ? onStepChange(50)
            : index === 3
              ? onStepChange(75)
              : onStepChange(75);
    }
  };

  const updatePercentage = (stepActive: number) => {
    switch (stepActive) {
      case 0:
        onStepChange(25);
        break;
      case 1:
        onStepChange(50);
        break;
      case 2:
        onStepChange(75);
        break;
    }
  };

  const onHandleAddRow = () => {
    const totalRows = [...totalFormRows];
    if (!totalRows.includes(totalFormRows.length + 1)) {
      totalRows.push(totalFormRows.length + 1);
    } else {
      totalRows.push(totalFormRows.length + 2);
    }
    setTotalFormRows(totalRows);
  };

  const listProducts = () => {
    const elements = totalFormRows.map((row, index) => formRow(row, index));
    return elements;
  };

  const onHandleDeleteRow = (indexToDelete: number) => {
    const data = totalFormRows.filter((_, index) => index !== indexToDelete);
    setTotalFormRows(data);
  };

  const onHandleChangeCategory = (value: any, option: any) => {
    if (`${option.moduleName}_${option.index}` === "comercio_internacional_4") {
      value.length !== 0 && setIsErrorMainObstacles(false);
      setMainObstaclesList(value);
    }

    value.length !== 0 && setIsErrorCategoryEmpty(false);
    setSelectedCategories(value);
  };

  const onHandleChangeProduct = (event: any) => {
    event.target.value.length !== 0 && setIsErrorProductEmpty(false);
    setProduct(event.target.value);
  };

  const onHandleChangeComboMultiSelect = (value: any, option: any) => {
    if (`${option.moduleName}_${option.index}` === "mercado_europeo_2") {
      value.length !== 0 && setIsErrorExportCountryEmpty(false);
      setSelectedExportCountry(value);
    }

    if (`${option.moduleName}_${option.index}` === "exportaciones_3") {
      value.length !== 0 && setIsErrorCompanyElement(false);
      setCompanyElementList(value);
    }

    if (`${option.moduleName}_${option.index}` === "exportaciones_6") {
      value.length !== 0 && setIsErrorCompanyCertification(false);
      setCompanyCertificationList(value);
    }

    if (`${option.moduleName}_${option.index}` === "exportaciones_1") {
      value.length !== 0 && setIsErrorExportCountryEmpty(false);
      setExportCountryList(value);
    }
  };

  const onHandlerChangeSalePerYear = (event: any, label: string) => {
    label === "2020" &&
      setSalesPerYear((preValue) => ({
        ...preValue,
        2020: event.target.value,
      }));
    label === "2021" &&
      setSalesPerYear((preValue) => ({
        ...preValue,
        2021: event.target.value,
      }));
    label === "2022" &&
      setSalesPerYear((preValue) => ({
        ...preValue,
        2022: event.target.value,
      }));
    label === "2023" &&
      setSalesPerYear((preValue) => ({
        ...preValue,
        2023: event.target.value,
      }));

    const isErrorSalesPerYear =
      salesPerYear[2020] === "" ||
      salesPerYear[2021] === "" ||
      salesPerYear[2022] === "" ||
      salesPerYear[2023] === "";
    setIsErrorSalesPerYear(isErrorSalesPerYear);
  };

  const onHandlerChangeMultiSelectTab2 = (value: any, option: any) => {
    if (
      `${option.moduleName}_${option.index}` === "mercado_europeo_5" ||
      `${option.moduleName}_${option.index}` === "mercado_europeo_3"
    ) {
      setCountryNextGoalSelect(value);
      setIsErrorEstablishedCountry(false);
    }
    if (`${option.moduleName}_${option.index}` === "mercado_europeo_7") {
      setMarketingChannelSelect(value);
      setIsErrorMarketingChannels(false);
    }
  };

  const onHandlerChangeExportFrequency = (event: any, option: any) => {
    if (option.index === 2) {
      setExportFrequencyList(event.target.value);
      setIsErrorExportFrequency(false);
    }

    if (option.index === 8) {
      setHumanCapitalList(event.target.value);
      setIsErrorHumanCapital(false);
    }
  };

  const onChangeMultiSelectTab3 = (value: any, option: any) => {
    if (`${option.moduleName}_${option.index}` === "exportaciones_9") {
      value.length !== 0 && setIsErrorCompanyResourcesEmpty(false);
      setCompanyResources(value);
    }

    if (`${option.moduleName}_${option.index}` === "exportaciones_11") {
      value.length !== 0 && setIsErrorCompanyCertification(false);
      setCompanyCertificationList(value);
    }
  };

  const onChangeSelectTab3 = (event: any, option: any) => {
    if (`${option.moduleName}_${option.index}` === "mercado_europeo_5") {
      event.target.value.length !== 0 && setIsErrorBusinessPlanEmpty(false);
      setIsErrorCheckBusinessPlanEmpty(false);
      setBusinessPlan(event.target.value);
    }
  };

  const onHandlerChangeBusinessReadyExportOrRRHH = (
    event: any,
    option: any
  ) => {
    if (`${option.moduleName}_${option.index}` === "mercado_europeo_1") {
      event.target.value.length !== 0 && setIsErrorBusinessReadyExport(false);
      setBusinessReadyExport(event.target.value);
    }

    if (`${option.moduleName}_${option.index}` === "exportaciones_13") {
      event.target.value.length !== 0 && setIsErrorHumanCapital(false);
      setHumanCapitalList(event.target.value);
    }
  };

  const onHandlerChangeInternationalTrade = (value: any, option: any) => {
    if (`${option.moduleName}_${option.index}` === "mercado_europeo_1") {
      value.length !== 0 && setIsErrorPromotionalActivities(false);
      setPromotionalActivitiesList(value);
    }

    if (`${option.moduleName}_${option.index}` === "comercio_internacional_3") {
      value.length !== 0 && setIsErrorFinancingSources(false);
      setFinancingSourcesList(value);
    }

    if (`${option.moduleName}_${option.index}` === "comercio_internacional_1") {
      value.length !== 0 && setIsErrorPromotionalActivities(false);
      setPromotionalActivitiesList(value);
    }
  };

  const formRow = (row: number, index: number) => {
    const newIndex = index === 0 ? 2 : index + 2;
    return (
      <div key={`${row}`} className="row exporter-test__row">
        <div className="col-12 col-sm-12 col-md-7">
          <input
            type="text"
            placeholder={`Producto o servicio ${newIndex}`}
            className="exporter-test_textbox-producto"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-3 d-flex align-items-center justify-content-end exporter-test_container-textbox-arancel">
          <input
            type="text"
            placeholder={`Código arancelario ${newIndex}`}
            className="exporter-test_textbox-arancel"
          />
        </div>
        <div className="col-1 exporter-test_textbox-arancel-trash">
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => onHandleDeleteRow(index)}
          />
        </div>
      </div>
    );
  };

  /**
   * @description Return a value by @type ControlType
   * @param value
   * @param questionData
   * @returns
   */
  const getValueScore = (
    value: string | Option[],
    questionData: QuestionData
  ) => {
    switch (questionData.controlType) {
      case CONTROL_TYPES.SWITCH:
        return (
          questionData.switchScore?.find((item) => item.label === value)
            ?.score ?? 0
        );

      case CONTROL_TYPES.COMBO_SELECT:
        return (
          questionData.dataSource?.find((item) => item.value === value)
            ?.score ?? 0
        );

      case CONTROL_TYPES.COMBO_MULTI_SELECT:
        return (value as Option[]).reduce(
          (accumulator: number, current: Option) =>
            accumulator + (current?.score ?? 0),
          0
        );

      default:
        return null;
    }
  };

  /**
   * @description Function handles all possible field responses, stores them, and captures the scores to later evaluate the type of Exporter.
   * @param value
   * @param questionData
   * @returns scores
   */
  const handleSetScores = (
    value: string | Option[] | any,
    questionData: QuestionData
  ) => {
    const valueScore = getValueScore(value, questionData);
    if (valueScore === null) {
      return;
    }

    setScores((prev) => {
      const moduleIndex = prev.findIndex(
        (module) => module.moduleName === questionData.moduleName
      );

      if (moduleIndex !== -1) {
        const updatedModules = [...prev];
        const module = updatedModules[moduleIndex];
        const questionIndex = module.questions.findIndex(
          (item: any) => item.question === questionData.question
        );

        if (questionIndex !== -1) {
          module.questions[questionIndex].score = valueScore;
          module.questions[questionIndex].value = value;
        } else {
          module.questions.push({
            question: questionData.question,
            value,
            score: valueScore,
          });
        }

        return updatedModules;
      } else {
        return [
          ...prev,
          {
            moduleName: questionData.moduleName,
            questions: [
              { question: questionData.question, score: valueScore, value },
            ],
          },
        ];
      }
    });
  };

  const calculateTotalScore = () => {
    return scores.reduce((total, module) => {
      return (
        total +
        module.questions.reduce((moduleTotal, question) => {
          return moduleTotal + question.score;
        }, 0)
      );
    }, 0);
  };

  const evaluateExporterCategory = (): [TExporterCategory, number] => {
    const totalScore = calculateTotalScore();

    if (totalScore >= 0 && totalScore <= 32) {
      return ["emergent", 1];
    } else if (totalScore >= 33 && totalScore <= 65) {
      return ["consolidated", 2];
    } else if (totalScore >= 66 && totalScore <= 100) {
      return ["expert", 3];
    } else {
      return ["expert", 3];
    }
  };

  return (
    <div className="exporter-test__container">
      <div className="exporter-test__tabs">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-test-exporter ${
              activeTab === index ? "tab-active" : ""
            }`}
            onClick={() => handleTabClick(index)}
          >
            <div
              className={`circle ${activeTab === index ? "circle-active" : ""}`}
            >
              {index + 1}
            </div>
            <div
              className={`text-test-exporter ${
                activeTab === index ? "text-test-exporter-active" : ""
              }`}
            >
              {tab.title}
            </div>
          </div>
        ))}
      </div>
      <div className="tab-container exporter-test__tab-container">
        <div>{tabs[activeTab].contentTitle}</div>
        {tabs[activeTab].contentRows.map((row, rowIndex) => (
          <div key={rowIndex} className="row">
            {row.map((questionData, questionIndex) => (
              <div key={questionIndex} className="col-12">
                <div className="exporter-test__question">
                  {questionData.question}{" "}
                  {questionData?.tooltip && (
                    <CustomToolTip
                      tooltipText={questionData?.tooltipText}
                      title={questionData?.tooltipText}
                    >
                      <FontAwesomeIcon
                        icon={faCircleQuestion}
                        className="font-size: 15px"
                      />
                    </CustomToolTip>
                  )}
                </div>
                {questionData.controlType === "ComboMultiSelect" ? (
                  <div className="exporter-test__CustomMultiSelect">
                    <CustomMultiSelect
                      data={questionData.dataSource || []}
                      placeholder={questionData.placeholder_1}
                      width="100%"
                      onChange={(value) => {
                        onHandleChangeCategory(value, questionData);
                        handleSetScores(value, questionData);
                      }}
                    />
                    {((`${questionData.moduleName}_${questionData.index}` ===
                      "empresa_0" &&
                      isErrorCategoryEmpty) ||
                      (`${questionData.moduleName}_${questionData.index}` ===
                        "comercio_internacional_4" &&
                        isErrorMainObstacles)) && (
                      <div className="container errorContainer">
                        <img src={circleError} alt="error-icon" />
                        <p className="errorInputValidation">
                          {t("exporterTest.required")}
                        </p>
                      </div>
                    )}
                  </div>
                ) : questionData.controlType === "Switch" &&
                  (questionData.hasChild || questionData.innerchild) ? (
                  <div className="exporter-test__notificacion">
                    <>
                      {questionData.hasChild &&
                        questionData.moduleName === "mercado_europeo" && (
                          <CustomizedSwitch
                            onChange={([isCheckedMercadoEuropeo, value]) => {
                              setValueExportEurope(isCheckedMercadoEuropeo);
                              handleSwitchChange_mercadoEuropeo(
                                isCheckedMercadoEuropeo || false
                              );
                              handleSetScores(value, questionData);
                            }}
                            checked={moduleStates.mercado_europeo}
                            isThreeOptions={questionData.threeOptions}
                          />
                        )}

                      {isErrorExportEurope && (
                        <div className="container errorContainer">
                          <img src={circleError} alt="error-icon" />
                          <p className="errorInputValidation">
                            {t("exporterTest.step_2.selectOption")}
                          </p>
                        </div>
                      )}
                      {questionData.hasChild &&
                        questionData.moduleName === "exportaciones" && (
                          <CustomizedSwitch
                            onChange={([isCheckedExportaciones, value]) => {
                              setValueExports(isCheckedExportaciones);
                              handleSwitchChange_exportaciones(
                                isCheckedExportaciones || false
                              );
                              handleSetScores(value, questionData);
                            }}
                            checked={moduleStates.exportaciones}
                            isThreeOptions={questionData.threeOptions}
                          />
                        )}
                      {isErrorExports && (
                        <div className="container errorContainer">
                          <img src={circleError} alt="error-icon" />
                          <p className="errorInputValidation">
                            {t("exporterTest.step_2.selectOption")}
                          </p>
                        </div>
                      )}
                      {questionData.hasChild &&
                        questionData.moduleName ===
                          "comercio_internacional" && (
                          <>
                            <CustomizedSwitch
                              onChange={([checked, value]) => {
                                handleSwitchChange(
                                  `${questionData.moduleName}_${questionData.index}` as keyof ModuleStates,
                                  checked
                                );
                                handleSetScores(value, questionData);
                              }}
                              checked={
                                moduleStates[
                                  `${questionData.moduleName}_${questionData.index}` as keyof ModuleStates
                                ] ?? false
                              }
                              isThreeOptions={true}
                            />
                            {((`${questionData.moduleName}_${questionData.index}` ===
                              "comercio_internacional_0" &&
                              isErrorPromotionalActivities) ||
                              (`${questionData.moduleName}_${questionData.index}` ===
                                "comercio_internacional_2" &&
                                isErrorFinancingSources)) && (
                              <div className="container errorContainer">
                                <img src={circleError} alt="error-icon" />
                                <p className="errorInputValidation">
                                  {t("exporterTest.step_2.selectOption")}
                                </p>
                              </div>
                            )}
                            {moduleStates[
                              `${questionData.moduleName}_${questionData.index}` as keyof ModuleStates
                            ] && (
                              <div>
                                {questionData.AdditionalOptions_true?.map(
                                  (subOptions, outerIndex) => (
                                    <div key={outerIndex}>
                                      {subOptions.map(
                                        (subOption, innerIndex) => (
                                          <div key={innerIndex}>
                                            {subOption.controlType ===
                                              "ComboSelect" && (
                                              <div className="col-12">
                                                <select
                                                  key={subOption.index}
                                                  className={subOption.class}
                                                  onChange={(value) => {
                                                    handleSetScores(
                                                      value,
                                                      subOption
                                                    );
                                                  }}
                                                >
                                                  <option
                                                    value=""
                                                    disabled
                                                    selected
                                                  >
                                                    {subOption.placeholder_1}{" "}
                                                    {questionData?.tooltip && (
                                                      <CustomToolTip
                                                        tooltipText={""}
                                                        title={
                                                          questionData?.tooltipText
                                                        }
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={
                                                            faCircleQuestion
                                                          }
                                                          className="font-size: 15px"
                                                        />
                                                      </CustomToolTip>
                                                    )}
                                                  </option>
                                                  {subOption.dataSource?.map(
                                                    (item, index) => (
                                                      <option
                                                        key={index}
                                                        value={item.value}
                                                      >
                                                        {item.label}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            )}
                                            {subOption.controlType ===
                                              "ComboMultiSelect" && (
                                              <div>
                                                <CustomMultiSelect
                                                  data={
                                                    subOption.dataSource || []
                                                  }
                                                  placeholder={
                                                    subOption.placeholder_1
                                                  }
                                                  width="100%"
                                                  onChange={(value) => {
                                                    onHandlerChangeInternationalTrade(
                                                      value,
                                                      subOption
                                                    );
                                                    handleSetScores(
                                                      value,
                                                      subOption
                                                    );
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </>
                        )}
                    </>
                    {questionData.AdditionalOptions_true &&
                      (questionData.moduleName === "empresa" ||
                        (questionData.moduleName === "mercado_europeo" &&
                          moduleStates.mercado_europeo &&
                          !initialLoad_mercadoEuropeo) ||
                        (questionData.moduleName === "exportaciones" &&
                          moduleStates.exportaciones &&
                          !initialLoad_exportaciones) ||
                        (questionData.moduleName === "comercio_internacional" &&
                          moduleStates.comercio_internacional)) && (
                        <div className="contain">
                          {questionData.AdditionalOptions_true?.map(
                            (options, outerIndex) => (
                              <div key={outerIndex}>
                                {options.map((option, innerIndex) =>
                                  option.controlType === "ComboSelect" ? (
                                    <>
                                      <div className="exporter-test__question">
                                        {option.question}{" "}
                                        {option?.tooltip && (
                                          <CustomToolTip
                                            tooltipText={option?.tooltipText}
                                            title={option?.tooltipText}
                                          >
                                            <FontAwesomeIcon
                                              icon={faCircleQuestion}
                                              className="font-size: 15px"
                                            />
                                          </CustomToolTip>
                                        )}
                                      </div>
                                      {option.lateralLabel ? (
                                        option.lateralLabel
                                          .split(",")
                                          .map((label, index) => (
                                            <div
                                              key={index}
                                              className="row exporter-test-information__row"
                                            >
                                              <div className="col-2 col-sm-1 d-flex align-items-center">
                                                <div className="exporter-test__label">
                                                  {label}
                                                </div>
                                              </div>
                                              <div className="col-10 col-sm-9">
                                                <select
                                                  key={option.index}
                                                  className={option.class}
                                                  onChange={(value) => {
                                                    onHandlerChangeSalePerYear(
                                                      value,
                                                      label
                                                    );
                                                    handleSetScores(
                                                      value,
                                                      option
                                                    );
                                                  }}
                                                >
                                                  <option
                                                    value=""
                                                    disabled
                                                    selected
                                                  >
                                                    {option.placeholder_1}
                                                  </option>
                                                  {option.dataSource?.map(
                                                    (item, index) => (
                                                      <option
                                                        key={index}
                                                        value={item.value}
                                                      >
                                                        {item.label}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                          ))
                                      ) : (
                                        <div className="col-12">
                                          <select
                                            key={option.index}
                                            className={option.class}
                                            onChange={(event) => {
                                              onHandlerChangeExportFrequency(
                                                event,
                                                option
                                              );
                                              handleSetScores(
                                                event.target.value,
                                                option
                                              );
                                            }}
                                          >
                                            <option value="" disabled selected>
                                              {option.placeholder_1}
                                            </option>
                                            {option.dataSource?.map(
                                              (item, index) => (
                                                <option
                                                  key={index}
                                                  value={item.value}
                                                >
                                                  {item.label}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      )}
                                      {((`${option.moduleName}_${option.index}` ===
                                        "mercado_europeo_3" &&
                                        isErrorSalesPerYear) ||
                                        (`${option.moduleName}_${option.index}` ===
                                          "exportaciones_2" &&
                                          isErrorExportFrequency) ||
                                        (`${option.moduleName}_${option.index}` ===
                                          "exportaciones_8" &&
                                          isErrorHumanCapital)) && (
                                        <div className="container errorContainer">
                                          <img
                                            src={circleError}
                                            alt="error-icon"
                                          />
                                          <p className="errorInputValidation">
                                            {t("exporterTest.step_2.required")}
                                          </p>
                                        </div>
                                      )}
                                    </>
                                  ) : option.controlType === "Switch" ? (
                                    <div className="exporter-test__switch">
                                      <React.Fragment key={innerIndex}>
                                        <div className="exporter-test__question">
                                          {option.question}{" "}
                                          {option?.tooltip && (
                                            <CustomToolTip
                                              tooltipText={option?.tooltipText}
                                              title={option?.tooltipText}
                                            >
                                              <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 15px"
                                              />
                                            </CustomToolTip>
                                          )}
                                        </div>
                                        <CustomizedSwitch
                                          onChange={([checked, value]) => {
                                            handleSwitchChange(
                                              `${option.moduleName}_${option.index}` as keyof ModuleStates,
                                              checked
                                            );
                                            handleSetScores(value, option);
                                          }}
                                          checked={
                                            moduleStates[
                                              `${option.moduleName}_${option.index}` as keyof ModuleStates
                                            ] ?? false
                                          }
                                          isThreeOptions={true}
                                        />
                                        {((`${option.moduleName}_${option.index}` ===
                                          "mercado_europeo_4" &&
                                          isErrorEstablishedCountry) ||
                                          (`${option.moduleName}_${option.index}` ===
                                            "mercado_europeo_6" &&
                                            isErrorMarketingChannels) ||
                                          (`${option.moduleName}_${option.index}` ===
                                            "mercado_europeo_9" &&
                                            isErrorNecessaryRegulation) ||
                                          (`${option.moduleName}_${option.index}` ===
                                            "exportaciones_7" &&
                                            isErrorCertificationEnough) ||
                                          (`${option.moduleName}_${option.index}` ===
                                            "exportaciones_4" &&
                                            isErrorCheckBusinessPlanEmpty)) && (
                                          <div className="container errorContainer">
                                            <img
                                              src={circleError}
                                              alt="error-icon"
                                            />
                                            <p className="errorInputValidation">
                                              {t(
                                                "exporterTest.step_2.selectOption"
                                              )}
                                            </p>
                                          </div>
                                        )}

                                        {moduleStates[
                                          `${option.moduleName}_${option.index}` as keyof ModuleStates
                                        ] ? (
                                          <div>
                                            {option.AdditionalOptions_true?.map(
                                              (subOptions, outerIndex) => (
                                                <div key={outerIndex}>
                                                  {subOptions.map(
                                                    (subOption, innerIndex) => (
                                                      <div key={innerIndex}>
                                                        {subOption.controlType ===
                                                          "ComboSelect" && (
                                                          <div className="col-12">
                                                            <select
                                                              key={
                                                                subOption.index
                                                              }
                                                              className={
                                                                subOption.class
                                                              }
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                onChangeSelectTab3(
                                                                  event,
                                                                  subOption
                                                                );
                                                                handleSetScores(
                                                                  event.target
                                                                    .value,
                                                                  subOption
                                                                );
                                                              }}
                                                            >
                                                              <option
                                                                value=""
                                                                disabled
                                                                selected
                                                              >
                                                                {
                                                                  subOption.placeholder_1
                                                                }
                                                              </option>
                                                              {subOption.dataSource?.map(
                                                                (
                                                                  item,
                                                                  index
                                                                ) => (
                                                                  <option
                                                                    key={index}
                                                                    value={
                                                                      item.value
                                                                    }
                                                                  >
                                                                    {item.label}
                                                                  </option>
                                                                )
                                                              )}
                                                            </select>
                                                          </div>
                                                        )}
                                                        {subOption.controlType ===
                                                          "ComboMultiSelect" && (
                                                          <div className="col-12">
                                                            <CustomMultiSelect
                                                              data={
                                                                subOption.dataSource ||
                                                                []
                                                              }
                                                              placeholder={
                                                                subOption.placeholder_1
                                                              }
                                                              width="100%"
                                                              onChange={(
                                                                value
                                                              ) => {
                                                                onHandlerChangeMultiSelectTab2(
                                                                  value,
                                                                  subOption
                                                                );
                                                                handleSetScores(
                                                                  value,
                                                                  subOption
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        )}
                                                        {subOption.controlType ===
                                                          "Switch" && (
                                                          <div>
                                                            <span></span>
                                                          </div>
                                                        )}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        ) : null}
                                      </React.Fragment>
                                    </div>
                                  ) : option.controlType ===
                                    "ComboMultiSelect" ? (
                                    <div className="col-12">
                                      <div className="exporter-test__question">
                                        {option.question}{" "}
                                        {option?.tooltip && (
                                          <CustomToolTip
                                            tooltipText={option?.tooltipText}
                                            title={option?.tooltipText}
                                            
                                          >
                                            <FontAwesomeIcon
                                              icon={faCircleQuestion}
                                              className="font-size: 15px"
                                            />
                                          </CustomToolTip>
                                        )}
                                      </div>
                                      <CustomMultiSelect
                                        data={option.dataSource || []}
                                        placeholder={option.placeholder_1}
                                        width="100%"
                                        onChange={(value) => {
                                          onHandleChangeComboMultiSelect(
                                            value,
                                            option
                                          );
                                          handleSetScores(value, option);
                                        }}
                                      />
                                      {((`${option.moduleName}_${option.index}` ===
                                        "exportaciones_1" &&
                                        isErrorExportCountryEmpty) ||
                                        (`${option.moduleName}_${option.index}` ===
                                          "exportaciones_3" &&
                                          isErrorCompanyElement) ||
                                        (`${option.moduleName}_${option.index}` ===
                                          "exportaciones_6" &&
                                          isErrorCompanyCertification) ||
                                        (`${option.moduleName}_${option.index}` ===
                                          "mercado_europeo_2" &&
                                          isErrorExportCountryEmpty)) && (
                                        <div className="container errorContainer">
                                          <img
                                            src={circleError}
                                            alt="error-icon"
                                          />
                                          <p className="errorInputValidation">
                                            {t("exporterTest.required")}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  ) : null
                                )}
                              </div>
                            )
                          )}
                        </div>
                      )}
                    {questionData.AdditionalOptions_false &&
                      ((questionData.moduleName === "mercado_europeo" &&
                        !moduleStates.mercado_europeo &&
                        !initialLoad_mercadoEuropeo) ||
                        (questionData.moduleName === "exportaciones" &&
                          !moduleStates.exportaciones &&
                          !initialLoad_exportaciones) ||
                        (questionData.moduleName === "comercio_internacional" &&
                          !moduleStates.comercio_internacional)) && (
                        <div className="contain">
                          {questionData.AdditionalOptions_false?.map(
                            (options, outerIndex) => (
                              <div key={outerIndex}>
                                {options.map((option, innerIndex) =>
                                  option.controlType === "ComboSelect" ? (
                                    <div>
                                      {option.lateralLabel ? (
                                        option.lateralLabel
                                          .split(",")
                                          .map((label, index) => (
                                            <div
                                              key={index}
                                              className="row exporter-test-information__row"
                                            >
                                              <div className="col-1 d-flex align-items-center">
                                                <div className="exporter-test__label">
                                                  {label}{" "}
                                                  {option?.tooltip && (
                                                    <CustomToolTip
                                                      tooltipText={
                                                        option?.tooltipText
                                                      }
                                                      title={
                                                        option?.tooltipText
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faCircleQuestion}
                                                        className="font-size: 15px"
                                                      />
                                                    </CustomToolTip>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="col-12">
                                                <select
                                                  key={option.index}
                                                  className={option.class}
                                                >
                                                  <option
                                                    value=""
                                                    disabled
                                                    selected
                                                  >
                                                    {option.placeholder_1}
                                                  </option>
                                                  {option.dataSource?.map(
                                                    (item, index) => (
                                                      <option
                                                        key={index}
                                                        value={item.value}
                                                      >
                                                        HERE 33333 {item.label}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                          ))
                                      ) : (
                                        <div>
                                          <div className="exporter-test__question">
                                            {option.question}{" "}
                                            {option?.tooltip && (
                                              <CustomToolTip
                                                tooltipText={
                                                  option?.tooltipText
                                                }
                                                title={option?.tooltipText}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faCircleQuestion}
                                                  className="font-size: 15px"
                                                />
                                              </CustomToolTip>
                                            )}
                                          </div>
                                          <select
                                            key={option.index}
                                            className={option.class}
                                            onChange={(event) => {
                                              onHandlerChangeBusinessReadyExportOrRRHH(
                                                event,
                                                option
                                              );
                                              handleSetScores(
                                                event.target.value,
                                                option
                                              );
                                            }}
                                          >
                                            <option value="" disabled selected>
                                              {option.placeholder_1}
                                            </option>
                                            {option.dataSource?.map(
                                              (item, index) => (
                                                <option
                                                  key={index}
                                                  value={item.value}
                                                >
                                                  {item.label}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          {((`${option.moduleName}_${option.index}` ===
                                            "mercado_europeo_1" &&
                                            isErrorBusinessReadyExport) ||
                                            (`${option.moduleName}_${option.index}` ===
                                              "exportaciones_13" &&
                                              isErrorHumanCapital)) && (
                                            <div className="container errorContainer">
                                              <img
                                                src={circleError}
                                                alt="error-icon"
                                              />
                                              <p className="errorInputValidation">
                                                {t("exporterTest.required")}
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ) : option.controlType === "Switch" ? (
                                    <div>
                                      <React.Fragment key={innerIndex}>
                                        <div className="exporter-test__question">
                                          {option.question}{" "}
                                          {option?.tooltip && (
                                            <CustomToolTip
                                              tooltipText={option?.tooltipText}
                                              title={option?.tooltipText}
                                            >
                                              <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 15px"
                                              />
                                            </CustomToolTip>
                                          )}
                                        </div>
                                        <CustomizedSwitch
                                          onChange={([checked, value]) => {
                                            handleSwitchChange(
                                              `${option.moduleName}_${option.index}` as keyof ModuleStates,
                                              checked
                                            );
                                            handleSetScores(value, option);
                                          }}
                                          checked={
                                            moduleStates[
                                              `${option.moduleName}_${option.index}` as keyof ModuleStates
                                            ] ?? false
                                          }
                                          isThreeOptions={true}
                                        />
                                        {((`${option.moduleName}_${option.index}` ===
                                          "mercado_europeo_2" &&
                                          isErrorEstablishedCountry) ||
                                          (`${option.moduleName}_${option.index}` ===
                                            "mercado_europeo_4" &&
                                            isErrorMarketingChannels) ||
                                          (`${option.moduleName}_${option.index}` ===
                                            "mercado_europeo_5" &&
                                            isErrorNecessaryRegulation) ||
                                          (`${option.moduleName}_${option.index}` ===
                                            "exportaciones_10" &&
                                            isErrorCheckBusinessPlanEmpty) ||
                                          (`${option.moduleName}_${option.index}` ===
                                            "exportaciones_12" &&
                                            isErrorCertificationEnough)) && (
                                          <div className="container errorContainer">
                                            <img
                                              src={circleError}
                                              alt="error-icon"
                                            />
                                            <p className="errorInputValidation">
                                              {t(
                                                "exporterTest.step_2.selectOption"
                                              )}
                                            </p>
                                          </div>
                                        )}
                                        {moduleStates[
                                          `${option.moduleName}_${option.index}` as keyof ModuleStates
                                        ] ? (
                                          <div >
                                            {option.AdditionalOptions_true?.map(
                                              (subOptions, outerIndex) => (
                                                <div key={outerIndex}>
                                                  {subOptions.map(
                                                    (subOption, innerIndex) => (
                                                      <div key={innerIndex}>
                                                        {subOption.controlType ===
                                                          "ComboSelect" && (
                                                          <div className="col-12">
                                                            <select
                                                              key={
                                                                subOption.index
                                                              }
                                                              className={
                                                                subOption.class
                                                              }
                                                              onChange={(
                                                                value
                                                              ) => {
                                                                onChangeSelectTab3(
                                                                  value,
                                                                  subOption
                                                                );
                                                                handleSetScores(
                                                                  value,
                                                                  subOption
                                                                );
                                                              }}
                                                            >
                                                              <option
                                                                value=""
                                                                disabled
                                                                selected
                                                              >
                                                                {
                                                                  subOption.placeholder_1
                                                                }
                                                              </option>
                                                              {subOption.dataSource?.map(
                                                                (
                                                                  item,
                                                                  index
                                                                ) => (
                                                                  <option
                                                                    key={index}
                                                                    value={
                                                                      item.value
                                                                    }
                                                                  >
                                                                    {item.label}
                                                                  </option>
                                                                )
                                                              )}
                                                            </select>
                                                          </div>
                                                        )}
                                                        {subOption.controlType ===
                                                          "ComboMultiSelect" && (
                                                          <div className="col-12">
                                                            <CustomMultiSelect
                                                              data={
                                                                subOption.dataSource ||
                                                                []
                                                              }
                                                              placeholder={
                                                                subOption.placeholder_1
                                                              }
                                                              width="100%"
                                                              onChange={(
                                                                value
                                                              ) => {
                                                                onHandlerChangeMultiSelectTab2(
                                                                  value,
                                                                  subOption
                                                                );
                                                                handleSetScores(
                                                                  value,
                                                                  subOption
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        )}
                                                        {subOption.controlType ===
                                                          "Switch" && (
                                                          <div>
                                                            <span></span>
                                                          </div>
                                                        )}
                                                        {`${subOption.moduleName}_${subOption.index}` ===
                                                          "mercado_europeo_5" &&
                                                          isErrorBusinessPlanEmpty && (
                                                            <div className="container errorContainer">
                                                              <img
                                                                src={
                                                                  circleError
                                                                }
                                                                alt="error-icon"
                                                              />
                                                              <p className="errorInputValidation">
                                                                {t(
                                                                  "exporterTest.step_2.selectOption"
                                                                )}
                                                              </p>
                                                            </div>
                                                          )}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        ) : null}
                                      </React.Fragment>
                                    </div>
                                  ) : option.controlType ===
                                    "ComboMultiSelect" ? (
                                    <div>
                                      <div className="exporter-test__question">
                                        {option.question}{" "}
                                        {option?.tooltip && (
                                          <CustomToolTip
                                            tooltipText={option?.tooltipText}
                                            title={option?.tooltipText}
                                          >
                                            <FontAwesomeIcon
                                              icon={faCircleQuestion}
                                              className="font-size: 15px"
                                            />
                                          </CustomToolTip>
                                        )}
                                      </div>
                                      <CustomMultiSelect
                                        data={option.dataSource || []}
                                        placeholder={option.placeholder_1}
                                        width="100%"
                                        onChange={(value) => {
                                          onChangeMultiSelectTab3(
                                            value,
                                            option
                                          );
                                          handleSetScores(value, option);
                                        }}
                                      />
                                      {((`${option.moduleName}_${option.index}` ===
                                        "exportaciones_9" &&
                                        isErrorCompanyResourcesEmpty) ||
                                        (`${option.moduleName}_${option.index}` ===
                                          "exportaciones_11" &&
                                          isErrorCompanyCertification)) && (
                                        <div className="container errorContainer">
                                          <img
                                            src={circleError}
                                            alt="error-icon"
                                          />
                                          <p className="errorInputValidation">
                                            {t(
                                              "exporterTest.step_2.selectOption"
                                            )}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  ) : null
                                )}
                              </div>
                            )
                          )}
                        </div>
                      )}
                    {((questionData.moduleName === "mercado_europeo" &&
                      initialLoad_mercadoEuropeo) ||
                      (questionData.moduleName === "exportaciones" &&
                        initialLoad_exportaciones)) && (
                      <div className="exporter-test-information__container-footer">
                        <div className="exporter-test-information__svg-icon-text-footern">
                          <img
                            src={AvatarMenIco}
                            alt=""
                            style={imgFooterStyles}
                          />
                        </div>
                        <span className="exporter-test-information__text-footer">
                          {t("exporterTest.footer")}
                        </span>
                      </div>
                    )}
                  </div>
                ) : questionData.controlType === "Switch" ? (
                  <div>
                    <React.Fragment key={questionIndex}>
                      <CustomizedSwitch
                        onChange={([checked, value]) => {
                          handleSwitchChange(
                            `${questionData.moduleName}_${questionData.index}` as keyof ModuleStates,
                            checked
                          );
                          handleSetScores(value, questionData);
                        }}
                        checked={
                          moduleStates[
                            `${questionData.moduleName}_${questionData.index}` as keyof ModuleStates
                          ] ?? false
                        }
                        isThreeOptions={true}
                      />
                    </React.Fragment>
                  </div>
                ) : questionData.controlType === "ComboSelect" ? (
                  <div>
                    <div className="col-12">
                      <select
                        key={questionData.index}
                        className={questionData.class}
                      >
                        <option value="" disabled selected>
                          {questionData.placeholder_1}
                        </option>
                        {questionData.dataSource?.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="row exporter-test__row">
                    <div className="col-12 col-sm-12 col-md-7">
                      <input
                        type="text"
                        placeholder={questionData.placeholder_1}
                        value={product}
                        className="exporter-test_textbox-producto"
                        onChange={onHandleChangeProduct}
                      />
                    </div>
                    <div className="col-11 col-sm-11 col-md-3 d-flex align-items-center justify-content-end exporter-test_container-textbox-arancel">
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          placeholder={questionData.placeholder_2}
                          className="exporter-test_textbox-arancel"
                        />
                      </div>
                    </div>
                    <div className="col-1 exporter-test__question">
                      <CustomToolTip
                        title=""
                        tooltipText={t("exporterTest.tooltip")}
                      >
                        <FontAwesomeIcon icon={faCircleQuestion} />
                      </CustomToolTip>
                    </div>
                    {isErrorProductEmpty && (
                      <div className="col-12 mt-1 container">
                        <img src={circleError} alt="error-icon" />
                        <p className="errorInputValidation">
                          {t("exporterTest.required")}
                        </p>
                      </div>
                    )}
                    <div className="col-12">
                      {listProducts()}
                      <span
                        className="exporter-test-add-row"
                        onClick={onHandleAddRow}
                      >
                        {t("exporterTest.step_1.add")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="exporter-test__container-footer">
        <div className="exporter-test__next" onClick={handleContinueClick}>
          <div className="btn-test-next-text">{t("exporterTest.button")}</div>
          <FontAwesomeIcon icon={faArrowRight} color="black" />
        </div>
      </div>
    </div>
  );
};

export default ExporterTest;
