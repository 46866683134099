import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetApiNews } from "../../api/news.api";
import { ReactComponent as Bookmark } from "../../assets/images/bookmark-regular.svg";
import { ReactComponent as LinkSolid } from "../../assets/images/link-solid.svg";
import { FriendlyDate } from "../../components/FriendlyDate/FriendlyDate";
import CopyLinkButton from "../../shared/CopyLinkButton/CopyLinkButton";
import "./noticias.css";




const NewLayout = ({ children }: { children: React.ReactNode }) => {
  return (
   
    <div className="singlenews" >
      {children}
    </div>
  
  )
}

const NoticiaPage: React.FC = () => {
  const { id } = useParams()
  const { data: dataNews, isLoading, mutate: newsList } = useGetApiNews()
  const newsLink = window.location.href;
  const [bodyContent, setBodyContent] = useState('');
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

 

  

  useEffect(() => {
    if (id) {
      newsList({ data: { id: id } })
    }
  }, [id]);

  useEffect(() => {
	if (dataNews && dataNews.length > 0) {
		const htmlString = dataNews[0]?.body ?? '';

		if (htmlString) {
			const parser = new DOMParser();
			const doc = parser.parseFromString(htmlString, 'text/html');

			let contentDiv = doc.querySelector(
				".coh-wysiwyg.ssa-component.coh-component.ssa-component-instance-b8139067-5702-407e-bd58-f773350cec56.coh-component-instance-b8139067-5702-407e-bd58-f773350cec56"
			  );
		
			  // Si no lo encuentra, intenta con el div con clase "ecl"
			  if (!contentDiv) {
				contentDiv = doc.querySelector(".ecl");
			  }

        if (!contentDiv) {
				contentDiv = doc.querySelector(".ecl-page-header__description-container .ecl-page-header__description");
			  }
        
        if (!contentDiv) {
				  contentDiv = doc.querySelector(".post-template-default article .content");
			  }

        if (!contentDiv) {
				  contentDiv = doc.querySelector(".wrapper .content");
			  }
        
        if (!contentDiv) {
				  contentDiv = doc.querySelector("#main-content article");
			  }
        
        if (!contentDiv) {
				  contentDiv = doc.querySelector(".cmp-text");
			  }
        
        if (!contentDiv) {
				  contentDiv = doc.querySelector(".fusion-column-wrapper");
			  }
        
        if (!contentDiv) {
				  contentDiv = doc.querySelectorAll(".coh-wysiwyg.ssa-component.coh-component ")[1];
			  }
        if (!contentDiv) {
				  contentDiv = doc.querySelectorAll(".coh-wysiwyg.ssa-component.coh-component ")[0];
			  }
        
        if (!contentDiv) {
				  contentDiv = doc.querySelector(".story-column ");
			  }
        if (!contentDiv) {
				  contentDiv = doc.querySelector(".video__transcript__text ");
			  }
        if (!contentDiv) {
				  contentDiv = doc.querySelector(".c-article-content.js-article-content.poool-content ");
			  }

        

			// Si encuentra alguno, extrae su contenido
			setBodyContent(contentDiv ? contentDiv.innerHTML : dataNews[0]?.summary); 
		}
	}
}, [dataNews]);

  return (
    <>
      <NewLayout>
        {dataNews?.length &&
          <>
          
            
            <div className="singlenews__top" >
              <span className="singlenews__top__left">
                <span className="singlenews__top__left__title">published </span>  
                <FriendlyDate date={dataNews[0].date}  />
              </span>
              <div className="singlenews__top__right ">
                <span className="singlenews__top__link">
                <CopyLinkButton link={dataNews[0].link} >
                  <LinkSolid className="singlenews__top__share " />
                </CopyLinkButton>
                </span>
                <a href="#" className="singlenews__top__link">
                  <Bookmark className="singlenews__top__save " />
                </a>
              </div>
            </div>

            <h2 className="singlenews__title" >
              {dataNews[0].title}
            </h2>

            <div className="singlenews__main " >
              <figure className="singlenews__figure">
              <img
                className="singlenews__img"
                src={dataNews[0].image}
                alt={dataNews[0].title}
              />
              </figure>
            </div>
            <p className="singlenews__body">
              {/* {dataNews[0].summary} */}
			        <div dangerouslySetInnerHTML={{ __html: bodyContent }} />
            </p>

            <div className="singlenews__actions">
              <p className="singlenews__actions__text">
                {t("news.source")} 
                <a href={dataNews[0].link} target="_blank" rel="noopener noreferrer">
                  {dataNews[0].source}
                </a>
                
              </p>
            

              <div className="singlenews__actions__right " >
                <div className="singlenews__actions__copy">
                  <CopyLinkButton className="singlenews__actions__btncopy" text={true} link={dataNews[0].link}> 
                    <LinkSolid className="clipboard__svg" /> {t("news.copyNewsLink")}
                  </CopyLinkButton>
                </div>
                <span className="singlenews__actions__save">
                  <Bookmark className="singlenews__actions__save__svg " />
                  {t("news.saveNews")}
                </span>
              </div>
            </div>

            <div className="singlenews__footer">
              
                {dataNews?.[0]?.preferences?.map((item, index) => (
                  <span key={index} className="singlenews__footer__text" >
                      {item}
                  </span>
              ))}

            </div>


          </>
        }
      </NewLayout>
    </>
  );
};

export default NoticiaPage;
