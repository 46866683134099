import * as React from "react";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import "./CustomToolTip.css";

interface StyledTooltipProps extends TooltipProps {
  tooltipText: string | React.ReactNode;
}

const CustomToolTip: React.FC<StyledTooltipProps> = ({ tooltipText, children, ...props }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // Evita que el click se propague
    setOpen(!open);
  };

  return (
    <Tooltip
      {...props}
      title={tooltipText}
      open={open}
      onClose={() => setOpen(false)}
      disableHoverListener
      disableFocusListener
      disableTouchListener
      classes={{ tooltip: "custom-tooltip" }}
    >
      <span onClick={handleClick} style={{ cursor: "pointer" }}>{children}</span>
    </Tooltip>
  );
};

export default CustomToolTip;
