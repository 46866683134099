
import * as React from 'react';

type IconCkeckSolidProps = React.SVGProps<SVGSVGElement>;

export const IconCkeckSolid: React.FC<IconCkeckSolidProps> = (props) => (
	<svg width={18} height={20} viewBox="0 0 18 20" fill="#46514A" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clip-path="url(#clip0_1272_6403)">
		<path d="M17.1328 4.11719C17.6211 4.60547 17.6211 5.39844 17.1328 5.88672L7.13281 15.8867C6.64453 16.375 5.85156 16.375 5.36328 15.8867L0.363281 10.8867C-0.125 10.3984 -0.125 9.60547 0.363281 9.11719C0.851562 8.62891 1.64453 8.62891 2.13281 9.11719L6.25 13.2305L15.3672 4.11719C15.8555 3.62891 16.6484 3.62891 17.1367 4.11719H17.1328Z" />
		</g>
		<defs>
		<clipPath id="clip0_1272_6403">
		<rect width="17.5" height="20" fill="white"/>
		</clipPath>
		</defs>
	</svg>


);


