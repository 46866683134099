import React, { createContext, useContext, useEffect, useReducer } from "react";

// Define auth state
interface AuthState {
  isAuthenticated: boolean;
  token: string | null;
}

// Define actions
type AuthAction =
  | { type: "LOGIN"; payload: string }
  | { type: "LOGOUT" }
  | { type: "SET_AUTH"; payload: boolean };

// Initial state
const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem("token"),
  token: localStorage.getItem("token"),
};

// Reducer function
const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "LOGIN":
      return { isAuthenticated: true, token: action.payload };
    case "LOGOUT":
      return { isAuthenticated: false, token: null };
    case "SET_AUTH":
      return { ...state, isAuthenticated: action.payload };
    default:
      return state;
  }
};

// Create context
const AuthContext = createContext<
  { state: AuthState; dispatch: React.Dispatch<AuthAction> } | undefined
>(undefined);

// AuthProvider Component
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch({ type: "SET_AUTH", payload: true });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for using auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
