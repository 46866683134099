import * as React from 'react';

type IconSuscripcionesProps = React.SVGProps<SVGSVGElement>;

export const IconSuscripciones: React.FC<IconSuscripcionesProps> = (props) => (
	<svg width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="9.54242" y="1" width="8" height="14.2222" rx="4" stroke="#FFA692" stroke-width="1.77778"/>
		<path d="M2.31347 9.7622C2.37992 8.83188 3.15404 8.11108 4.08673 8.11108H22.9983C23.931 8.11108 24.7051 8.83188 24.7716 9.7622L26.2954 31.0955C26.3689 32.1246 25.5538 33 24.5221 33H2.56292C1.5312 33 0.71615 32.1246 0.789657 31.0955L2.31347 9.7622Z" fill="#EFF4F1"/>
		<path d="M13.3072 14.3334C12.4409 14.3334 11.7386 15.0357 11.7386 15.902V26.0981C11.7386 26.9644 12.4409 27.6667 13.3072 27.6667H29.7778C30.6441 27.6667 31.3464 26.9644 31.3464 26.0981V24.3974C31.3464 24.0072 31.0527 23.6875 30.6919 23.5386C29.6951 23.1271 28.9935 22.1455 28.9935 21C28.9935 19.8546 29.6951 18.873 30.6919 18.4615C31.0527 18.3126 31.3464 17.9929 31.3464 17.6026V15.902C31.3464 15.0357 30.6441 14.3334 29.7778 14.3334H13.3072Z" fill="#8BA293"/>
		<circle cx="25.0718" cy="16.6862" r="0.784314" fill="#EFF4F1"/>
		<circle cx="25.0718" cy="19.4313" r="0.784314" fill="#EFF4F1"/>
		<circle cx="25.0718" cy="22.1764" r="0.784314" fill="#EFF4F1"/>
		<circle cx="25.0718" cy="24.9215" r="0.784314" fill="#EFF4F1"/>
	</svg>

);


