import * as React from 'react';
type IconSuccessProps = React.SVGProps<SVGSVGElement>;

export const IconSuccess: React.FC<IconSuccessProps> = (props) => (
	<svg
		width={42}
		height={43}
		viewBox="0 0 42 43"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_949_20176)">
			<path
				d="M21 42.5C26.5695 42.5 31.911 40.2875 35.8492 36.3492C39.7875 32.411 42 27.0695 42 21.5C42 15.9305 39.7875 10.589 35.8492 6.65076C31.911 2.71249 26.5695 0.5 21 0.5C15.4305 0.5 10.089 2.71249 6.15076 6.65076C2.21249 10.589 0 15.9305 0 21.5C0 27.0695 2.21249 32.411 6.15076 36.3492C10.089 40.2875 15.4305 42.5 21 42.5ZM30.2695 17.6445L19.7695 28.1445C18.9984 28.9156 17.7516 28.9156 16.9887 28.1445L11.7387 22.8945C10.9676 22.1234 10.9676 20.8766 11.7387 20.1137C12.5098 19.3508 13.7566 19.3426 14.5195 20.1137L18.375 23.9691L27.4805 14.8555C28.2516 14.0844 29.4984 14.0844 30.2613 14.8555C31.0242 15.6266 31.0324 16.8734 30.2613 17.6363L30.2695 17.6445Z"
				fill="#ADCAB8"
			/>
		</g>
		<defs>
			<clipPath id="clip0_949_20176">
				<rect
					width="42"
					height="42"
					fill="white"
					transform="translate(0 0.5)"
				/>
			</clipPath>
		</defs>
	</svg>
);
export default IconSuccess;
