import { useState, useEffect, useCallback } from 'react';
import { postApiSubscription } from '../../api';

    export const usePacksUser = (userId: string, productParentId: number) => {
        const [packsUser, setPacksUser] = useState<any[]>([]);
        const [loadingUser, setLoadingUser] = useState<boolean>(true);
        const [error, setError] = useState<string | null>(null);

        const fetchPacks = useCallback ( async () => {
            if (productParentId === -1) return;
    
            setLoadingUser(true);
            try {
                const response = await postApiSubscription({ userId, productParentId });
                setPacksUser(response?.results ? [...response.results] : []);
                setError(null);
            } catch (err) {
                setError("No se pudieron obtener los packs.");
            } finally {
                setLoadingUser(false);
            }
        }, [userId, productParentId]);

        useEffect(() => {
            fetchPacks();
        }, [fetchPacks]);
    
        return { packsUser, loadingUser, error, refetch: fetchPacks };

        
};