import { useTranslation } from "react-i18next";
import { Option } from '../../core/MultiSelect/multi-select';

export const DataOption = () => {
  const { t } = useTranslation();

  // // CATEGORIAS
  // const Categorias = [
  //   { label: "Categorias", value: "categorias", score: 0 },
  //   { label: "Producción primaria", value: "produccion_primaria", parent: 'categorias', score: 0 },
  //   { label: "Industria", value: "industria", parent: 'categorias', score: 0 },
  //   { label: "Comercialización", value: "comercializacion", parent: 'categorias', score: 0 },
  //   { label: "Servicios", value: "servicios", parent: 'categorias', score: 0 },
  // ];
 
  // CATEGORIAS
  const Categorias = [
    { label: t("exporterTest.step_1.categoria.opcion_1"), value: "categorias", score: 0 },
    { label: t("exporterTest.step_1.categoria.opcion_2"), value: "produccion_primaria", parent: 'categorias', score: 0 },
    { label: t("exporterTest.step_1.categoria.opcion_3"), value: "industria", parent: 'categorias', score: 0 },
    { label: t("exporterTest.step_1.categoria.opcion_4"), value: "comercializacion", parent: 'categorias', score: 0 },
    { label: t("exporterTest.step_1.categoria.opcion_5"), value: "servicios", parent: 'categorias', score: 0 },
  ];
  t("exporterTest.step_1.rangoventas.opcion_1")

  // RANGO DE VENTAS
  const RangoVentas = [
    { label: t("exporterTest.step_2.RangoVentas.opcion_1"), value: "rango_a", score: 0 },
    { label: t("exporterTest.step_2.RangoVentas.opcion_2"), value: "rango_b", score: 0 },
    { label: t("exporterTest.step_2.RangoVentas.opcion_3"), value: "rango_c", score: 0 },
    { label: t("exporterTest.step_2.RangoVentas.opcion_4"), value: "rango_d", score: 0 },
    { label: t("exporterTest.step_2.RangoVentas.opcion_5"), value: "rango_e", score: 0 },
    { label: t("exporterTest.step_2.RangoVentas.opcion_6"), value: "rango_f", score: 0 },
    { label: t("exporterTest.step_2.RangoVentas.opcion_7"), value: "rango_g", score: 0 },
    { label: t("exporterTest.step_2.RangoVentas.opcion_8"), value: "rango_h", score: 0 },
    { label: t("exporterTest.step_2.RangoVentas.opcion_9"), value: "rango_i", score: 0 },
  ];

  // TIEMPO ESPERADO
  const TiempoEsperado = [
    { label: t("exporterTest.step_2.TiempoEsperado.opcion_1"), value: "menos_de_1_anio_", score: 0 },
    { label: t("exporterTest.step_2.TiempoEsperado.opcion_2"), value: "entre_1_y_2_anios", score: 0 },
    { label: t("exporterTest.step_2.TiempoEsperado.opcion_3"), value: "entre_2_y_3_anios", score: 0 },
    { label: t("exporterTest.step_2.TiempoEsperado.opcion_4"), value: "mas_de_3_anios", score: 0 },
  ];

  // BUSINESS PLAN
  const PlanNegocios = [
    {label:"Preliminar", value: "preliminar"},
    {label:"En desarrollo", value: "en_desarrollo"},
    {label:"Finalizado", value: "finalizado"},
  ];

  
  // CANALES
  const Canales = [
    { label: t("exporterTest.step_2.Canales.opcion_1"), value: "canales" },
    { label: t("exporterTest.step_2.Canales.opcion_2"), value: "distribuidores_mayoristas", parent: 'canales' },
    { label: t("exporterTest.step_2.Canales.opcion_3"), value: "mayorista", parent: 'canales' },
    { label: t("exporterTest.step_2.Canales.opcion_4"), value: "minorista", parent: 'canales' },
    { label: t("exporterTest.step_2.Canales.opcion_5"), value: "tiendas_de_especialidad", parent: 'canales' },
    { label: t("exporterTest.step_2.Canales.opcion_6"), value: "venta_on-line", parent: 'canales' },
    { label: t("exporterTest.step_2.Canales.opcion_7"), value: "marketplace", parent: 'canales' },
    { label: t("exporterTest.step_2.Canales.opcion_8"), value: "onlinestore", parent: 'canales' },
    { label: t("exporterTest.step_2.Canales.opcion_9"), value: "venta_directa", parent: 'canales' },
    { label: t("exporterTest.step_2.Canales.opcion_10"), value: "licitacion", parent: 'canales' },
    { label: t("exporterTest.step_2.Canales.opcion_11"), value: "pasajeros_internacionales", parent: 'canales' },
    { label: t("exporterTest.step_2.Canales.opcion_12"), value: "tour_operador", parent: 'canales'},
    { label: t("exporterTest.step_2.Canales.opcion_13"), value: "no_estan_identificados ", parent: 'canales' },
    { label: t("exporterTest.step_2.Canales.opcion_14"), value: "otros", parent: 'canales' },
  ];

  
  // EXPORTACIONES NO EU
  const ExporNoEU: Option[] = [
    { label: t("exporterTest.step_3.ExporNoEU.opcion_1"), value: 'comunidad_europea' },
    { label: t("exporterTest.step_3.ExporNoEU.opcion_2"), value: "sudamerica", parent:'comunidad_no_europea'},
    { label: t("exporterTest.step_3.ExporNoEU.opcion_3"), value: "centroamerica", parent:'comunidad_no_europea'},
    { label: t("exporterTest.step_3.ExporNoEU.opcion_4"), value: "norteamerica", parent:'comunidad_no_europea'},
    { label: t("exporterTest.step_3.ExporNoEU.opcion_5"), value: "asia", parent:'comunidad_no_europea'},
    { label: t("exporterTest.step_3.ExporNoEU.opcion_6"), value: "africa", parent:'comunidad_no_europea'},
    { label: t("exporterTest.step_3.ExporNoEU.opcion_7"), value: "oceania", parent:'comunidad_no_europea'},
  ];

  
  // FRECUENCIA
  const Frecuencia = [
    { label: t("exporterTest.step_3.Frecuencia.opcion_1"), value: "mas_de_4_veces_al_anio", score: 5 },
    { label: t("exporterTest.step_3.Frecuencia.opcion_2"), value: "entre_2_a_4_veces_al_anio", score: 2 },
    { label: t("exporterTest.step_3.Frecuencia.opcion_3"), value: "una_vez_al_anio", score: 0 },
  ];

  
  // ELEMENTOS
  const Elementos = [
    { label: t("exporterTest.step_3.Elementos.opcion_1"), value: "recursos_disponibles", score: 0 },
    { label: t("exporterTest.step_3.Elementos.opcion_2"), value: "conocimientos_de_los_mercados", parent: 'recursos_disponibles', score: 2 },
    { label: t("exporterTest.step_3.Elementos.opcion_3"), value: "reportes_o_informes_de_mercados", parent: 'recursos_disponibles', score: 3 },
    { label: t("exporterTest.step_3.Elementos.opcion_4"), value: "capacitacion_en_internacionalizacion", parent: 'recursos_disponibles', score: 0 },
    { label: t("exporterTest.step_3.Elementos.opcion_5"), value: "financiamiento_para_capital", parent: 'recursos_disponibles', score: 1 },
    { label: t("exporterTest.step_3.Elementos.opcion_6"), value: "financiamiento_para_acciones", parent: 'recursos_disponibles', score: 2 },
    { label: t("exporterTest.step_3.Elementos.opcion_7"), value: "dominio_de_ingles", parent: 'recursos_disponibles', score: 1 },
    { label: t("exporterTest.step_3.Elementos.opcion_8"), value: "tarjetas_de_presentacion_en_ingles", parent: 'recursos_disponibles', score: 0 },
    { label: t("exporterTest.step_3.Elementos.opcion_9"), value: "sitio_web_en_ingles", parent: 'recursos_disponibles', score: 1 },
    { label: t("exporterTest.step_3.Elementos.opcion_10"), value: "material_gráfico", parent: 'recursos_disponibles', score: 0 },
    { label: t("exporterTest.step_3.Elementos.opcion_11"), value: "ninguno_de_los_anteriores", parent: 'recursos_disponibles', score: 0 },
    { label: t("exporterTest.step_3.Elementos.opcion_12"), value: "otro", parent: 'recursos_disponibles', score: 0 },
  ];

  // BUSINESS PLAN
  const BusinessPlan = [
    { label: t("exporterTest.step_3.BusinessPlan.opcion_1"), value: "preliminar", score: 0 },
    { label: t("exporterTest.step_3.BusinessPlan.opcion_2"), value: "en_desarrollo", score: 0 },
    { label: t("exporterTest.step_3.BusinessPlan.opcion_3"), value: "finalizado", score: 0 },
  ];

  // SUFICIENTE 
  // Desactualizado
  const Suficiente = [
    { label: "Si", value: "si", score: 0 },
    { label: "No", value: "no", score: 0 },
  ];
  
  // RRHH
  const RRHH = [
    { label: t("exporterTest.step_3.RRHH.opcion_1"), value: "capacitar_al_personal", score: 1 },
    { label: t("exporterTest.step_3.RRHH.opcion_2"), value: "propietario_a_cargo", score: 0 },
    { label: t("exporterTest.step_3.RRHH.opcion_3"), value: "contratar_a_un_empleado_junior", score: 2 },
    { label: t("exporterTest.step_3.RRHH.opcion_4"), value: "contratar_a_un_empleado_experimentado", score: 5 },
    { label: t("exporterTest.step_3.RRHH.opcion_5"), value: "tercerizar", score: 2 },
    { label: t("exporterTest.step_3.RRHH.opcion_6"), value: "formar_parte_de_un_consorcio", score: 1 },
    { label: t("exporterTest.step_3.RRHH.opcion_7"), value: "en_definicion", score: 0 },
  ];

  
  // PROMOCIONES
  const Promociones = [
    { label: t("exporterTest.step_4.Promociones.opcion_1"), value: "promociones", score: 0 },
    { label: t("exporterTest.step_4.Promociones.opcion_2"), value: "exposicion_en_ferias", parent: 'promociones', score: 2},
    { label: t("exporterTest.step_4.Promociones.opcion_3"), value: "visitas_a_mercados", parent: 'promociones', score: 0 },
    { label: t("exporterTest.step_4.Promociones.opcion_4"), value: "rondas_de_negocios_en_el_exterior", parent: 'promociones', score: 1 },
    { label: t("exporterTest.step_4.Promociones.opcion_5"), value: "rondas_de_negocios_en_mi_pais", parent: 'promociones', score: 1 },
    { label: t("exporterTest.step_4.Promociones.opcion_6"), value: "capacitaciones_y_cursos", parent: 'promociones', score: 1},
    { label: t("exporterTest.step_4.Promociones.opcion_7"), value: "suscripcion_plataformas_de_desarrollo", parent: 'promociones', score: 0 },
    { label: t("exporterTest.step_4.Promociones.opcion_8"), value: "membresia_a_camaras_nacionales", parent: 'promociones', score: 0 },
    { label: t("exporterTest.step_4.Promociones.opcion_9"), value: "membresia_a_cámarasinternacionales", parent: 'promociones', score: 0 },
    { label: t("exporterTest.step_4.Promociones.opcion_10"), value: "misiones_de_prospeccion", parent: 'promociones', score: 0 },
    { label: t("exporterTest.step_4.Promociones.opcion_11"), value: "misiones_de_penetracion", parent: 'promociones', score: 0 },
    { label: t("exporterTest.step_4.Promociones.opcion_12"), value: "otras_en_forma_independiente", parent: 'promociones', score: 0 },
  ];

  
  // FUENTES DE FINANCIAMIENTO
  const FuentesFinanciamiento = [
    { label: t("exporterTest.step_4.FuentesFinanciamiento.opcion_1"), value: "fuentes_financiamiento", score: 0 },
    { label: t("exporterTest.step_4.FuentesFinanciamiento.opcion_2"), value: "capital_propio", parent: 'fuentes_financiamiento', score: 0 },
    { label: t("exporterTest.step_4.FuentesFinanciamiento.opcion_3"), value: "acceso_al_credito", parent: 'fuentes_financiamiento', score: 0 },
    { label: t("exporterTest.step_4.FuentesFinanciamiento.opcion_4"), value: "inversionistas", parent: 'fuentes_financiamiento', score: 0 },
    { label: t("exporterTest.step_4.FuentesFinanciamiento.opcion_5"), value: "otros", parent: 'fuentes_financiamiento', score: 0 },
  ];
  
  // OBSTACULOS
  const Obstaculos = [
    { label: t("exporterTest.step_4.Obstaculos.opcion_1"), value: "obstaculos", score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_2"), value: "no_posee_recursos_humanos", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_3"), value: "falta_financiamiento_para_capital_de_trabajo_para_inversion", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_4"), value: "falta_financiamiento_para_capital_de_trabajo_para_promocion", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_5"), value: "desconocimiento_de_reglamentos", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_6"), value: "desconocimiento_de_informacion", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_7"), value: "dificultad_para_atender_pedidos", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_8"), value: "encontrar_un_representante_en_el_extranjero ", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_9"), value: "escala_de_producción_insuficiente", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_10"), value: "altos_costos_y_problemas_de_distribucion", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_11"), value: "distancia_geografica", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_12"), value: "diferencias_socioculturales", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_13"), value: "tipo_de_cambio", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_14"), value: "entorno_macroeconomico_domestico", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_15"), value: "acceso_a_internet_poco_fiable", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_16"), value: "medidas_paraarancelarias", parent: 'obstaculos', score: 0 },
    { label: t("exporterTest.step_4.Obstaculos.opcion_17"), value: "otros", parent: 'obstaculos', score: 0 },
  ];
  
  const certificaciones: Option[] = [
    { label: t("exporterTest.step_3.certificaciones.opcion_1"), value: 'religiosas' },
    { label: t("exporterTest.step_3.certificaciones.opcion_2"), value: 'halal', parent: 'religiosas' },
    { label: t("exporterTest.step_3.certificaciones.opcion_3"), value: 'kosher', parent: 'religiosas' },
    { label: t("exporterTest.step_3.certificaciones.opcion_4"), value: 'rse_triple_impacto' },
    { label: t("exporterTest.step_3.certificaciones.opcion_5"), value: 'comercio_justo', parent: 'rse_triple_impacto' },
    { label: t("exporterTest.step_3.certificaciones.opcion_6"), value: 'fairtrade', parent: 'rse_triple_impacto' },
    { label: t("exporterTest.step_3.certificaciones.opcion_7"), value: 'empresa_b', parent: 'rse_triple_impacto' },
    { label: t("exporterTest.step_3.certificaciones.opcion_8"), value: 'iso_26000', parent: 'rse_triple_impacto' },
    { label: t("exporterTest.step_3.certificaciones.opcion_9"), value: 'cuidado_del_ambiente'},
    { label: t("exporterTest.step_3.certificaciones.opcion_10"), value: 'huella_de_agua', parent: 'cuidado_del_ambiente' },
    { label: t("exporterTest.step_3.certificaciones.opcion_11"), value: 'huella_de_carbono', parent: 'cuidado_del_ambiente' },
    { label: t("exporterTest.step_3.certificaciones.opcion_12"), value: 'iso_14000', parent: 'cuidado_del_ambiente' },
    { label: t("exporterTest.step_3.certificaciones.opcion_13"), value: 'fsc', parent: 'cuidado_del_ambiente' },
    { label: t("exporterTest.step_3.certificaciones.opcion_14"), value: 'utz', parent: 'cuidado_del_ambiente' },
    { label: t("exporterTest.step_3.certificaciones.opcion_15"), value: 'rainforest', parent: 'cuidado_del_ambiente' },
    { label: t("exporterTest.step_3.certificaciones.opcion_16"), value: 'calidad'},
    { label: t("exporterTest.step_3.certificaciones.opcion_17"), value: 'bpa', parent: 'calidad' },
    { label: t("exporterTest.step_3.certificaciones.opcion_18"), value: 'bpm', parent: 'calidad' },
    { label: t("exporterTest.step_3.certificaciones.opcion_19"), value: 'haccp', parent: 'calidad' },
    { label: t("exporterTest.step_3.certificaciones.opcion_20"), value: 'brc', parent: 'calidad' },
    { label: t("exporterTest.step_3.certificaciones.opcion_21"), value: 'ifs', parent: 'calidad' },
    { label: t("exporterTest.step_3.certificaciones.opcion_22"), value: 'fsc_22000', parent: 'calidad' },
    { label: t("exporterTest.step_3.certificaciones.opcion_23"), value: 'atributos_del_producto'},
    { label: t("exporterTest.step_3.certificaciones.opcion_24"), value: 'certificacion_organica', parent: 'atributos_del_producto' },
    { label: t("exporterTest.step_3.certificaciones.opcion_25"), value: 'vegana', parent: 'atributos_del_producto' },
    { label: t("exporterTest.step_3.certificaciones.opcion_26"), value: 'biodinamica', parent: 'atributos_del_producto' },
    { label: t("exporterTest.step_3.certificaciones.opcion_27"), value: 'otras'},
    { label: t("exporterTest.step_3.certificaciones.opcion_28"), value: 'certificacion_cmmi', parent: 'otras' },
    { label: t("exporterTest.step_3.certificaciones.opcion_29"), value: 'certificacion_pmp', parent: 'otras' },
    { label: t("exporterTest.step_3.certificaciones.opcion_30"), value: 'certificacion_calidad_servicios_turisticos', parent: 'otras' },
    { label: t("exporterTest.step_3.certificaciones.opcion_31"), value: 'certificacion_electrica_ce', parent: 'otras' },
    { label: t("exporterTest.step_3.certificaciones.opcion_32"), value: 'certificacion_de_irradiacion', parent: 'otras' },
    { label: t("exporterTest.step_3.certificaciones.opcion_33"), value: 'implementacion_proceso_compliance', parent: 'otras' },
    { label: t("exporterTest.step_3.certificaciones.opcion_34"), value: 'reglamento_europeo_deforestacion_eudr', parent: 'otras' },
    { label: t("exporterTest.step_3.certificaciones.opcion_35"), value: 'otra_mencione', parent: 'otras' },
    { label: t("exporterTest.step_3.certificaciones.opcion_36"), value: 'resolucion_sanitaria', parent: 'otras' },
    { label: t("exporterTest.step_3.certificaciones.opcion_37"), value: 'certificado_libre_venta', parent: 'otras' },
    { label: t("exporterTest.step_3.certificaciones.opcion_38"), value: 'isp', parent: 'otras' },
    { label: t("exporterTest.step_3.certificaciones.opcion_39"), value: 'ninguna', parent: 'ninguna' },
    ];
   
    
    const destinos: Option[] = [
      { label: t("exporterTest.step_2.destinos.opcion_1"), value: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_2"), value: 'alemania', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_3"), value: 'austria', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_4"), value: 'bélgica', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_5"), value: 'bulgaria', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_6"), value: 'chipre', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_7"), value: 'croacia', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_8"), value: 'dinamarca', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_9"), value: 'eslovaquia', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_10"), value: 'eslovenia', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_11"), value: 'españa', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_12"), value: 'estonia', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_13"), value: 'finlandia', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_14"), value: 'francia', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_15"), value: 'grecia', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_16"), value: 'hungría', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_17"), value: 'irlanda', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_18"), value: 'italia', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_19"), value: 'letonia', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_20"), value: 'lituania', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_21"), value: 'luxemburgo', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_22"), value: 'malta', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_23"), value: 'países bajos', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_24"), value: 'polonia', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_25"), value: 'portugal', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_26"), value: 'república checa', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_27"), value: 'rumanía', parent: 'comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_28"), value: 'suecia', parent: 'comunidad_europea' },
  
      { label: t("exporterTest.step_2.destinos.opcion_29"), value: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_30"), value: 'noruega', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_31"), value: 'suiza', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_32"), value: 'islandia', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_33"), value: 'liechtenstein', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_34"), value: 'albania', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_35"), value: 'bosnia_y_herzegovina', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_36"), value: 'macedonia_del_norte', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_37"), value: 'montenegro', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_38"), value: 'serbia', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_39"), value: 'kosovo', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_40"), value: 'inglaterra', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_41"), value: 'gales', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_42"), value: 'irlanda_del_norte', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_43"), value: 'escocia', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_44"), value: 'vaticano', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_45"), value: 'andorra', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_46"), value: 'aserbadjian', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_47"), value: 'bielorusia', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_48"), value: 'georgia', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_49"), value: 'kasajistan', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_50"), value: 'moldavia', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_51"), value: 'monaco', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_52"), value: 'rusia', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_53"), value: 'san_marino', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_54"), value: 'turquia', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_55"), value: 'ucrania', parent: 'no_comunidad_europea' },
      { label: t("exporterTest.step_2.destinos.opcion_56"), value: 'armenia', parent: 'no_comunidad_europea' },      
  ];
  

  const Sectors: Option[] = [
    { label: t("exporterTest.sectors.item_1.title"), value: "agricultura_ganaderia" },
    {
      label: t("exporterTest.sectors.item_1.option_1"),
      value: "agricultura",
      parent: "agricultura_ganaderia",
    },
    { label: t("exporterTest.sectors.item_1.option_2"), value: "ganaderia", parent: "agricultura_ganaderia" },
    {
      label: t("exporterTest.sectors.item_1.option_3"),
      value: "pesca_acuicultura",
      parent: "agricultura_ganaderia",
    },
    {
      label: t("exporterTest.sectors.item_1.option_4"),
      value: "silvicultura",
      parent: "agricultura_ganaderia",
    },
    { label: t("exporterTest.sectors.item_2.title"), value: "quimicos_materias_primas" },
    {
      label: t("exporterTest.sectors.item_2.option_1"),
      value: "combustibles_minerales_destilacion",
      parent: "quimicos_materias_primas",
    },
    {
      label: t("exporterTest.sectors.item_2.option_2"),
      value: "industria_quimica",
      parent: "quimicos_materias_primas",
    },
    {
      label: t("exporterTest.sectors.item_2.option_3"),
      value: "minería_metales_minerales",
      parent: "quimicos_materias_primas",
    },
    {
      label: t("exporterTest.sectors.item_2.option_4"),
      value: "papel_pasta_papel",
      parent: "quimicos_materias_primas",
    },
    { label: t("exporterTest.sectors.item_2.option_5"), value: "madera", parent: "quimicos_materias_primas" },
    {
      label: t("exporterTest.sectors.item_2.option_6"),
      value: "plastico_caucho",
      parent: "quimicos_materias_primas",
    },
    {
      label: t("exporterTest.sectors.item_2.option_7"),
      value: "productos_minerales_no_metalicos",
      parent: "quimicos_materias_primas",
    },
    {
      label: t("exporterTest.sectors.item_2.option_8"),
      value: "refinerias_petróleo",
      parent: "quimicos_materias_primas",
    },
    { label: t("exporterTest.sectors.item_3.title"), value: "metalurgia_electronica" },
    {
      label: t("exporterTest.sectors.item_3.option_1"),
      value: "construccion_naval",
      parent: "metalurgia_electronica",
    },
    {
      label: t("exporterTest.sectors.item_3.option_2"),
      value: "electronica",
      parent: "metalurgia_electronica",
    },
    {
      label: t("exporterTest.sectors.item_3.option_3"),
      value: "industria_aeroespacial",
      parent: "metalurgia_electronica",
    },
    {
      label: t("exporterTest.sectors.item_3.option_4"),
      value: "industria_defensa",
      parent: "metalurgia_electronica",
    },
    { label: t("exporterTest.sectors.item_3.option_5"), value: "metales", parent: "metalurgia_electronica" },
    {
      label: t("exporterTest.sectors.item_3.option_6"),
      value: "rodados_vehiculos_automoviles_tractores_partes_accesorios",
      parent: "metalurgia_electronica",
    },
    {
      label: t("exporterTest.sectors.item_3.option_7"),
      value: "maquinas_herramientas_equipos_industriales",
      parent: "metalurgia_electronica",
    },
    { label: t("exporterTest.sectors.item_4.title"), value: "energia_medio_ambiente" },
    {
      label: t("exporterTest.sectors.item_4.option_1"),
      value: "tratamientos_aguas_residuales",
      parent: "energia_medio_ambiente",
    },
    { label: t("exporterTest.sectors.item_4.option_2"), value: "emisiones", parent: "energia_medio_ambiente" },
    { label: t("exporterTest.sectors.item_4.option_3"), value: "energia", parent: "energia_medio_ambiente" },
    {
      label: t("exporterTest.sectors.item_4.option_4"),
      value: "energias_renovables",
      parent: "energia_medio_ambiente",
    },
    {
      label: t("exporterTest.sectors.item_4.option_5"),
      value: "tecnologia_medioambiental",
      parent: "energia_medio_ambiente",
    },
    {
      label: t("exporterTest.sectors.item_4.option_6"),
      value: "gestion_residuos",
      parent: "energia_medio_ambiente",
    },
    { label: t("exporterTest.sectors.item_5.title"), value: "bienes_consumo" },
    {
      label: t("exporterTest.sectors.item_5.option_1"),
      value: "alimentacion_nutricion",
      parent: "bienes_consumo",
    },
    {
      label: t("exporterTest.sectors.item_5.option_2"),
      value: "bebidas_alcoholicas",
      parent: "bienes_consumo",
    },
    {
      label: t("exporterTest.sectors.item_5.option_3"),
      value: "bebidas_sin_alcohol",
      parent: "bienes_consumo",
    },
    {
      label: t("exporterTest.sectors.item_5.option_4"),
      value: "cosmetica_cuidado_personal",
      parent: "bienes_consumo",
    },
    {
      label: t("exporterTest.sectors.item_5.option_5"),
      value: "equipamiento_hogar",
      parent: "bienes_consumo",
    },
    { label: t("exporterTest.sectors.item_5.option_6"), value: "juguetes", parent: "bienes_consumo" },
    {
      label: t("exporterTest.sectors.item_5.option_7"),
      value: "alimentos_animales",
      parent: "bienes_consumo",
    },
    {
      label: t("exporterTest.sectors.item_5.option_8"),
      value: "productos_limpieza",
      parent: "bienes_consumo",
    },
    { label: t("exporterTest.sectors.item_6.title"), value: "comercio_electronico" },
    {
      label: t("exporterTest.sectors.item_6.option_1"),
      value: "comercio_electronico_b2b_b2c_c2c",
      parent: "comercio_electronico",
    },
    {
      label: t("exporterTest.sectors.item_6.option_2"),
      value: "pagos_digitales",
      parent: "comercio_electronico",
    },
    { label: t("exporterTest.sectors.item_7.title"), value: "bienes_inmuebles" },
    {
      label: t("exporterTest.sectors.item_7.option_1"),
      value: "sector_inmobiliario",
      parent: "bienes_inmuebles",
    },
    { label: t("exporterTest.sectors.item_8.title"), value: "finanzas" },
    {
      label: t("exporterTest.sectors.item_8.option_1"),
      value: "entidades_bancarias",
      parent: "finanzas",
    },
    {
      label: t("exporterTest.sectors.item_8.option_2"),
      value: "entidades_financieras",
      parent: "finanzas",
    },
    {
      label: t("exporterTest.sectors.item_8.option_3"),
      value: "mercados_financieros",
      parent: "finanzas",
    },
    {
      label: t("exporterTest.sectors.item_8.option_4"),
      value: "servicios_financieros",
      parent: "finanzas",
    },
    { label: t("exporterTest.sectors.item_8.option_5"), value: "seguros", parent: "finanzas" },
    {
      label: t("exporterTest.sectors.item_9.title"),
      value: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_1"),
      value: "investigacion_desarrollo",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_2"),
      value: "servicios_profesionales_consultoria",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_3"),
      value: "servicios_juridicos_contabilidad_administracion_empresas_rrpp",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_4"),
      value: "servicios_arquitectura_ingenieria_tecnicos",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_5"),
      value: "servicios_agricolas_mineros",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_6"),
      value: "servicios_relacionados_comercio",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_7"),
      value: "otros_servicios_empresariales",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_8"),
      value: "aeroespacial",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_9"),
      value: "nanotecnologico",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_10"),
      value: "software_servicios_informaticos",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_11"),
      value: "videojuegos",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_12"),
      value: "franquicias",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_13"),
      value: "diseno",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_14"),
      value: "comunicacion_marketplace",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_15"),
      value: "artes_visuales",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_16"),
      value: "audiovisual",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_17"),
      value: "editorial",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_18"),
      value: "musica",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_19"),
      value: "teatro",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_20"),
      value: "e_sport",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_21"),
      value: "sportech",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_22"),
      value: "cooperacion_deportiva",
      parent: "servicios_basados_conocimientos",
    },
    {
      label: t("exporterTest.sectors.item_9.option_23"),
      value: "sport_business",
      parent: "servicios_basados_conocimientos",
    },
    { label: t("exporterTest.sectors.item_10.title"), value: "logistica" },
    { label: t("exporterTest.sectors.item_10.option_1"), value: "aviacion", parent: "logistica" },
    {
      label: t("exporterTest.sectors.item_10.option_2"),
      value: "transporte_terrestre",
      parent: "logistica",
    },
    {
      label: t("exporterTest.sectors.item_10.option_3"),
      value: "transporte_acuatico",
      parent: "logistica",
    },
    { label: t("exporterTest.sectors.item_11.title"), value: "medios_comunicacion" },
    { label: t("exporterTest.sectors.item_11.option_1"), value: "cine", parent: "medios_comunicacion" },
    { label: t("exporterTest.sectors.item_11.option_2"), value: "radio", parent: "medios_comunicacion" },
    { label: t("exporterTest.sectors.item_11.option_3"), value: "television", parent: "medios_comunicacion" },
    {
      label: t("exporterTest.sectors.item_11.option_4"),
      value: "industria_videojuego",
      parent: "medios_comunicacion",
    },
    {
      label: t("exporterTest.sectors.item_11.option_5"),
      value: "mercado_editorial",
      parent: "medios_comunicacion",
    },
    { label: t("exporterTest.sectors.item_11.option_6"), value: "musica", parent: "medios_comunicacion" },
    {
      label: t("exporterTest.sectors.item_11.option_7"),
      value: "publicidad_marketing",
      parent: "medios_comunicacion",
    },
    { label: t("exporterTest.sectors.item_12.title"), value: "internet" },
    {
      label: t("exporterTest.sectors.item_12.option_1"),
      value: "publicidad_marketing",
      parent: "internet",
    },
    {
      label: t("exporterTest.sectors.item_12.option_2"),
      value: "social_media_contenidos",
      parent: "internet",
    },
    {
      label: t("exporterTest.sectors.item_12.option_3"),
      value: "aplicaciones_internet_movil",
      parent: "internet",
    },
    {
      label: t("exporterTest.sectors.item_12.option_4"),
      value: "ciberdelincuencia",
      parent: "internet",
    },
    { label: t("exporterTest.sectors.item_12.option_5"), value: "trafico", parent: "internet" },
    {
      label: t("exporterTest.sectors.item_13.title"),
      value: "tecnologia_telecomunicaciones",
    },
    {
      label: t("exporterTest.sectors.item_13.option_1"),
      value: "electrodomesticos",
      parent: "tecnologia_telecomunicaciones",
    },
    {
      label: t("exporterTest.sectors.item_13.option_2"),
      value: "electronica_consumo",
      parent: "tecnologia_telecomunicaciones",
    },
    {
      label: t("exporterTest.sectors.item_13.option_3"),
      value: "hardware",
      parent: "tecnologia_telecomunicaciones",
    },
    {
      label: t("exporterTest.sectors.item_13.option_4"),
      value: "servicios_ti",
      parent: "tecnologia_telecomunicaciones",
    },
    {
      label: t("exporterTest.sectors.item_13.option_5"),
      value: "software",
      parent: "tecnologia_telecomunicaciones",
    },
    {
      label: t("exporterTest.sectors.item_13.option_6"),
      value: "telecomunicaciones",
      parent: "tecnologia_telecomunicaciones",
    },
    { label: t("exporterTest.sectors.item_14.title"), value: "deporte_ocio" },
    { label: t("exporterTest.sectors.item_14.option_1"), value: "arte_cultura", parent: "deporte_ocio" },
    {
      label: t("exporterTest.sectors.item_14.option_2"),
      value: "deporte_fitness",
      parent: "deporte_ocio",
    },
    { label: t("exporterTest.sectors.item_14.option_3"), value: "juegos_azar", parent: "deporte_ocio" },
    {
      label: t("exporterTest.sectors.item_14.option_4"),
      value: "actividades_aire_libre",
      parent: "deporte_ocio",
    },
    { label: t("exporterTest.sectors.item_15.title"), value: "salud" },
    {
      label: t("exporterTest.sectors.item_15.option_1"),
      value: "asistencia_cuidados",
      parent: "salud",
    },
    {
      label: t("exporterTest.sectors.item_15.option_2"),
      value: "industria_farmaceutica",
      parent: "salud",
    },
    { label: t("exporterTest.sectors.item_15.option_3"), value: "servicios_medicos", parent: "salud" },
    { label: t("exporterTest.sectors.item_15.option_4"), value: "sistema_sanitario", parent: "salud" },
    { label: t("exporterTest.sectors.item_15.option_5"), value: "tecnologia_medica", parent: "salud" },
    { label: t("exporterTest.sectors.item_16.title"), value: "institucional" },
    {
      label: t("exporterTest.sectors.item_16.option_1"),
      value: "viajes_negocios",
      parent: "institucional",
    },
    { label: t("exporterTest.sectors.item_16.option_2"), value: "viajes_ocio", parent: "institucional" },
    {
      label: t("exporterTest.sectors.item_16.option_3"),
      value: "servicios_alojamiento",
      parent: "institucional",
    },
    {
      label: t("exporterTest.sectors.item_16.option_4"),
      value: "restaurante_cafeterias",
      parent: "institucional",
    },
    {
      label: t("exporterTest.sectors.item_16.option_5"),
      value: "licitaciones_publicas",
      parent: "institucional",
    },
    { label: t("exporterTest.sectors.item_16.option_6"), value: "ciencia", parent: "institucional" },
    { label: t("exporterTest.sectors.item_17.title"), value: "otro" },
    { label: t("exporterTest.sectors.item_17.option_1"), value: "menciona_cual", parent: "otro" },
  ];

  const countries = {
      [t("exporterTest.countries.title_1")]: [
      "Argentina",
      "Bolivia",
      "Brasil",
      "Chile",
      "Colombia",
      "Ecuador",
      "Guyana",
      "Paraguay",
      "Perú",
      "Surinam",
      "Uruguay",
      "Venezuela",
    ],
    [t("exporterTest.countries.title_2")]: [
      "Belice",
      "Costa Rica",
      "El Salvador",
      "Guatemala",
      "Honduras",
      "Nicaragua",
      "Panamá",
    ],
    [t("exporterTest.countries.title_3")]: ["Canadá", "Estados Unidos", "México"],
    Caribe: [
      "Antigua y Barbuda",
      "Bahamas",
      "Barbados",
      "Cuba",
      "Dominica",
      "Granada",
      "Haití",
      "Jamaica",
      "Puerto Rico",
      "República Dominicana",
      "San Cristóbal y Nieves",
      "Santa Lucía",
      "San Vicente y las Granadinas",
      "Trinidad y Tobago",
    ],
    [t("exporterTest.countries.title_4")]: [
      "Aruba (Países Bajos)",
      "Bonaire (Países Bajos)",
      "Curazao (Países Bajos)",
      "Groenlandia (Reino de Dinamarca)",
      "Guayana Francesa",
      "Islas Caimán (Reino Unido)",
      "Islas Turcas y Caicos (Reino Unido)",
      "Islas Vírgenes Británicas (Reino Unido)",
    ],
  };
  
  const provincesByCountry: { [country: string]: string[] } = {
    Argentina: [
      "Buenos Aires",
      "CABA",
      "Catamarca",
      "Chaco",
      "Chubut",
      "Córdoba",
      "Corrientes",
      "Entre Ríos",
      "Formosa",
      "Jujuy",
      "La Pampa",
      "La Rioja",
      "Mendoza",
      "Misiones",
      "Neuquén",
      "Río Negro",
      "Salta",
      "San Juan",
      "San Luis",
      "Santa Cruz",
      "Santa Fe",
      "Santiago del Estero",
      "Tierra del Fuego, Antártida e Islas del Atlántico Sur",
      "Tucumán",
    ],
    Bolivia: [
      "Chuquisaca",
      "Cochabamba",
      "Beni",
      "La Paz",
      "Oruro",
      "Pando",
      "Potosí",
      "Santa Cruz",
      "Tarija",
    ],
    Brasil: [
      "Acre",
      "Alagoas",
      "Amapá",
      "Amazonas",
      "Bahía",
      "Ceará",
      "Distrito Federal (Brasilia)",
      "Espírito Santo",
      "Goiás",
      "Maranhão",
      "Mato Grosso",
      "Mato Grosso do Sul",
      "Minas Gerais",
      "Pará",
      "Paraíba",
      "Paraná",
      "Pernambuco",
      "Piauí",
      "Río de Janeiro",
      "Río Grande do Norte",
      "Río Grande do Sul",
      "Rondônia",
      "Roraima",
      "Santa Catarina",
      "São Paulo",
      "Sergipe",
      "Tocantins",
    ],
    Chile: [
      "Arica y Parinacota",
      "Tarapacá",
      "Antofagasta",
      "Atacama",
      "Coquimbo",
      "Valparaíso",
      "Región Metropolitana de Santiago",
      "Región del Libertador General Bernardo O'Higgins (O'Higgins)",
      "Región del Maule",
      "Ñuble",
      "Región del Biobío",
      "La Araucanía",
      "Los Ríos",
      "Los Lagos",
      "Aysén del General Carlos Ibáñez del Campo",
      "Magallanes y de la Antártica Chilena",
    ],
    Colombia: [
      "Distrito Capital",
      "Amazonas",
      "Antioquia",
      "Arauca",
      "Atlántico",
      "Bolívar",
      "Boyacá",
      "Caldas",
      "Caquetá",
      "Casanare",
      "Cauca",
      "Cesar",
      "Chocó",
      "Córdoba",
      "Cundinamarca",
      "Guainía",
      "Guaviare",
      "Huila",
      "La Guajira",
      "Magdalena",
      "Meta",
      "Nariño",
      "Norte de Santander",
      "Putumayo",
      "Quindío",
      "Risaralda",
      "San Andrés y Providencia",
      "Santander",
      "Sucre",
      "Tolima",
      "Valle del Cauca",
      "Vaupés",
      "Vichada",
    ],
    Ecuador: [
      "Azuay",
      "Bolívar",
      "Cañar",
      "Carchi",
      "Chimborazo",
      "Cotopaxi",
      "El Oro",
      "Esmeraldas",
      "Galápagos",
      "Guayas",
      "Imbabura",
      "Loja",
      "Los Ríos",
      "Manabí",
      "Morona Santiago",
      "Napo",
      "Orellana",
      "Pastaza",
      "Pichincha",
      "Santa Elena",
      "Santo Domingo de los Tsáchilas",
      "Sucumbíos",
      "Tungurahua",
      "Zamora-Chinchipe",
    ],
    Guyana: [
      "Barima-Waini",
      "Cuyuni-Mazaruni",
      "Demerara-Mahaica",
      "East Berbice-Corentyne",
      "Essequibo Islands-West Demerara",
      "Mahaica-Berbice",
      "Pomeroon-Supenaam",
      "Potaro-Siparuni",
      "Upper Demerara-Berbice",
      "Upper Takutu-Upper Essequibo",
    ],
    Paraguay: [
      "Alto Paraguay",
      "Alto Paraná",
      "Amambay",
      "Boquerón",
      "Caaguazú",
      "Caazapá",
      "Canindeyú",
      "Central",
      "Concepción",
      "Cordillera",
      "Guairá",
      "Itapúa",
      "Misiones",
      "Ñeembucú",
      "Paraguarí",
      "Presidente Hayes",
      "San Pedro",
      "Asunción (Distrito Capital)",
    ],
    Perú: [
      "Amazonas",
      "Áncash",
      "Apurímac",
      "Arequipa",
      "Ayacucho",
      "Cajamarca",
      "Callao (Provincia Constitucional)",
      "Cusco",
      "Huancavelica",
      "Huánuco",
      "Ica",
      "Junín",
      "La Libertad",
      "Lambayeque",
      "Lima",
      "Loreto",
      "Madre de Dios",
      "Moquegua",
      "Pasco",
      "Piura",
      "Puno",
      "San Martín",
      "Tacna",
      "Tumbes",
      "Ucayali",
    ],
    Surinam: [
      "Brokopondo",
      "Commewijne",
      "Coronie",
      "Marowijne",
      "Nickerie",
      "Para",
      "Paramaribo",
      "Saramacca",
      "Sipaliwini",
      "Wanica",
    ],
    Uruguay: [
      "Artigas",
      "Canelones",
      "Cerro Largo",
      "Colonia",
      "Durazno",
      "Flores",
      "Florida",
      "Lavalleja",
      "Maldonado",
      "Montevideo",
      "Paysandú",
      "Río Negro",
      "Rivera",
      "Rocha",
      "Salto",
      "San José",
      "Soriano",
      "Tacuarembó",
      "Treinta y Tres",
    ],
    Venezuela: [
      "Amazonas",
      "Anzoátegui",
      "Apure",
      "Aragua",
      "Barinas",
      "Bolívar",
      "Carabobo",
      "Cojedes",
      "Delta Amacuro",
      "Falcón",
      "Guárico",
      "Lara",
      "Mérida",
      "Miranda",
      "Monagas",
      "Nueva Esparta",
      "Portuguesa",
      "Sucre",
      "Táchira",
      "Trujillo",
      "Vargas",
      "Yaracuy",
      "Zulia",
      "Distrito Capital (Caracas)",
    ],
    Belice: ["Belice", "Cayo", "Corozal", "Orange Walk", "Stann Creek", "Toledo"],
    "Costa Rica": [
      "San José",
      "Alajuela",
      "Cartago",
      "Heredia",
      "Guanacaste",
      "Puntarenas",
      "Limón",
    ],
    "El Salvador": [
      "Ahuachapán",
      "Cabañas",
      "Chalatenango",
      "Cuscatlán",
      "La Libertad",
      "La Paz",
      "La Unión",
      "Morazán",
      "San Miguel",
      "San Salvador",
      "San Vicente",
      "Santa Ana",
      "Sonsonate",
      "Usulután",
    ],
    Guatemala: [
      "Alta Verapaz",
      "Baja Verapaz",
      "Chimaltenango",
      "Chiquimula",
      "El Progreso",
      "Escuintla",
      "Guatemala",
      "Huehuetenango",
      "Izabal",
      "Jalapa",
      "Jutiapa",
      "Petén",
      "Quetzaltenango",
      "Quiché",
      "Retalhuleu",
      "Sacatepéquez",
      "San Marcos",
      "Santa Rosa",
      "Sololá",
      "Suchitepéquez",
      "Totonicapán",
      "Zacapa",
    ],
    Honduras: [
      "Atlántida",
      "Choluteca",
      "Colón",
      "Comayagua",
      "Copán",
      "Cortés",
      "El Paraíso",
      "Francisco Morazán",
      "Gracias a Dios",
      "Intibucá",
      "Islas de la Bahía",
      "La Paz",
      "Lempira",
      "Ocotepeque",
      "Olancho",
      "Santa Bárbara",
      "Valle",
      "Yoro",
    ],
    Nicaragua: [
      "Boaco",
      "Carazo",
      "Chinandega",
      "Chontales",
      "Estelí",
      "Granada",
      "Jinotega",
      "León",
      "Madriz",
      "Managua",
      "Masaya",
      "Matagalpa",
      "Nueva Segovia",
      "Río San Juan",
      "Rivas",
      "Región Autónoma de la Costa Caribe Norte",
      "Región Autónoma de la Costa Caribe Sur",
    ],
    Panamá: [
      "Bocas del Toro",
      "Coclé",
      "Colón",
      "Chiriquí",
      "Darién",
      "Herrera",
      "Los Santos",
      "Panamá",
      "Veraguas",
      "Panamá Oeste",
      "Comarca Guna Yala",
      "Comarca Emberá-Wounaan",
      "Comarca Ngäbe-Buglé",
    ],
    Canadá: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Nova Scotia",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Northwest Territories",
      "Nunavut",
      "Yukon",
    ],
    "Estados Unidos": [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
    México: [
      "Aguascalientes",
      "Baja California",
      "Baja California Sur",
      "Campeche",
      "Chiapas",
      "Chihuahua",
      "Ciudad de México",
      "Coahuila",
      "Colima",
      "Durango",
      "Guanajuato",
      "Guerrero",
      "Hidalgo",
      "Jalisco",
      "México (Estado de México)",
      "Michoacán",
      "Morelos",
      "Nayarit",
      "Nuevo León",
      "Oaxaca",
      "Puebla",
      "Querétaro",
      "Quintana Roo",
      "San Luis Potosí",
      "Sinaloa",
      "Sonora",
      "Tabasco",
      "Tamaulipas",
      "Tlaxcala",
      "Veracruz",
      "Yucatán",
      "Zacatecas",
    ],
    "Antigua y Barbuda": [
      "Saint George",
      "Saint John",
      "Saint Mary",
      "Saint Paul",
      "Saint Peter",
      "Saint Philip",
    ],
    Bahamas: [
      "Acklins",
      "Berry Islands",
      "Bimini",
      "Black Point",
      "Cat Island",
      "Central Abaco",
      "Central Andros",
      "Central Eleuthera",
      "City of Freeport",
      "Crooked Island",
      "East Grand Bahama",
      "Exuma",
      "Grand Cay",
      "Harbour Island",
      "Hope Town",
      "Inagua",
      "Long Island",
      "Mangrove Cay",
      "Mayaguana",
      "Moore's Island",
      "North Abaco",
      "North Andros",
      "North Eleuthera",
      "Ragged Island",
      "Rum Cay",
      "San Salvador",
      "South Abaco",
      "South Andros",
      "South Eleuthera",
      "Spanish Wells",
      "West Grand Bahama",
    ],
    Barbados: [
      "Christ Church",
      "Saint Andrew",
      "Saint George",
      "Saint James",
      "Saint John",
      "Saint Joseph",
      "Saint Lucy",
      "Saint Michael",
      "Saint Peter",
      "Saint Philip",
      "Saint Thomas",
    ],
    Cuba: [
      "Artemisa",
      "Camagüey",
      "Ciego de Ávila",
      "Cienfuegos",
      "Granma",
      "Guantánamo",
      "Holguín",
      "Isla de la Juventud",
      "La Habana",
      "Las Tunas",
      "Matanzas",
      "Mayabeque",
      "Pinar del Río",
      "Sancti Spíritus",
      "Santiago de Cuba",
      "Villa Clara",
    ],
    Dominica: [
      "Saint Andrew",
      "Saint David",
      "Saint George",
      "Saint John",
      "Saint Joseph",
      "Saint Luke",
      "Saint Mark",
      "Saint Patrick",
      "Saint Paul",
      "Saint Peter",
    ],
    Granada: [
      "Saint Andrew",
      "Saint David",
      "Saint George",
      "Saint John",
      "Saint Mark",
      "Saint Patrick",
    ],
    Haití: [
      "Artibonite",
      "Centre",
      "Grand'Anse",
      "Nippes",
      "Nord",
      "Nord-Est",
      "Nord-Ouest",
      "Ouest (Oeste)",
      "Sud",
      "Sud-Est",
    ],
    Jamaica: [
      "Clarendon",
      "Hanover",
      "Kingston",
      "Manchester",
      "Portland",
      "Saint Andrew",
      "Saint Ann",
      "Saint Catherine",
      "Saint Elizabeth",
      "Saint James",
      "Saint Mary",
      "Saint Thomas",
      "Trelawny",
      "Westmoreland",
    ],
    "Puerto Rico": [
      "Adjuntas",
      "Aguada",
      "Aguadilla",
      "Aguas Buenas",
      "Aibonito",
      "Añasco",
      "Arecibo",
      "Arroyo",
      "Barceloneta",
      "Barranquitas",
      "Bayamón",
      "Cabo Rojo",
      "Caguas",
      "Camuy",
      "Canóvanas",
      "Carolina",
      "Cataño",
      "Cayey",
      "Ceiba",
      "Ciales",
      "Cidra",
      "Coamo",
      "Comerío",
      "Corozal",
      "Culebra",
      "Dorado",
      "Fajardo",
      "Florida",
      "Guánica",
      "Guayama",
      "Guayanilla",
      "Guaynabo",
      "Gurabo",
      "Hatillo",
      "Hormigueros",
      "Humacao",
      "Isabela",
      "Jayuya",
      "Juana Díaz",
      "Juncos",
      "Lajas",
      "Lares",
      "Las Marías",
      "Las Piedras",
      "Loíza",
      "Luquillo",
      "Manatí",
      "Maricao",
      "Maunabo",
      "Mayagüez",
      "Moca",
      "Morovis",
      "Naguabo",
      "Naranjito",
      "Orocovis",
      "Patillas",
      "Peñuelas",
      "Ponce",
      "Quebradillas",
      "Rincón",
      "Río Grande",
      "Sabana Grande",
      "Salinas",
      "San Germán",
      "San Juan",
      "San Lorenzo",
      "San Sebastián",
      "Santa Isabel",
      "Toa Alta",
      "Toa Baja",
      "Trujillo Alto",
      "Utuado",
      "Vega Alta",
      "Vega Baja",
      "Vieques",
      "Villalba",
      "Yabucoa",
      "Yauco",
    ],
    "República Dominicana": [
      "Azua",
      "Bahoruco",
      "Barahona",
      "Dajabón",
      "Duarte",
      "Elías Piña",
      "El Seibo",
      "Espaillat",
      "Hato Mayor",
      "Hermanas Mirabal",
      "Independencia",
      "La Altagracia",
      "La Romana",
      "La Vega",
      "María Trinidad Sánchez",
      "Monseñor Nouel",
      "Monte Cristi",
      "Monte Plata",
      "Pedernales",
      "Peravia",
      "Puerto Plata",
      "Samaná",
      "San Cristóbal",
      "San José de Ocoa",
      "San Juan",
      "San Pedro de Macorís",
      "Sánchez Ramírez",
      "Santiago",
      "Santiago Rodríguez",
      "Santo Domingo",
      "Valverde",
    ],
    "San Cristóbal y Nieves": [
      "Christ Church Nichola Town",
      "Saint Anne Sandy Point",
      "Saint George Basseterre",
      "Saint John Capisterre",
      "Saint Mary Cayon",
      "Saint Paul Capisterre",
      "Saint Peter Basseterre",
      "Saint Thomas Middle Island",
      "Saint James Windward",
      "Saint John Figtree",
      "Saint Paul Charlestown",
      "Saint Thomas Lowland",
      "Trinity Palmetto Point",
    ],
    "Santa Lucía": [
      "Anse-la-Raye",
      "Canaries",
      "Castries",
      "Choiseul",
      "Dennery",
      "Gros Islet",
      "Laborie",
      "Micoud",
      "Praslin",
      "Soufrière",
      "Vieux Fort",
    ],
    "San Vicente y las Granadinas": [
      "Charlotte",
      "Grenadines",
      "Saint Andrew",
      "Saint David",
      "Saint George",
      "Saint Patrick",
    ],
    "Trinidad y Tobago": [
      "Diego Martín",
      "Penal/Debe",
      "Princes Town",
      "San Fernando",
      "San Juan/Laventille",
      "Sangre Grande",
      "Siparia",
      "Tobago",
      "Tunapuna/Piarco",
    ],
    "Aruba (Países Bajos)": [
      "Oranjestad",
      "Paradera",
      "San Nicolaas",
      "Noord",
      "Santa Cruz",
      "Savaneta",
      "Pos Chiquito",
      "Tanki Leendert",
    ],
    "Bonaire (Países Bajos)": ["Kralendijk", "Rincon"],
    "Curazao (Países Bajos)": [
      "Willemstad",
      "Bandabou",
      "Bandariba",
      "Oostpunt",
      "Noord",
      "Zuidoost",
      "Pietermaai",
      "Westpunt",
    ],
    "Groenlandia (Reino de Dinamarca)": [
      "Sermersooq",
      "Kujalleq",
      "Qeqertalik",
      "Qeqqata",
      "Avannaata",
    ],
    "Guayana Francesa": [
      "Apatou",
      "Awala-Yalimapo",
      "Camopi",
      "Cayenne",
      "Grand-Santi",
      "Iracoubo",
      "Kourou",
      "Macouria",
      "Mana",
      "Maripasoula",
      "Matoury",
      "Montsinéry-Tonnegrande",
      "Ouanary",
      "Papaïchton",
      "Régina",
      "Remire-Montjoly",
      "Roura",
      "Saint-Élie",
      "Saint-Georges",
      "Saint-Laurent-du-Maroni",
      "Saül",
      "Sinnamary",
    ],
    "Islas Caimán (Reino Unido)": [
      "George Town",
      "West Bay",
      "Bodden Town",
      "North Side",
      "East End",
    ],
    "Islas Turcas y Caicos (Reino Unido)": [
      "Providenciales",
      "Grand Turk",
      "North Caicos",
      "Middle Caicos",
      "South Caicos",
      "Salt Cay",
    ],
    "Islas Vírgenes Británicas (Reino Unido)": [
      "Tortola",
      "Virgin Gorda",
      "Jost Van Dyke",
      "Anegada",
    ],
  };
  
  const citiesByProvince: { [province: string]: string[] } = {
    "Buenos Aires": [
      "Azul",
      "Bahía Blanca",
      "Balcarce",
      "Bolívar",
      "Chascomús",
      "Dolores",
      "Junín",
      "Luján",
      "Mar del Plata",
      "Mercedes",
      "Necochea",
      "Olavarría",
      "Pergamino",
      "San Isidro",
      "San Nicolás de los Arroyos",
      "San Pedro",
      "Tandil",
      "Tigre",
      "Vicente López",
    ],
    CABA: ["Ciudad Autónoma de Buenos Aires"],
    Córdoba: [
      "Córdoba",
      "Río Cuarto",
      "Villa María",
      "Marcos Juárez",
      "San Francisco",
    ],
    "Santa Fe": [
      "Rosario",
      "Santa Fe",
      "Rafaela",
      "Venado Tuerto",
      "Reconquista",
    ],
    Mendoza: ["Mendoza", "San Rafael", "Godoy Cruz", "Maipú", "Luján de Cuyo"],
    "La Paz": ["La Paz"],
    "Santa Cruz de la Sierra": ["Santa Cruz de la Sierra"],
    Cochabamba: ["Cochabamba"],
    Sucre: ["Sucre"],
    Potosí: ["Potosí"],
    "São Paulo": ["São Paulo"],
    "Río de Janeiro": ["Río de Janeiro"],
    "Distrito Federal": ["Brasilia"],
    Bahía: ["Salvador"],
    Ceará: ["Fortaleza"],
    "Región Metropolitana": ["Santiago"],
    Valparaíso: ["Valparaíso"],
    Concepción: ["Concepción"],
    "Distrito Capital": ["Bogotá"],
    Antioquia: ["Medellín"],
    "Valle del Cauca": ["Cali"],
    Atlántico: ["Barranquilla"],
    Bolívar: ["Cartagena"],
    Pichincha: ["Quito"],
    Guayas: ["Guayaquil"],
    Azuay: ["Cuenca"],
    Tungurahua: ["Ambato"],
    Loja: ["Loja"],
    Asunción: ["Asunción"],
    "Ciudad del Este": ["Ciudad del Este"],
    "San Lorenzo": ["San Lorenzo"],
    Luque: ["Luque"],
    Encarnación: ["Encarnación"],
    Lima: ["Lima"],
    Arequipa: ["Arequipa"],
    "La Libertad": ["Trujillo"],
    Lambayeque: ["Chiclayo"],
    Callao: ["Callao"],
    Montevideo: ["Montevideo"],
    Salto: ["Salto"],
    "Ciudad de la Costa": ["Ciudad de la Costa"],
    Paysandú: ["Paysandú"],
    "Las Piedras": ["Las Piedras"],
    Caracas: ["Caracas"],
    Maracaibo: ["Maracaibo"],
    Valencia: ["Valencia"],
    Barquisimeto: ["Barquisimeto"],
    "Ciudad Guayana": ["Ciudad Guayana"],
    "San José": ["San José"],
    Limón: ["Limón"],
    Alajuela: ["Alajuela"],
    Heredia: ["Heredia"],
    Puntarenas: ["Puntarenas"],
    "San Salvador": ["San Salvador", "Mejicanos"],
    "Santa Ana": ["Santa Ana"],
    "San Miguel": ["San Miguel"],
    Soyapango: ["Soyapango"],
    "Ciudad de Guatemala": ["Ciudad de Guatemala"],
    Mixco: ["Mixco"],
    "Villa Nueva": ["Villa Nueva"],
    Quetzaltenango: ["Quetzaltenango"],
    Escuintla: ["Escuintla"],
    "Francisco Morazán": ["Tegucigalpa"],
    "San Pedro Sula": ["San Pedro Sula"],
    Choloma: ["Choloma"],
    "La Ceiba": ["La Ceiba"],
    Atlántida: ["La Ceiba"],
    Comayagua: ["Comayagua"],
    Managua: ["Managua"],
    León: ["León"],
    Masaya: ["Masaya"],
    Matagalpa: ["Matagalpa"],
    Chinandega: ["Chinandega"],
    "Ciudad de Panamá": ["Ciudad de Panamá"],
    "San Miguelito": ["San Miguelito"],
    Tocumen: ["Tocumen"],
    David: ["David"],
    Arraiján: ["Arraiján"],
    "Nueva York": ["Nueva York"],
    California: ["Los Ángeles"],
    Illinois: ["Chicago"],
    Texas: ["Houston"],
    Florida: ["Miami"],
    Ontario: ["Toronto", "Ottawa"],
    Quebec: ["Montreal"],
    "British Columbia": ["Vancouver"],
    Alberta: ["Calgary"],
    "Port of Spain": ["Port of Spain"],
    Bridgetown: ["Bridgetown"],
    "Saint Peter": ["Speightstown"],
    "Christ Church": ["Oistins"],
    "Saint James": ["Holetown", "Montego Bay"],
    "Saint Joseph": ["Bathsheba"],
    "La Habana": ["La Habana"],
    "Santiago de Cuba": ["Santiago de Cuba"],
    Camagüey: ["Camagüey"],
    Holguín: ["Holguín"],
    "Villa Clara": ["Santa Clara"],
    Ouest: [
      "Puerto Príncipe",
      "Carrefour",
      "Delmas",
      "Pétion-Ville",
      "Port-au-Prince",
    ],
    Kingston: ["Kingston"],
    "Saint Catherine": ["Spanish Town", "Portmore"],
    Manchester: ["Mandeville"],
    "San Juan": ["San Juan"],
    Bayamón: ["Bayamón"],
    Carolina: ["Carolina"],
    Ponce: ["Ponce"],
    Caguas: ["Caguas"],
    "Distrito Nacional": ["Santo Domingo"],
    Santiago: ["Santiago de los Caballeros"],
    "Santo Domingo Este": ["Santo Domingo Este"],
    "Santo Domingo Oeste": ["Santo Domingo Oeste"],
    "Santo Domingo Norte": ["Santo Domingo Norte"],
  };

  return {
    Categorias,
    RangoVentas,
    TiempoEsperado,
    Canales,
    ExporNoEU,
    Frecuencia,
    Elementos,
    BusinessPlan,
    Suficiente,
    RRHH,
    Promociones,
    FuentesFinanciamiento,
    Obstaculos,
    destinos,
    certificaciones,
    PlanNegocios,
    Sectors,
    countries,
    provincesByCountry,
    citiesByProvince
  }
}
