import React, { useEffect, useState } from "react";

interface Country {
  id: string;
  name: string;
}

interface Region {
  countries: Country[];
}

interface CountryNameProps {
  countryOriginId: string;
  countriesOrigin: Region[];
  countriesDestination: Region[];
}

const CountryName: React.FC<CountryNameProps> = ({ 
  countryOriginId, 
  countriesOrigin, 
  countriesDestination 
}) => {
  const [countryName, setCountryName] = useState<string>("");

  useEffect(() => {
    // Combinar los países de origen y destino
    const allCountries = [
      ...countriesOrigin.flatMap(region => region.countries),
      ...countriesDestination.flatMap(region => region.countries),
    ];

    // Buscar el país por su ID
    const country = allCountries.find((c) => c.id === countryOriginId);

    // Actualizar el nombre del país (o dejarlo vacío si no se encuentra)
    setCountryName(country ? country.name : "Desconocido");
  }, [countryOriginId, countriesOrigin, countriesDestination]);

  return <span>{countryName}</span>;
};

export default CountryName;
