import { t } from "i18next";
import React, { useState } from "react";
import IconCkeck from "../../components/Icons/IconCkeck";

interface CopyLinkButtonProps {
  className?: string;
  children?: React.ReactNode;
  text?: boolean;
  link?: string;
}

export const CopyLinkButton: React.FC<CopyLinkButtonProps> = ({
  className = "",
  children,
  text = false,
  link = window.location.href,
}) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text: string) => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(text)
        .then(() => handleCopySuccess())
        .catch((err) => console.error("Error al copiar:", err));
    } else {
      // Método alternativo para iOS
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      handleCopySuccess();
    }
  };

  const handleCopySuccess = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Vuelve al estado inicial en 2 segundos
  };

  return (
    <button
      onClick={() => copyToClipboard(link)}
      className={`${copied ? "copied" : ""} ${className}`}
    >
      {copied ? (
        <>
          <IconCkeck />
          {text && <span>{t("news.copyNewsLinkSucces")}</span>}
        </>
      ) : (
        children || "Copiar enlace"
      )}
    </button>
  );
};

export default CopyLinkButton;
