import * as React from "react";
type IconCloseProps = React.SVGProps<SVGSVGElement>;

export const IconClose: React.FC<IconCloseProps> = (props) => (
  <svg
    width={14}
    height={15}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L7 6.43934L12.4697 0.96967C12.7626 0.676777 13.2374 0.676777 13.5303 0.96967C13.8232 1.26256 13.8232 1.73744 13.5303 2.03033L8.06066 7.5L13.5303 12.9697C13.8232 13.2626 13.8232 13.7374 13.5303 14.0303C13.2374 14.3232 12.7626 14.3232 12.4697 14.0303L7 8.56066L1.53033 14.0303C1.23744 14.3232 0.762563 14.3232 0.46967 14.0303C0.176777 13.7374 0.176777 13.2626 0.46967 12.9697L5.93934 7.5L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967Z"
      fill="#97A2A9"
    />
  </svg>
);
export default IconClose;
