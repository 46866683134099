import React, { useState } from "react";
import CountryCodeSelector from "../../core/CountryCodeSelector/CountryCodeSelector";
import CustomMultiSelect, { Option } from "../../core/MultiSelect/multi-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as InfoIcon } from "../../assets/images/info.svg";
import { DataOption } from "./DataOptions";
import "react-phone-number-input/style.css";
import "./ExporterTest.css";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

interface Inputs {
  countryValue: string;
  cityValue: string;
  provinceValue: string;
}

interface Props {
  onComponentChange: () => void;
}

const ExporterTestInformation: React.FC<Props> = ({ onComponentChange }) => {
  const { t } = useTranslation();
  const { Sectors, countries, provincesByCountry, citiesByProvince } = DataOption()
  const [inputs, setInputs] = useState<Inputs>({
    countryValue: "",
    cityValue: "",
    provinceValue: "",
  });

  const handleChanges = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [userBusinessName, setBusinessName] = useState("");

  const validateFrm = () => {
    const newErrors: { [key: string]: string } = {};
    let selectAOption = t("exporterTest.information.selectAOption");

    newErrors.businessName =
      userBusinessName.length < 1
        ? t("exporterTest.information.businessName.error")
        : "";
    newErrors.countryValue = inputs.countryValue === "" ? selectAOption : "";
    newErrors.provinceValue = inputs.provinceValue === "" ? selectAOption : "";

    setErrors(newErrors);

    return (
      newErrors.countryValue === "" &&
      newErrors.businessName === "" &&
      newErrors.provinceValue === ""
    );
  };

  const handleSubmit = () => {
    let isValid = validateFrm();
    console.log("isValid ", isValid);
    if (isValid) {
      onComponentChange();
      window.scrollTo({ top: 0, behavior: "smooth" });
      
    }
  };

  const countriesByRegion = Object.entries(countries);
  const provinces = provincesByCountry[inputs.countryValue] || [];
  const cities = inputs.provinceValue
    ? citiesByProvince[inputs.provinceValue] || []
    : [];

  return (
    <div className="exporter-test-information__container exporter-test__read-only">
      <div className="exporter-test-information__container-main">
        <Formik
          initialValues={{
            businessName: "",
            countryValue: "",
            provinceValue: "",
            cityValue: "",
          }}
          onSubmit={(values) => {
          console.log(values);
          }}
        >
        <div className="exporter-test-information__inner">
          <div className="row exporter-test-information__row ">
            <div className="col-12 col-sm-12 col-md-6">
              <input
                type="text"
                className="exporter-test-information__textbox-general"
                placeholder={t("exporterTest.information.name.placeholder")}
                id="exporter-test-information__name"
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <input
                type="text"
                className="exporter-test-information__textbox-general"
                placeholder={t("exporterTest.information.lastname.placeholder")}
                id="exporter-test-information__lastname"
              />
            </div>
          </div>
          <div className="row exporter-test-information__row">
            <div className="col-12 col-sm-12 col-md-6">
              <CountryCodeSelector />
            </div>
          </div>
          <div className="exporter-test-information__sub-title">
            {t("exporterTest.information.subtitle")}
          </div>
          <div className="row exporter-test-information__row-wo-errors">
            <div className="col-12 col-sm-12 col-md-12">
              <CustomMultiSelect
                data={Sectors}
                placeholder={t("exporterTest.information.sectors.placeholder")}
                width="100%"
                
              />
            </div>
          </div>
          <div className="row exporter-test-information__row">
            <div className="col-12 col-sm-12 col-md-6">
              <input
                name="businessName"
                type="text"
                className="exporter-test-information__textbox-general"
                placeholder={t(
                  "exporterTest.information.businessName.placeholder"
                )}
                id="exporter-test-information__businessName"
                onChange={(e) => setBusinessName(e.target.value)}
              />
              <div
                className={`exporter-test-information__error-container${
                  errors.businessName ? " show" : ""
                }`}
              >
                <InfoIcon fill="#F8A0D2" />
                {"\u00A0"}
                <div className="exporter-test-information__error-message">
                  {errors.businessName}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <select
                name="countryValue"
                className="exporter-test-information__dropdown-general exporter-test__select-font"
                defaultValue={""}
                onChange={handleChanges}
                style={
                  inputs.countryValue !== ""
                    ? {
                        color: "var(--gray-300, #BBC2C7)",
                        fontFamily: "Barlow",
                        fontSize: "15.19px",
                      }
                    : undefined
                }
              >
                <option value="" selected disabled>
                  {t("exporterTest.information.countryValue.placeholder")}
                </option>
                {countriesByRegion.map(([region, countries]) => (
                  <optgroup
                    key={region}
                    label={region}
                    className="exporter-test__select-font"
                  >
                    {countries.map((country) => (
                      <option
                        key={country}
                        value={country}
                        className="exporter-test__select-font"
                      >
                        {country}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
              <div
                className={`exporter-test-information__error-container${
                  errors.countryValue ? " show" : ""
                }`}
              >
                <InfoIcon fill="#F8A0D2" />
                {"\u00A0"}
                <div className="exporter-test-information__error-message">
                  {errors.countryValue}
                </div>
              </div>
            </div>
          </div>
          <div className="row exporter-test-information__row">
            <div className="col-12 col-sm-12 col-md-6">
              <select
                name="provinceValue"
                className="exporter-test-information__dropdown-general exporter-test__select-font"
                defaultValue={""}
                onChange={handleChanges}
                style={
                  inputs.provinceValue !== "" ? { color: "#ECEEEF" } : undefined
                }
              >
                <option value="" selected disabled>
                  {t("exporterTest.information.provinceValue.placeholder")}
                </option>
                {provinces.map((province) => (
                  <option
                    key={province}
                    value={province}
                    className="exporter-test__select-font"
                  >
                    {province}
                  </option>
                ))}
              </select>
              <div
                className={`exporter-test-information__error-container${
                  errors.provinceValue ? " show" : ""
                }`}
              >
                <InfoIcon fill="#F8A0D2" />
                {"\u00A0"}
                <div className="exporter-test-information__error-message">
                  {errors.provinceValue}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <select
                name="cityValue"
                className="exporter-test-information__dropdown-general exporter-test__select-font"
                defaultValue={""}
                onChange={handleChanges}
                style={
                  inputs.cityValue !== "" ? { color: "#ECEEEF" } : undefined
                }
              >
                <option value="" selected disabled>
                  {t("exporterTest.information.cityValue.placeholder")}
                </option>
                {cities.map((city) => (
                  <option
                    key={city}
                    value={city}
                    className="exporter-test__select-font"
                  >
                    {city}
                  </option>
                ))}
              </select>
              <div
                className={`exporter-test-information__error-container${
                  errors.cityValue ? " show" : ""
                }`}
              >
                <InfoIcon fill="#F8A0D2" />
                {"\u00A0"}
                <div className="exporter-test-information__error-message">
                  {errors.cityValue}
                </div>
              </div>
            </div>
          </div>
          <div className="row exporter-test-information__row ">
            <div className="col-12 col-sm-12 col-md-6">
              <CountryCodeSelector />
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <input
                type="text"
                className="exporter-test-information__textbox-general"
                placeholder={t("exporterTest.information.website.placeholder")}
                id="exporter-test-information__Website"
              />
            </div>
          </div>
          <div className="row exporter-test-information__row">
            <div className="col-12 col-sm-12 col-md-6">
              <input
                type="text"
                className="exporter-test-information__textbox-general"
                placeholder={t("exporterTest.information.position.placeholder")}
                id="exporter-test-information__PositionHold "
              />
            </div>
          </div>
          <div className="exporter-test-information__footer">
            <div
              className="exporter-test-information__btn-start-test"
              onClick={handleSubmit}
            >
              <div className="exporter-test-information__btn-text-start-test">
                {t("exporterTest.information.button")}
              </div>
              <FontAwesomeIcon icon={faArrowRight} color="black" />
            </div>
          </div>
        </div>
        </Formik>
      </div>
    </div>
  );
};

export default ExporterTestInformation;
