import * as React from 'react';

type IconLockProps = React.SVGProps<SVGSVGElement>;

export const IconLock: React.FC<IconLockProps> = (props) => (
	<svg width={14} height={17} viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg"
	{...props} >
		<g clip-path="url(#clip0_5131_13519)">
			<path d="M4.5 5V6.5H9.5V5C9.5 3.61875 8.38125 2.5 7 2.5C5.61875 2.5 4.5 3.61875 4.5 5ZM2.5 6.5V5C2.5 2.51562 4.51562 0.5 7 0.5C9.48437 0.5 11.5 2.51562 11.5 5V6.5H12C13.1031 6.5 14 7.39687 14 8.5V14.5C14 15.6031 13.1031 16.5 12 16.5H2C0.896875 16.5 0 15.6031 0 14.5V8.5C0 7.39687 0.896875 6.5 2 6.5H2.5Z" fill="#ECEEEF"/>
		</g>
		<defs>
			<clipPath id="clip0_5131_13519">
			<rect width="14" height="16" fill="white" transform="translate(0 0.5)"/>
		</clipPath>
		</defs>
	</svg>

);


