import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import "react-phone-number-input/style.css";
import print from "../../assets/images/print-solid.png";
import pdf from "../../assets/images/file-pdf-solid.png";
import "./CubicajeResumen.css";
import { IFormData } from "./Cubicaje";
import { PdfDownload } from "../PdfDownload/PdfDownload";
import { IconInstagram } from "../Icons/IconInstagram";
import { IconLinkedin } from "../Icons/IconLinkedin";
import { formatText } from "../../utils/formatText";
import { downloadPdf } from "../../utils/pdfUtils";

interface Props {
  resetFormClick: () => void;
  formData: IFormData;
}

const CubicajeResumen: React.FC<Props> = ({ resetFormClick, formData }) => {
  const { t } = useTranslation();
const altoStep2 = Number(formData.altoStep2);
const anchoStep2 = formData.anchoStep2;
const filasMaximoPeso = formData.FilasMaximoPeso;
const kCajasXFila = Number(formData.KcajasXFila);
const kPallet = formData.kPallet || 1;
const largoStep2 = formData.largoStep2;
const pesoFilaCajas = formData.PesoFilaCajas;
const pesoStep2 = Number(formData.pesoStep2) || 1; // Evitar división por 0 o valores inválidos
const KFilaMaxAltura = formData.KFilaMaxAltura;
const cantFilas = formData.KFilaMaxAltura;
let FilasMaximoAltura = formData.FilasMaximoAltura;
const AlturaMaxima = Number(formData.AlturaMaxima) || 0;
const PesoMaximo = Number(formData.PesoMaximo) || 0;
const PesoPallet = Number(formData.palletData.pesoPalletUnidad) || 0;
const AlturaPallet = Number(formData.palletData.alto) || 0;


console.log("🚀 ~ formData:", formData)

let cajasTotales = Number(FilasMaximoAltura) * kCajasXFila;

// Calcular la cantidad máxima por altura con la restricción del contenedor
let CantidadMaximaPorAltura = AlturaMaxima > 0 
    ? Math.floor((Math.min(AlturaMaxima, formData.contenedor.puertasAbiertasAlto) - AlturaPallet) / altoStep2) * kCajasXFila
    : cajasTotales;

// Calcular la cantidad máxima por peso
let CantidadMaximaPorPeso = PesoMaximo > 0 
    ? Math.floor((PesoMaximo - PesoPallet) / pesoStep2) 
    : cajasTotales;

// Determinar el total de cajas con las restricciones aplicadas
cajasTotales = Math.max(0, Math.min(CantidadMaximaPorAltura, CantidadMaximaPorPeso));

// Calcular las filas necesarias
FilasMaximoAltura = Math.ceil((cajasTotales || 0) / kCajasXFila);

// Calcular la altura total asegurando que no supere el límite del contenedor
let alturaTotal = Math.max(0, (Number(FilasMaximoAltura) || 0) * (Number(altoStep2) || 0) + AlturaPallet);

// Si la altura total supera el límite del contenedor, recalcular cajasTotales
if (alturaTotal >= formData.contenedor.puertasAbiertasAlto) {
    let nuevasFilas = Math.floor((formData.contenedor.puertasAbiertasAlto - AlturaPallet) / altoStep2);
    cajasTotales = Math.max(0, nuevasFilas * kCajasXFila);
    FilasMaximoAltura = nuevasFilas;
    alturaTotal = Math.max(0, FilasMaximoAltura * altoStep2 + AlturaPallet); // Recalcular alturaTotal
}

// Calcular el peso total con el nuevo número de cajas
let pesoTotal = Math.max(0, cajasTotales * pesoStep2 + PesoPallet);




  const items = [
    {
      id: 0,
      title: Number(kPallet) * cajasTotales,
      description: t('cubicaje.resume.box_1.title'),
    },
    {
      id: 2,
      title: `${pesoTotal * Number(kPallet)}${
        formData.palletData.unidadPeso
      }`,
      description: t('cubicaje.resume.box_2.title'),
    },
    {
      id: 3,
      title: `${alturaTotal}${formData.palletData.unidadMedida}`,
      description: t('cubicaje.resume.box_3.title'),
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
    <div className="mainContainerCubicajeResumen">
      <h2>{t('cubicaje.resume.name')}</h2>
      <h3>{formData.referenceName}</h3>
      <div className="squareItemsContainer">
        {items.map((item) => (
          <div key={item.id} className="itemContainer">
            <h5>{item.title}</h5>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
      <div className="descriptionMainContainer">
        <div className="rowContainer">
          <h6>{t('cubicaje.resume.item_1.title')}</h6>
          <div className="middleColumnText">
            <p>{formData.palletData.titlePallet}</p>
          </div>
          <div></div>
        </div>
        <div className="rowContainer">
          <h6>{t('cubicaje.resume.item_2.title')}</h6>
          <div className="middleColumnText">
            <p>
            {t('cubicaje.resume.item_2.subtitle_1')}: {anchoStep2} x {largoStep2} x {altoStep2}{" "}
              {formData.palletData.unidadMedida}
            </p>
          </div>
          <div className="rightColumn">
            <p>
              {t('cubicaje.resume.item_2.subtitle_2')}: {pesoStep2}
              {formData.palletData.unidadPeso}
            </p>
          </div>
        </div>
        <div className="rowContainer">
          <h6>{t('cubicaje.resume.item_3.title')}</h6>
          <div>
            <div className="middleColumnText">
              <p>{t('cubicaje.resume.item_3.subtitle_1')}: {kCajasXFila } </p>
              <p>
                {t('cubicaje.resume.item_3.subtitle_2')}: {cajasTotales}
              </p>
              <p>
              {t('cubicaje.resume.item_3.subtitle_3')}: {pesoTotal}
                {formData.palletData.unidadPeso}
              </p>
            </div>
          </div>
          <div className="rightColumn">
            <p>{t('cubicaje.resume.item_3.subtitle_4')}: {FilasMaximoAltura} </p>
            <p>
            {t('cubicaje.resume.item_3.subtitle_5')}: {alturaTotal}
              {formData.palletData.unidadMedida}
            </p>
          </div>
        </div>
        <div className="rowContainer">
          <h6>{t('cubicaje.resume.item_4.title')}</h6>
          <div className="middleColumnText">
            <p>{formData.contenedor?.titlePallet ?? ""}</p>
          </div>
          <div className="rightColumn">
            <p>{t('cubicaje.resume.item_4.subtitle_1')}: {kPallet}</p>
          </div>
        </div>
      </div>
      <div className="row frame-111-M3B">
        <div className="col-sm-3 botonesCubicajeContainer">
          <img className="print-solid-k5K" src={print} alt="print formulario" />
          <div className="buttonsCubicaje">{t('cubicaje.resume.print')}</div>
        </div>
        <div className="col-sm-3 botonesCubicajeContainer">
          <img
            className="file-pdf-solid-kDj"
            src={pdf}
            alt="downliad formulario"
          />
          <div className="buttonsCubicaje" onClick={() => downloadPdf('divToPrint', 'cubicajeCalculator.pdf')}  >{t('cubicaje.resume.download')}</div>
        </div>
        <div className="newCalButton" onClick={resetFormClick}>
          <div className="btn-cubicajeNuevo-text">{t('cubicaje.resume.button')}</div>
        </div>
      </div>
    </div>
    <PdfDownload referenceName={formData.referenceName} headerTitle={t('cubicaje.header.title')} className="pdf-cubicaje">
      <div className="pdf_a4_content">
        <h3 className="pdf_a4_content_title">{t('cubicaje.resume.name')}</h3>
        <p className="pdf_a4_content_subtitle">{formData.referenceName}</p>
        
        <div className="pdf_a4_table">
            {items.map((item) => (
              <div key={item.id} className="pdf_a4_table_box pdf_a4_table_box_feature">
                <span className="pdf_a4_table_box_num">{item.title}</span>
                <span className="pdf_a4_table_box_text">{item.description}</span>
              </div>
            ))}
        </div>
        
        <div className="pdf_a4_details_content">
            <div className="pdf_a4_details">
                <div className="pdf_a4_details_col">
                    <h4 className="pdf_a4_details_title">{t('cubicaje.resume.item_1.title')}</h4>
                </div>
                <div className="pdf_a4_details_col">
                    <p className="pdf_a4_details_text">{formData.palletData.titlePallet}</p>
                    
                </div>
                <div className="pdf_a4_details_col"></div>
            </div>
            <div className="pdf_a4_details">
                <div className="pdf_a4_details_col">
                    <h4 className="pdf_a4_details_title">{t('cubicaje.resume.item_2.title')}</h4>
                </div>
                <div className="pdf_a4_details_col">
                    <p className="pdf_a4_details_text"> <span>{t('cubicaje.resume.item_2.subtitle_1')} {anchoStep2} x {largoStep2} x {altoStep2}{" "}
                    {formData.palletData.unidadMedida}</span></p>
                    
                </div>
                <div className="pdf_a4_details_col">
                    <p className="pdf_a4_details_text"> <span>{t('cubicaje.resume.item_2.subtitle_2')} :</span> <span>{pesoStep2}
                    {formData.palletData.unidadPeso}</span></p>
                </div>
            </div>
            {/*  */}
           
            <div className="pdf_a4_details">
                <div className="pdf_a4_details_col">
                    <h4 className="pdf_a4_details_title">{t('cubicaje.resume.item_3.title')}</h4>
                </div>
                <div className="pdf_a4_details_col">
                    <p className="pdf_a4_details_text"><span>{t('cubicaje.resume.item_3.subtitle_1')} :</span> <span>{kCajasXFila}</span></p>
                    <p className="pdf_a4_details_text">
                      <span>{t('cubicaje.resume.item_3.subtitle_2')}</span> : <span>{cajasTotales}</span>
                    </p>
                    <p className="pdf_a4_details_text">
                    <span>{t('cubicaje.resume.item_3.subtitle_3')}</span> : <span>{pesoTotal}</span>
                      <span>{formData.palletData.unidadPeso}</span>
                    </p>
                </div>
                <div className="pdf_a4_details_col">
                <p className="pdf_a4_details_text"> <span>{t('cubicaje.resume.item_3.subtitle_4')}</span> : <span>{FilasMaximoAltura}</span></p>
                <p className="pdf_a4_details_text"> 
                <span>{t('cubicaje.resume.item_3.subtitle_5')}</span>: <span>{alturaTotal}
                {formData.palletData.unidadMedida}</span>
                </p>
                   
                </div>
            </div>
           
            <div className="pdf_a4_details">
                <div className="pdf_a4_details_col">
                    <h4 className="pdf_a4_details_title">{t('cubicaje.resume.item_4.title')}</h4>
                </div>
                <div className="pdf_a4_details_col">
                    <p className="pdf_a4_details_text"> <span>{formData.contenedor?.titlePallet ?? ""}</span></p>
                </div>
                <div className="pdf_a4_details_col">
                    <p className="pdf_a4_details_text"> <span>{t('cubicaje.resume.item_4.subtitle_1')}</span> : <span>{kPallet}</span></p>
                </div>
            </div>
        </div>
      </div>

      <div className="pdf_a4_terms">

        <h4 className="pdf_a4_terms_title">{t('cubicaje.download.title')}</h4>
        <div className="pdf_a4_terms_content" dangerouslySetInnerHTML={{ __html: formatText(t('cubicaje.download.text_1')) }} ></div>

      </div>

      <div className="pdf_a4_alert">
        <div className="icon">
        <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5640_372)">
            <path d="M13.6093 10.2382V8.53484H8.90097C8.87741 8.48315 8.85254 8.43145 8.82898 8.38105C10.1563 7.41048 11.4835 6.4412 12.8108 5.47062C12.8017 5.41893 12.7925 5.36853 12.7833 5.31683H10.4586C11.4835 4.2377 12.4273 3.24386 13.4522 2.16602C12.9993 2.14405 12.5896 2.12466 11.9692 2.09494C12.9326 1.33373 13.7637 0.678497 14.6224 0C15.4706 0.672035 16.29 1.3221 17.2456 2.07943C16.6579 2.11303 16.2547 2.1363 15.7887 2.16214C16.8136 3.20121 17.7966 4.19763 18.8687 5.28194H16.4563C16.434 5.33492 16.4118 5.38662 16.3895 5.43961C17.7207 6.41276 19.0532 7.38463 20.5376 8.47023H15.6722V10.224H22.2667C22.2851 10.4837 22.3073 10.6505 22.3073 10.8172C22.3086 13.8116 22.3086 16.8073 22.3047 19.8018C22.3047 20.0835 22.4107 20.4428 21.9107 20.4428C20.1488 20.4389 18.387 20.4415 16.5283 20.4415C17.3202 19.7901 18.0558 19.237 18.7299 18.618C19.4773 17.9304 19.9944 17.0917 20.1659 16.0707C20.3085 15.2293 20.0742 14.6594 19.315 14.2239C17.9249 13.4252 16.3686 13.1887 14.8057 13.0362C11.9037 12.7532 8.99783 12.5218 6.09456 12.253C5.73067 12.2194 5.36809 12.1108 5.01991 11.9945C4.52774 11.8304 4.60104 11.5215 4.94922 11.307C5.29348 11.0937 5.6914 10.9477 6.08409 10.8288C8.09334 10.2201 10.1615 10.1878 12.2388 10.2369C12.6721 10.2472 13.1067 10.2382 13.6106 10.2382H13.6093Z" fill="#387E22"/>
            <path d="M0 10.2638H5.02247C4.36538 10.4086 3.73053 10.5365 3.10092 10.6929C2.82866 10.7601 2.52105 10.8299 2.31947 11.0005C2.04197 11.2357 1.66892 11.5704 1.67285 11.8573C1.67678 12.1533 2.03019 12.6146 2.32471 12.7064C3.35486 13.0243 4.41512 13.2763 5.48192 13.4379C7.24377 13.7067 9.02265 13.8695 10.7884 14.1151C11.2112 14.1732 11.6353 14.3335 12.0189 14.5248C13.0215 15.0262 13.2165 15.8779 12.3605 16.5758C11.4848 17.2905 10.507 17.9793 9.45722 18.3709C7.36551 19.1502 5.21489 19.8028 3.04464 20.3366C2.1035 20.5679 1.05502 20.3754 0 20.3754V10.2612L0 10.2638Z" fill="#387E22"/>
            </g>
            <defs>
            <clipPath id="clip0_5640_372">
            <rect width="22.3125" height="20.4531" fill="white"/>
            </clipPath>
            </defs>
        </svg>

        </div>
        <p className="pdf_a4_alert_text">{t('cubicaje.download.alert_text')}</p>
      </div>

      <div className="pdf_a4_footer pdf_a4_footer--col">
            <div className="pdf_a4_footer_left">
              <span className="pdf_a4_footer_link">https://www.exportopia.eu/</span>
              <span className="pdf_a4_footer_ig"><IconInstagram /> exportopia</span>
              <span className="pdf_a4_footer_in"><IconLinkedin /> Exportopia</span>
            </div>
            <div className="pdf_a4_footer_paginator">
              <span className="pdf_a4_footer_paginator_text">{t('price_comparator.download.pagination.page')}</span>
              <span className="pdf_a4_footer_paginator_actual">{ 1}</span>
              <span className="pdf_a4_footer_paginator_div"> {t('price_comparator.download.pagination.of')} </span>
              <span className="pdf_a4_footer_paginator_lenght">{ 1}</span>
            </div>
          </div>
    </PdfDownload>
    </>
  );
};
  
  
  
export default CubicajeResumen;
