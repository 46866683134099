import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SuscripcionesPlanes } from '../../components/SuscripcionesPlanes/SuscripcionesPlanes';
import './suscripciones.css';
import { IconCalcPrecios } from '../../components/Icons/IconCalcPrecios';
import { IconCompPrecios } from '../../components/Icons/IconCompPrecios';
import { IconPack } from '../../components/Icons/IconPack';
import { IconPending } from '../../components/Icons/IconPending';
import CustomToolTip from '../../core/CustomToolTip/CustomToolTip';
import { ProductChildViewModel, ProductViewModel, ProductViewModelCollectionResult, SubscriptionUserViewModel, postApiSubscription, postApiSubscriptionProducts } from '../../api';
import CircularLoading from '../../shared/CircularLoading/CircularLoading';
import { usePacksUser } from './useUserPacks';


export const SuscripcionesPage = () => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [packActive, setPackActive] = useState<boolean>(false);
    const [products, setProducts] = useState<ProductViewModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true); // Estado para controlar el loading
    const [activeTab, setActiveTab] = useState<string>(""); // Estado para el tab activo
	const [childProducts, setChildProducts] = useState<ProductChildViewModel[]>([]); // Estado para los childProducts

    const [packs, setPacks] = useState<SubscriptionUserViewModel[]>([]);

    const userId = localStorage.getItem('userId') || "";
    const productParentName = activeTab;
    
    // Find the product ID based on the name
    const selectedProduct = products.find(product => product.name === productParentName);
    const productParentId = selectedProduct?.id ?? -1;

    const { packsUser, loadingUser, error, refetch } = usePacksUser(userId, productParentId);
    
    const productIdsUses = packsUser.map(item => item.product.id);

   


    const handleButtonClick = () => {
        setIsOpen(true); // Asegúrate de abrir el pop-up al hacer clic
        // setPackActive(true);
    };

    const handleClosePopUp = () => {
        setIsOpen(false); // Permite que el pop-up se cierre
    };

	const handleTabClick = (productName: string) => {
		setActiveTab(productName); // Cambia el tab activo

		 // Encontramos el producto que corresponde al tab seleccionado
		 const selectedProduct = products.find(product => product.name === productName);
		 
		 if (selectedProduct) {
			 // Asignamos los childProducts del producto seleccionado
			 setChildProducts(selectedProduct.childProducts || []);
		 }
	};

    useEffect(() => {
        postApiSubscriptionProducts()
            .then((data: ProductViewModelCollectionResult) => {
                if (data.results) {
                    

                    setProducts([...data.results]);
                    setLoading(false); // Termina el loading

                    const firstProduct = data.results[0];
                    const firstName = firstProduct?.name ?? "";

                    setActiveTab(firstName);

                    // Luego de actualizar `activeTab`, establece los `childProducts`
                    setChildProducts(firstProduct?.childProducts ?? []);
                    
                }
            })
            .catch(error => console.error("Error al cargar los productos:", error));
    }, []);
    

    const calculateDaysRemaining = (expirationDate: string) => {
        const today = new Date();
        const expDate = new Date(expirationDate);
        const diffTime = expDate.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays > 0 ? `${diffDays} ${t('suscripcion.lists_packs.days')}` : t('suscripcion.lists_packs.expired');
    };

        
    if (loading ) {
        return (<CircularLoading />);
    }
    if (error) return <p>{error}</p>;

    return (
        <>
            <div className="suscripciones_page">
                <h2 className="title_suscripciones">{t('suscripcion.title')}</h2>
                <p className="desc_suscripciones">{t('suscripcion.description')}</p>
                <div className="container_suscripciones">
                    <div className="suscripciones_head">
                        <div className="suscripciones_head_left">
                            {products.map(product => (
                                product.name === "Price Calculator" || product.name === "Price Comparator " ? (
                                    <div
                                        key={product.id}
                                        className={`suscripciones_head_item ${activeTab === product.name ? 'suscripciones_head_item--active' : ''}`}
                                        onClick={() => handleTabClick(product.name ?? "")} // Asigna la función al clic
                                    >
                                        <div className="suscripciones_head_icon">
                                            {product.name === "Price Calculator" ? <IconCalcPrecios /> : <IconCompPrecios />}
                                        </div>
                                        <span className="suscripciones_head_text">
                                            {t(product.name === "Price Calculator" ? 'suscripcion.title_calculadora' : 'suscripcion.title_comparador')}
                                        </span>
                                    </div>
                                ) : null
                            ))}
                        </div>
                        <div className="suscripciones_head_right">
                            <button
                                className="suscripciones_head_button"
                                onClick={handleButtonClick}
                                type="button">
                                {t('suscripcion.button')}
                            </button>
                        </div>
                    </div>
                    
                    
                    {loadingUser ? 
                     <div className="suscripciones__loading">
                         <CircularLoading />
                     </div>
                    :
                    packsUser.length === 0 ? (
                        <div className="suscripciones_empty">
                            <h3 className="suscripciones_empty_title">{t('suscripcion.empty_packs.title')}</h3>
                            <span className="suscripciones_empty_text">
                                {t('suscripcion.empty_packs.description')}
                            </span>
                            <button
                                className="suscripciones_empty_button"
                                onClick={handleButtonClick}
                                type="button">
                                {t('suscripcion.empty_packs.button')}
                            </button>
                        </div>
                    ) : (
                        <div className="table_packs_overflow">
                            <div className="table_packs">
                                <div className="table_packs_row table_packs_row_head">
                                    <div className="table_packs_col table_packs_col_icon"></div>
                                    <div className="table_packs_col table_packs_col_package">
                                        <span className="table_packs_col_title">{t('suscripcion.table.title_1')}</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_total">
                                        <span className="table_packs_col_title">{t('suscripcion.table.title_2')}</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_date">
                                        <span className="table_packs_col_title">{t('suscripcion.table.title_3')}</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_expires">
                                        <span className="table_packs_col_title">{t('suscripcion.table.title_4')}</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_remaining">
                                        <span className="table_packs_col_title">{t('suscripcion.table.title_5')}</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_lkn"></div>
                                </div>

                                {packsUser.map((pack) => {
                                    const subscriptionState = pack.subscriptionState || "unknown";
                                    const isPending = subscriptionState === "Pending";
                                    const isActive = subscriptionState === "Active";
                                    const isExpired = subscriptionState === "Expired";
                                    const isUsed = subscriptionState === "Used";

                                    return (
                                        <div
                                            key={pack.id}
                                            className={`table_packs_row table_packs_row_filled ${subscriptionState.toLowerCase()} `}
                                        >
                                            <div className="table_packs_col table_packs_col_icon">
                                                <div className="table_packs_icon_circle">
                                                    {isPending ? <IconPending /> : <IconPack className="table_packs_icon_svg" />}
                                                </div>
                                            </div>
                                            <div className="table_packs_col table_packs_col_package">
                                                <span className="table_packs_package">{pack.product?.name}</span>
                                            </div>
                                            <div className="table_packs_col table_packs_col_total">
                                                <span className="table_packs_total">{pack.product?.uses} {t('suscripcion.lists_packs.calculations')}</span>
                                            </div>
                                            <div className="table_packs_col table_packs_col_date">
                                                {isPending ? (
                                                    <CustomToolTip
                                                        title=""
                                                        tooltipText={t('suscripcion.lists_packs.payment_status_tooltip')}
                                                    >
                                                        <span className="table_packs_date">{t('suscripcion.lists_packs.payment_status')}</span>
                                                    </CustomToolTip>
                                                ) : (
                                                    <span className="table_packs_date">
                                                        {new Date(pack.dateOrder || "").toLocaleDateString(i18n.language, {
                                                            day: "2-digit",
                                                            month: "long",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                        })}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="table_packs_col table_packs_col_expires">
                                                {isActive || isExpired || isUsed  ? (
                                                    <span className="table_packs_expires">
                                                        {pack.subscriptionExpirationDate && calculateDaysRemaining(pack.subscriptionExpirationDate)}
                                                    </span>
                                                ) : null}
                                            </div>
                                            <div className="table_packs_col table_packs_col_remaining">
                                                {(isActive || isExpired || isUsed ) && <span className="table_packs_remaining">{pack.product?.uses} {t('suscripcion.lists_packs.available_uses')}</span>}
                                            </div>
                                            <div className="table_packs_col table_packs_col_lkn"></div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                </div>
            </div>
            <SuscripcionesPlanes
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onClose={handleClosePopUp}
                data={childProducts}
                productIdsUses={productIdsUses}
                refetch={refetch}
                productParentName={productParentName}
            />
        </>
    );
};


{/* Lista */}
                                {/* <div className="table_packs_row table_packs_row_filled pending">
                                    <div className="table_packs_col table_packs_col_icon">
                                        <div className="table_packs_icon_circle">
                                            <IconPending />
                                        </div>
                                    </div>
                                    <div className="table_packs_col table_packs_col_package">
                                        <span className="table_packs_package">Promo</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_total">
                                        <span className="table_packs_total">2 cálculos</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_date">
                                        <CustomToolTip
                                            title=""
                                            tooltipText="Revisa tu bandeja de entrada y sigue los pasos"
                                        >
                                            <span className="table_packs_date">Pendiente de pago</span>
                                        </CustomToolTip>
                                    </div>
                                    <div className="table_packs_col table_packs_col_expires"></div>
                                    <div className="table_packs_col table_packs_col_remaining"></div>
                                    <div className="table_packs_col table_packs_col_lkn"></div>
                                </div>
                                <div className="table_packs_row table_packs_row_filled active">
                                    <div className="table_packs_col table_packs_col_icon">
                                        <div className="table_packs_icon_circle">
                                            <IconPack className="table_packs_icon_svg" />
                                        </div>
                                    </div>
                                    <div className="table_packs_col table_packs_col_package">
                                        <span className="table_packs_package">Estádar</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_total">
                                        <span className="table_packs_total">20 cálculos</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_date">
                                        <span className="table_packs_date"> 31 de enero a las 15:00</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_expires">
                                        <span className="table_packs_expires">Quedan 364 días</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_remaining">
                                        <span className="table_packs_remaining">3 restantes</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_lkn"></div>
                                </div>

                                <div className="table_packs_row table_packs_row_filled ">
                                    <div className="table_packs_col table_packs_col_icon">
                                        <div className="table_packs_icon_circle">
                                            <IconPack className="table_packs_icon_svg" />
                                        </div>
                                    </div>
                                    <div className="table_packs_col table_packs_col_package">
                                        <span className="table_packs_package">Mega</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_total">
                                        <span className="table_packs_total">2 cálculos</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_date">
                                        <span className="table_packs_date">2 de enero a las 15:00</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_expires">
                                        <span className="table_packs_expires">Vencido</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_remaining">
                                        <span className="table_packs_remaining">3 restantes</span>
                                    </div>
                                    <div className="table_packs_col table_packs_col_lkn"></div>
                                </div> */}
