/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEventHandler, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg'
import * as DO from './DataOptions';

import './PriceCalculator.css';
import CustomMessage from "../../core/CustomMessage/CustomMessage";
import { formatNumber, numberRegex } from "../../utils/helpers";
import { useVideo } from "../../context/VideoContext";

import { PortBriefViewModel, getApiContainerType } from "../../api";

import { getApiPortsCountries, getApiTablesTableName } from '../../api/interconectar-api'
import CircularLoading from "../../shared/CircularLoading/CircularLoading";
import { GroupedPorts, Port } from "./PriceCalculator.types";
import i18n from "../../i18n";
import IconError from "../Icons/IconError";
import { string } from "yup";



const PriceCalculatorStepTwo = ({ handleClickOpen, handleContinueClick }: { handleClickOpen: MouseEventHandler<HTMLDivElement>, handleContinueClick: () => void }) => {
    const { t } = useTranslation();
    const { setVideoName } = useVideo();
    const [formTwoValues, setFormTwoValues] = useState<any>();
    const [transportation, setTransportation] = useState('');
    const [validRange, setValidRange] = useState(true);
    const [containers, setContainers] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [groupedPorts, setGroupedPorts] = useState<GroupedPorts[]>([]);
    const [loadingPorts, setLoadingPorts] = useState(false);

    


    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm();

    

    const handleChangeMeansTransportation = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setTransportation(e.target.value);
        setFormTwoValues({ portDestinationId: !!formTwoValues?.portDestinationId ? formTwoValues?.portDestinationId : '', meansTransportation: e.target.value })
        localStorage.removeItem('formTwo')
        reset({ portDestinationId: !!formTwoValues?.portDestinationId ? formTwoValues?.portDestinationId : '', containerId: '', internationalFreightValueUsd: '' })
    };

    const onSubmit = (data: any) => {
        if (!!data.portDestinationId) {
            if (data.meansTransportation === 'maritimo') {
                const maritimo = {
                    portDestinationId: data.portDestinationId,
                    meansTransportation: 'Maritime',
                    containerId: data.containerId,
                    internationalFreightValueUsd: data.internationalFreightValueUsd,
                }
                localStorage.setItem('formTwo', JSON.stringify(maritimo))
            }

            if (data.meansTransportation === 'multimodal') {
                const multimodal = {
                    portDestinationId: data.portDestinationId,
                    meansTransportation: 'Multimodal',
                    internationalFreightValueUsd: data.internationalFreightValueUsd,
                }
                localStorage.setItem('formTwo', JSON.stringify(multimodal))
            }

            if (data.meansTransportation === 'aereo') {
                const aereo = {
                    portDestinationId: data.portDestinationId,
                    meansTransportation: 'Air',
                    airTotalWeightPlusPackaging: data.airTotalWeightPlusPackaging,
                    airTarifCode: data.airTarifCode,
                    internationalFreightValueUsd: data.internationalFreightValueUsd,
                }
                localStorage.setItem('formTwo', JSON.stringify(aereo))
            }

            handleContinueClick()
        }
    }

    useEffect(() => {
        const formTwo = localStorage.getItem('formTwo') ? JSON.parse(localStorage.getItem('formTwo')!) : ''
        if (Object.keys(formTwo).length) setFormTwoValues(formTwo)
    }, [])

    useEffect(() => {
        if (formTwoValues) {
            reset((prev) => ({ ...prev, ...formTwoValues }));
        }
    }, [formTwoValues, reset]);

    useEffect(() => {
        if (!!formTwoValues?.meansTransportation) {
            setValue('meansTransportation', formTwoValues?.meansTransportation)
            setTransportation(formTwoValues?.meansTransportation);
        }
    }, [formTwoValues?.meansTransportation])

    const validateRange = !!errors?.internationalFreightValueUsd && (errors?.internationalFreightValueUsd.type === "min" || errors?.internationalFreightValueUsd.type === "max")

    useEffect(() => {
        transportation === 'maritimo' || transportation === 'multimodal'
            ? setVideoName('secondBarcoVideo')
            : transportation === 'aereo'
                ? setVideoName('secondAvionVideo')
                : setVideoName('')
    }, [transportation])

    useEffect(() => {
        const fetchContainers = async () => {
            try {
                // Llama a la API

                const data = await getApiContainerType();
                if (data.results) {
                    setContainers([...data.results]);
                }

            } catch (err) {
                setError(t('price_calculator.step_2.error_message_fetch'));
            } finally {
                setLoading(false);
            }
        };

        fetchContainers();
    }, []); // Se ejecuta al montar el componente

    
    
    
    useEffect(() => {
      const loadPorts = async () => {
        try {
          setLoadingPorts(true);
          const fetchedPorts = await fetchPorts();
          setGroupedPorts(fetchedPorts);
        } catch (error: any) {
            console.error("Error fetching ports:", error);
            setError(t('price_calculator.step_2.error_message_fetch'));
        } finally {
          setLoadingPorts(false);
        }
      };
    
      loadPorts();
    }, []);
    
   
      
    const fetchPorts = async (): Promise<GroupedPorts[]> => {
        try {

            
            const response = await getApiPortsCountries();
            if (!response || !response.results) return [];
        
            return response.results
            .map((country) => ({
                country: country.name ?? "Desconocido",
                ports: country.ports?.map((port) => ({
                id: port.id,
                name: port.name,
                descriptionEn: port.descriptionEn ?? "", 
                descriptionEs: port.descriptionEs ?? "", 
                })) ?? [],
            }))
            .filter((group) => group.ports.length > 0); // Filtrar países sin puertos
        } catch (error: any) {
            console.error("Error fetching ports:", error);
            setError(t('price_calculator.step_2.error_message_fetch'));
            return [];
        }
    };

    useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);
      
      

    

    if (loadingPorts) {
        return <CircularLoading />;
    }

    if (loading) return <CircularLoading />;
    
    
    if (error) {
		return <div className='errorcalculator'>
			<div className='errorcalculator__icon'>
				<IconError />	
			</div>
			
			<p className='errorcalculator__txt'>{error}</p>
		</div>;
	}


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="exporter-test__read-only">
            <div className='price-calculator__container-main'>
                <div className="price-calculator___inner">
                    <div className="price-calculator___container">
                        <div className="row">
                            <div className='col-12'>
                                <span className='price-calculator__container-title' style={{ marginBottom: validateRange ? "0" : "24px"}}>{t('price_calculator.step_2.subtitle')}</span>
                                <CustomToolTip
                                    title=""
                                    tooltipText={t('price_calculator.step_2.subtitle_tooltip')}
                                >
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        style={{fontSize: '15px', marginLeft: '8px'}}
                                    />
                                </CustomToolTip>
                            </div>
                        </div>

                        { !validRange &&
                            <CustomMessage
                                background={"#F4B400"}
                                width='100%'
                                textMessage={`Please note that current international freight rates range from ${DO?.flete_range?.rangeTo!} to ${DO.flete_range.rangeFrom}.`}
                            />
                        }

                        <div className='row price-calculator__row mb-4'>
                            <div className='col-12 col-sm-12 col-md-6'>
                                <div className='price-calculator__field-with-icon'>

                                <select
                                {...register("portDestinationId", { required: true })}
                                value={formTwoValues?.portDestinationId || ""}
                                onChange={(e) => {
                                    setFormTwoValues({
                                    ...formTwoValues,
                                    portDestinationId: e.target.value,
                                    });
                                }}
                                className="price-calc__dropdown-general price-calculator__select-font"
                                >
                                <option value="" disabled>
                                    {t("price_calculator.step_2.portDestinationId.placeholder")}
                                </option>

                                {loadingPorts ? (
                                    <option disabled>Cargando...</option>
                                ) : groupedPorts.length > 0 ? (
                                    groupedPorts.map((group) => (
                                    <optgroup key={group.country} label={group.country}>
                                        {group.ports.map((port) => (
                                        <option key={port.id} value={port.id} className="price-calculator__select-font">
                                            {i18n.language === "es"
                                            ? port.descriptionEs || "Sin descripción"
                                            : port.descriptionEn || "Sin descripción"}
                                        </option>
                                        ))}
                                    </optgroup>
                                    ))
                                ) : (
                                    <option disabled>No se encontraron puertos</option>
                                )}
                                </select>
     
                                    
                                    <div style={{ marginLeft: "8px"}}>
                                        <CustomToolTip
                                            title=""
                                            tooltipText={t('price_calculator.step_2.portDestinationId.tooltip')}
                                        >
                                        <FontAwesomeIcon
                                            icon={faCircleQuestion}
                                            className="font-size: 18px"
                                        />
                                        </CustomToolTip>
                                    </div>                                    
                                </div>
                                {!!errors?.portDestinationId && 
                                    <div className="error-container">
                                        <InfoIcon fill="#F8A0D2" />
                                        <div className="error-message">{t('price_calculator.step_2.error_message')}</div>
                                    </div>
                                }
                            </div>
                            <div className='col-12 col-sm-12 col-md-6'>
                                <select
                                    {...register('meansTransportation', { required: true })}
                                    value={!!formTwoValues?.meansTransportation ? formTwoValues?.meansTransportation : null}
                                    onChange={handleChangeMeansTransportation}
                                    className="price-calc__dropdown-general price-calculator__select-font"
                                >
                                    <option value="" disabled selected hidden>{t('price_calculator.step_2.meansTransportation.placeholder')}</option>
                                    <option value="maritimo">Marítimo</option>
                                    <option value="multimodal">Multimodal</option>
                                    <option value="aereo">Aéreo</option>
                                </select>
                                {!!errors?.meansTransportation && 
                                    <div className="error-container">
                                        <InfoIcon fill="#F8A0D2" />
                                        <div className="error-message">{t('price_calculator.step_2.error_message')}</div>
                                    </div>
                                }
                            </div>
                        </div>
                        { transportation === 'aereo' &&
                            <div className="row price-calculator__row mb-4">
                                <div className='col-12 col-sm-12 col-md-6 label-container'>
                                    <label className="label-input">{t('price_calculator.step_2.airTotalWeightPlusPackaging.placeholder')}</label>
                                    <input
                                        {...register('airTotalWeightPlusPackaging', { required: true })}
                                        type="text"
                                        className="price-calculator__textbox-general-col2"
                                        value={!!formTwoValues?.airTotalWeightPlusPackaging ? formTwoValues?.airTotalWeightPlusPackaging : ''}
                                        onChange={ (e) => {
                                            if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                setFormTwoValues({...formTwoValues, airTotalWeightPlusPackaging: formatNumber(e.target.value)})
                                            }
                                        }}
                                    />
                                    {!!errors?.airTotalWeightPlusPackaging && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_2.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-sm-12 col-md-6 label-container'>
                                    <label className="label-input">{t('price_calculator.step_2.airTarifCode.placeholder')}</label>
                                    <input
                                        type="text"
                                        className="price-calculator__textbox-general-col2"
                                        value={!!formTwoValues?.airTarifCode ? formTwoValues?.airTarifCode : ''}
                                        {...register('airTarifCode', { required: true })}
                                        onChange={ (e) => {
                                            if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                setFormTwoValues({...formTwoValues, airTarifCode: formatNumber(e.target.value)})
                                            }
                                        }}
                                    />
                                    {!!errors?.airTarifCode && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_2.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        <div className="row price-calculator__row mb-4">
                            { transportation === 'maritimo' &&
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon'>
                                       
                                    <select
                                        {...register('containerId', { required: true })}
                                        value={formTwoValues?.containerId || ''}
                                        onChange={(e) =>
                                            setFormTwoValues({ ...formTwoValues, containerId: e.target.value })
                                        }
                                        className="price-calc__dropdown-general price-calculator__select-font"
                                        >
                                        <option value="" disabled hidden>
                                            {t('price_calculator.step_2.containerId.placeholder')}
                                        </option>
                                        {containers.map((option) => (
                                            <option key={option.id} value={option.id}>
                                            {option.descriptionEn}
                                            </option>
                                            

                                        ))}
                                        </select>
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_2.containerId.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.containerId && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_2.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            }
                            { transportation !== '' &&
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon label-container'>
                                        <label className="label-input">{t('price_calculator.step_2.internationalFreightValueUsd.placeholder')}</label>
                                        <input
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            {...register('internationalFreightValueUsd', { required: true })}
                                            value={!!formTwoValues?.internationalFreightValueUsd ? formTwoValues?.internationalFreightValueUsd : ''}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormTwoValues({...formTwoValues, internationalFreightValueUsd: formatNumber(e.target.value)})
                                                }
                                            }}
                                            onBlur={ (e) => setValidRange(Number(e.target.value) >= 2000 && Number(e.target.value) <= 8000)}
                                        />
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_2.internationalFreightValueUsd.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.internationalFreightValueUsd && errors?.internationalFreightValueUsd.type === "required" &&
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_2.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    
                    </div>
                </div>
            </div>
            <div className="price-calculator__container-footer">
                    <div
                        className="price-calculator__footer-link"
                        onClick={handleClickOpen}
                    >{t('price_calculator.footer.link')}</div>
                    <button type="submit" className="price-calc__next">
                        <div className="btn-test-next-text">{t('price_calculator.footer.button')}</div>
                        <FontAwesomeIcon icon={faArrowRight} color="black" />
                    </button>
                </div>
        </div>
        </form>
    )
}

export default PriceCalculatorStepTwo