
import * as React from 'react';

type IconPackProps = React.SVGProps<SVGSVGElement>;

export const IconPack: React.FC<IconPackProps> = (props) => (
	<svg width="20" height="14" viewBox="0 0 20 14" fill="#ECEEEF" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M1.84051 0.31568C1.93426 0.125055 2.1405 0.0156805 2.34988 0.0438055L9.99988 1.00006L17.6499 0.0438055C17.8593 0.0188055 18.0655 0.12818 18.1593 0.31568L19.4624 2.92193C19.7436 3.48131 19.4436 4.15943 18.8436 4.33131L13.7374 5.79068C13.303 5.91568 12.8374 5.73131 12.6061 5.34381L9.99988 1.00006L7.39363 5.34381C7.16238 5.73131 6.69675 5.91568 6.26238 5.79068L1.15925 4.33131C0.55613 4.15943 0.259255 3.48131 0.540505 2.92193L1.84051 0.31568ZM10.0343 3.00006L11.7499 5.85631C12.2155 6.63131 13.1436 7.00006 14.0155 6.75006L17.9999 5.61256V10.8313C17.9999 11.5188 17.5311 12.1188 16.8624 12.2876L10.4843 13.8813C10.1655 13.9626 9.83113 13.9626 9.5155 13.8813L3.13738 12.2876C2.46863 12.1157 1.99988 11.5157 1.99988 10.8282V5.60943L5.98738 6.75006C6.85613 7.00006 7.78738 6.63131 8.25301 5.85631L9.9655 3.00006H10.0343Z" />
	</svg>
);


