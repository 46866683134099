import * as React from "react";
type IconFlagEsProps = React.SVGProps<SVGSVGElement>;

export const IconFlagEs: React.FC<IconFlagEsProps> = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 256 256"
>
    <g
    style={{
        stroke: "none",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        fill: "none",
        fillRule: "nonzero",
        opacity: 1,
    }}
    >
    <path
        d="M0 45c0 8.199 2.202 15.88 6.034 22.5h77.932A44.767 44.767 0 0 0 90 45c0-8.199-2.202-15.88-6.034-22.5H6.034A44.767 44.767 0 0 0 0 45z"
        style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        fill: "#ffc400",
        fillRule: "nonzero",
        opacity: 1,
        }}
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"
    />
    <path
        d="M83.966 22.5a45.903 45.903 0 0 0-.831-1.381C75.176 8.438 61.077 0 45 0a44.733 44.733 0 0 0-22.5 6.04A45.242 45.242 0 0 0 6.034 22.5h77.932zM6.034 67.5A45.251 45.251 0 0 0 22.5 83.96 44.733 44.733 0 0 0 45 90c16.077 0 30.176-8.438 38.135-21.12.285-.455.562-.915.831-1.38H6.034z"
        style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        fill: "#c60b1e",
        fillRule: "nonzero",
        opacity: 1,
        }}
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"
    />
    </g>
</svg>

  
);
export default IconFlagEs;
