/* eslint-disable react-hooks/exhaustive-deps */
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg';
import CustomToolTip from '../../core/CustomToolTip/CustomToolTip';

import {
	DailyParamViewModelValueResult,
	GetApiUserUserIdPriceCalculatorParams,
	PriceCalculatorBriefViewModelCollectionResult,
	Sorted,
} from '../../api';
import {
	getApiDailyParamName,
	getApiPriceCalculatorCalculatorId,
	getApiRegionCountries,
	getApiUserUserIdPriceCalculator,
} from '../../api/interconectar-api';
import { useVideo } from '../../context/VideoContext';
import CircularLoading from '../../shared/CircularLoading/CircularLoading';
import PopUp from '../../shared/PopUp/PopUp';
import { formatNumber, numberRegex } from '../../utils/helpers';
import IconCalculo from '../Icons/IconCalculo';
import IconCkeck from '../Icons/IconCkeck';
import IconSeach from '../Icons/IconSeach';
import './PriceCalculator.css';
import { Country, FormOneValues } from './PriceCalculator.types';
import { IconCkeckSolid } from '../Icons/IconCkeckSolid';
import IconCircleExclamation from '../Icons/IconCircleExclamation';
import IconCircleCheck from '../Icons/IconCircleCheck';
import i18n from '../../i18n';
import IconError from '../Icons/IconError';




const PriceCalculatorStepOne = ({
	handleClickOpen,
	handleContinueClick,
}: {
	handleClickOpen: MouseEventHandler<HTMLDivElement>;
	handleContinueClick: () => void;
}) => {
	const [isPopUpOpen, setIsPopUpOpen] = useState(false);
	const [referenceName, setReferenceName] = useState('');

	const [data, setData] = useState<any[]>([]); // Para almacenar los resultados
	const [loading, setLoading] = useState<boolean>(true); // Estado de carga
	const [error, setError] = useState<string | null>(null); // Para manejar errores
	const [selectedItemId, setSelectedItemId] = useState<string | null>(null); // Aquí especificas que puede ser un string o null
	const [loadingSelectedId, setLoadingSelectedId] = useState<boolean>(false);
	const [errorSelectedId, setErrorSelectedId] = useState<string | null>(null);

	const [newCountries, setCountries] = useState<any[]>([]); // Estado para almacenar los países
	const [loadingCountries, setLoadingCountries] = useState<boolean>(true); // Estado para gestionar la carga
	const [errorCountries, setErrorCountries] = useState<string | null>(null); // Estado para manejar errores
	const [selectedFilter, setSelectedFilter] = useState<Sorted>('DESC'); // Valor por defecto
	const [searchTerm, setSearchTerm] = useState<string>(''); // Estado para almacenar el término de búsqueda

	const { t } = useTranslation();
	const { setVideoName } = useVideo();
	const [formOneValues, setFormOneValues] = useState<any>();

	const lang = i18n.language || "en"; // Idioma actual o inglés por defecto

	const [dataDaily, setDailyData] = useState<DailyParamViewModelValueResult | null>(null);
	const [errorDaily, setDailyError] = useState<string | null>(null);
	const [loadingDaily, setLoadingDaily] = useState<boolean>(true); // Estado de loading

	useEffect(() => {
		const controller = new AbortController();
		setLoadingDaily(true);
	
		getApiDailyParamName("ParidadEuDolar", controller.signal)
			.then((response) => {
				
				if ('result' in response && 'status' in response) {
					setDailyData(response as DailyParamViewModelValueResult);
				} else {
					throw new Error(t('price_calculator.step_1.error_message_paridad_1'));
				}
				setDailyError(null);
			})
			.catch((err) => {
				setDailyError(err.message || t('price_calculator.step_1.error_message_paridad_2'));
			})
			.finally(() => {
				setLoadingDaily(false);
			});
	
		return () => controller.abort(); // Cancelar la petición al desmontar
	}, []);
	
	

	const emptyForm = {
		name: '',
		country: '',
		countryOriginId: '',
		euroDollarRate:  dataDaily?.result?.value,
		unitFobUsd: '',
		productWeightKg: '',
		tarifCode: '',
		quantityGoods: '',
	};

	const defaultValues = {
		name: formOneValues?.name,
		country: formOneValues?.country,
		countryOriginId: formOneValues?.countryOriginId,
		euroDollarRate: formOneValues?.euroDollarRate ?? dataDaily?.result?.value ,
		unitFobUsd: formOneValues?.unitFobUsd,
		productWeightKg: formOneValues?.productWeightKg,
		tarifCode: formOneValues?.tarifCode,
		quantityGoods: formOneValues?.quantityGoods,
	};

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues,
	});

	const onSubmit = (data: any) => {
		localStorage.setItem('formOne', JSON.stringify(data));
		setFormOneValues(data);
		handleContinueClick();
	};

	useEffect(() => {
		const formOne = localStorage.getItem('formOne') ? JSON.parse(localStorage.getItem('formOne')!) : '';
		if (Object.keys(formOne).length) {
			setFormOneValues(formOne);
		} else {
			reset(emptyForm);
			setFormOneValues(emptyForm);
		}
	}, []);

	useEffect(() => {
		if (formOneValues) {
			reset((prev) => ({ ...prev, ...formOneValues }));
		}
	}, [formOneValues, reset]);

	setVideoName('firstVideo');

	const handleButtonClick = async () => {
		setIsPopUpOpen(!isPopUpOpen);
		if (!selectedItemId) {
			console.warn('No se ha seleccionado un ID');
			return;
		}

		setLoadingSelectedId(true);
		setErrorSelectedId(null); // Limpiar errores previos

		try {
			// Llamada a la función para obtener los datos de la API
			const response = await getApiPriceCalculatorCalculatorId(selectedItemId);
			reset();

			// Verificar si la respuesta tiene datos válidos
			if (response && response.result) {
				const updatedData = {
					...response.result,
					name: response.result.name,
					country: response.result.countryOriginId,
					countryOriginId: response.result.countryOriginId,
					euroDollarRate: response.result.euroDollarRate ?? dataDaily?.result?.value,
					unitFobUsd: response.result.unitFobUsd,
					productWeightKg: response.result.productWeightKg,
					tarifCode: response.result.tarifCode,
					quantityGoods: response.result.quantityGoods,
				};

				setFormOneValues(updatedData);

				if (response.result.meansTransportation) {
					const transportType = response.result.meansTransportation.toLowerCase();
					let specificData = {};

					switch (transportType) {
						case 'maritime':
							specificData = {
								portDestinationId: response.result.portDestinationId,
								meansTransportation: 'maritimo',
								containerId: response.result.containerId,
								internationalFreightValueUsd: response.result.internationalFreightValueUsd,
							};
							break;

						case 'multimodal':
							specificData = {
								portDestinationId: response.result.portDestinationId,
								meansTransportation: 'multimodal',
								internationalFreightValueUsd: response.result.internationalFreightValueUsd,
							};
							break;

						case 'air':
							specificData = {
								portDestinationId: response.result.portDestinationId,
								meansTransportation: 'aereo',
								airTotalWeightPlusPackaging: response.result.airTotalWeightPlusPackaging,
								airTarifCode: response.result.airTarifCode,
								internationalFreightValueUsd: response.result.internationalFreightValueUsd,
							};
							break;

						default:
							console.warn('Transportation type not recognized:', transportType);
							break;
					}

					const updatedData = {
						...response.result,
						portDestinationId: response.result.portDestinationId,
						meansTransportation: response.result.meansTransportation,
						internationalFreightValueUsd: response.result.internationalFreightValueUsd,
					};
					const updatedData3 = {
						...response.result,
						portExpensesUsd: response.result.portExpensesUsd,
						customersExpensesValueUsd: response.result.customersExpensesValueUsd,
						countryDestinationId: response.result.countryDestinationId,
						adValoremTax: response.result.adValoremTax,
						taxDeterminedByWeight: response.result.taxDeterminedByWeight,
						costTransportationPortWarehouse: response.result.costTransportationPortWarehouse,
						country: response.result.countryOriginId,
						countryOriginId: response.result.countryOriginId,
					};
					const updatedData4 = {
						wholesalerMargin: response.result.wholesalerMargin,
						quantityMerchandiseRetailer: response.result.quantityMerchandiseRetailer,
						costTransportationWholesalerRetailerUsd: response.result.costTransportationWholesalerRetailerUsd,
						marketingSegment: response.result.marketingSegment,
						retailerMargin: response.result.retailerMargin,
					};

					// Save data to localStorage
					if (Object.keys(specificData).length > 0) {
						localStorage.setItem('formTwo', JSON.stringify(specificData));
					}
					localStorage.setItem('formOne', JSON.stringify(updatedData));
					localStorage.setItem('formThree', JSON.stringify(updatedData3));
					localStorage.setItem('formFour', JSON.stringify(updatedData4));
				}

				localStorage.setItem('selectedItemData', JSON.stringify(updatedData));

				// Actualizar los valores del formulario sin sobrescribir ediciones en curso
				Object.entries(updatedData).forEach(([key, value]) => {
					setValue(key as keyof FormOneValues, value);
				});
			} else {
				setErrorSelectedId(t('price_calculator.step_1.error_message_selected_id_1'));
			}
		} catch (err) {
			setErrorSelectedId(t('price_calculator.step_1.error_message_selected_id_2'));
		} finally {
			setLoadingSelectedId(false);
		}
	};

	useEffect(() => {
		// Llamada a la API
		const fetchCountries = async () => {
			try {
				const data = await getApiRegionCountries();
				if (data.results) {
					setCountries([...data.results]);
				}
			} catch (error) {
				setErrorCountries(t('price_calculator.step_1.error_message_countries'));
			} finally {
				setLoadingCountries(false);
			}
		};

		fetchCountries();
	}, []);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const filteredData = data.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

	const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedFilter(event.target.value as Sorted);
		setData([]);
	};

	const fetchData = async () => {
		setLoading(true);
		setError(null);
		setData([]);

		try {
			const userId = localStorage.getItem('userId');
			if (!userId) {
				setError(t('price_calculator.step_1.error_message_user_1'));
				
				return
			}

			const params: GetApiUserUserIdPriceCalculatorParams = {
				size: 100,
				order: selectedFilter || 'DESC',
			};

			const response: PriceCalculatorBriefViewModelCollectionResult = await getApiUserUserIdPriceCalculator(
				userId,
				params,
			);

			if (response?.results) {
				setData([...response.results]);
				// console.log(data);
			} else {
				setError(t('price_calculator.step_1.error_message_user_2'));				
			}
		} catch (err: any) {
			setError(err.message || t('price_calculator.step_1.error_message_user_3'));
		} finally {
			setLoading(false);
		}
	};

	const togglePopUp = () => {
		setIsPopUpOpen(!isPopUpOpen);

		if (!isPopUpOpen) {
			fetchData();
		}
	};

	useEffect(() => {
		if (isPopUpOpen) {
			fetchData();
		}
	}, [selectedFilter]);

	const toggleSelectItem = (id: string) => {
		setSelectedItemId((prevId) => (prevId === id ? null : id));
	};

	const [isPopupTarifOpen, setIsPopupTarifOpen] = useState(false);
	const [isValidTarif, setIsValidTarif] = useState(false); // Estado de validación
	const [tarifCode, setTarifCode] = useState(!!formOneValues?.tarifCode ? formOneValues?.tarifCode : ''); // Estado para el código



	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
	
		// Solo permitir números y aplicar formato
		if (value === '' || numberRegex.test(value)) {
		  const formattedValue = formatNumber(value);
	
		  // Actualizar el estado de formOneValues y localStorage
		  setFormOneValues((prev: any) => {
			setTarifCode(formattedValue)
			const updatedValues = { ...prev, tarifCode: formattedValue };
			localStorage.setItem('tarifCode', formattedValue); // Guardar en localStorage
			return updatedValues;
		  });
		}
	  };

	const handleOpenTarifPopup = () => {
	  setIsPopupTarifOpen(true);
	  if (tarifCode.length === 11) {
		setIsValidTarif(true);
	  }
	};
  
	const handleCloseTarifPopup = () => {
	  setIsPopupTarifOpen(false);
	};

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);


	

	if (loadingSelectedId) {
		return <CircularLoading />;
	}

	if (loadingCountries) {
		return <CircularLoading />;
	}
	
	if (loadingDaily) {
		return <CircularLoading />;
	}
	
	

	if (errorCountries) {
		return <div className='errorcalculator'>
			<div className='errorcalculator__icon'>
				<IconError />	
			</div>
			<p className='errorcalculator__txt'>{errorCountries}</p>
		</div>;
	}

	if (errorSelectedId) {
		
		return <div className='errorcalculator'>
			<div className='errorcalculator__icon'>
				<IconError />	
			</div>			
			<p className='errorcalculator__txt'>{errorSelectedId}</p>
		</div>;
	}

	if (errorDaily) {
		return <div className='errorcalculator'>
			<div className='errorcalculator__icon'>
				<IconError />	
			</div>			
			<p className='errorcalculator__txt'>{errorDaily}</p>
		</div>;

	}
	// {error && <p>Error: {error}</p>}
							

	
	return (
		
		<form onSubmit={handleSubmit(onSubmit)}>
			
			<div className="exporter-test__read-only">
				<div className="price-calculator__container-main">
					<div className="price-calculator___inner">
						<div className="row price-calculator__row mb-1">
							<div className="col-12">
								<div className="price-calculator__field-with-icon price-calculator-first label-container">
									<label className="label-input">{t('price_calculator.step_1.name.placeholder')}</label>
									<input
										type="text"
										className="price-calculator__textbox-general"
										value={!!formOneValues?.name ? formOneValues?.name : null}
										{...register('name', { required: true })}
										onChange={(e) => {
											setFormOneValues({ ...formOneValues, name: e.target.value });
										}}
									/>

									<span
										className="price-calculator-btn"
										aria-label={t('price_calculator.step_1.popup_select.button_1')}
										onClick={togglePopUp}>
										{t('price_calculator.step_1.popup_select.button_1')}
									</span>

									<div className="price-calculator-ml-lg">
										<CustomToolTip
											title=""
											tooltipText={t('price_calculator.step_1.name.tooltip')}>
											<FontAwesomeIcon
												icon={faCircleQuestion}
												className="font-size: 18px"
											/>
										</CustomToolTip>
									</div>
								</div>

								{!!errors?.name && (
									<div className="error-container">
										<InfoIcon fill="#F8A0D2" />
										<div className="error-message">{t('price_calculator.step_1.error_message')}</div>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className="price-calculator___inner">
						<div className=" price-calculator___container">
							<div className="row">
								<div className="col-12">
									<span className="price-calculator__container-title">{t('price_calculator.step_1.subtitle')}</span>
								</div>
							</div>

							<div className="row price-calculator__row mb-4">
								<div className="col-12 col-sm-12 col-md-6 ">
									<div className="price-calculator__field-with-icon">
										<select
											{...register('country', { required: true })}
											value={formOneValues?.country || ''}
											onChange={(e) => {
												const selectedCountryId = e.target.value;
												setFormOneValues({
													...formOneValues,
													country: selectedCountryId,
													countryOriginId: selectedCountryId,
												});
											}}
											className="price-calc__dropdown-general price-calculator__select-font">
											<option
												value=""
												disabled>
												{t('price_calculator.step_1.country.placeholder')}
											</option>
											{newCountries.map((region) => (
												<optgroup
													key={region.nameEs}
													label={region.nameEs}>
													{region.countries.map((country: Country) => (
														<option
															key={country.id}
															value={country.id}
															className="price-calculator__select-font">
															{country.name}
														</option>
													))}
												</optgroup>
											))}
										</select>

										<div className="price-calculator-ml-sm">
											<CustomToolTip
												title=""
												tooltipText={t('price_calculator.step_1.country.tooltip')}>
												<FontAwesomeIcon
													icon={faCircleQuestion}
													className="font-size: 18px"
												/>
											</CustomToolTip>
										</div>
									</div>
									{!!errors?.country && (
										<div className="error-container">
											<InfoIcon fill="#F8A0D2" />
											<div className="error-message">{t('price_calculator.step_1.error_message')}</div>
										</div>
									)}
								</div>
								<div className="col-12 col-sm-12 col-md-6 label-container">

									<label className="label-input">
										{t('price_calculator.step_1.euroDollarRate.placeholder')}
									</label>
									
									<input
										{...register('euroDollarRate', { required: true })}
										value={formOneValues?.euroDollarRate ||  dataDaily?.result?.value || ''  } 
										onChange={(e) => {
										if (e.target.value === '' || numberRegex.test(e.target.value)) {
											setFormOneValues({ ...formOneValues, euroDollarRate: formatNumber(e.target.value) });
										}
										}}
										type="text"
										className="price-calculator__textbox-general-col2"
									/>
									{!!errors?.euroDollarRate && (
										<div className="error-container">
										<InfoIcon fill="#F8A0D2" />
										<div className="error-message">{t('price_calculator.step_1.error_message')}</div>
										</div>
									)}
								</div>
							</div>

							<div className="row price-calculator__row mb-4">
								<div className="col-12 col-sm-12 col-md-6 label-container">
									<div className="price-calculator__field-with-icon">
										<div className="price-calculator__field-with-label">
											<label className="label-input">{t('price_calculator.step_1.tarifCode.placeholder')}</label>
											<div className="tarif-code_container">
												<input
													{...register('tarifCode', { required: true })}
													// value={}
													value={!!formOneValues?.tarifCode ? formOneValues?.tarifCode : null}
													onChange={handleInputChange}
													onPaste={(e) => {
														e.preventDefault();
														const pastedValue = e.clipboardData.getData('text').trim();
														if (/^[0-9.]+[A-Z]?$/.test(pastedValue)) {
														  setFormOneValues({ ...formOneValues, tarifCode: pastedValue });
														}
													  }}
													type="text"
													className="price-calculator__textbox-general-col2 tarif-code_input"
												/>
												{!isValidTarif ? (
													<button
														onClick={handleOpenTarifPopup}
														className="tarif-code_button"
														type="button">
														{t('price_calculator.step_1.tarif_code_button')}
													</button>
												): (
													<span className="tarif-code_button tarif-code_button-succes">
														<IconCkeckSolid  width={17} height={20} fill='#1A2228' />
													</span>
												)}

												{/* Popup */}
												<PopUp isOpen={isPopupTarifOpen} onClose={handleCloseTarifPopup}  >
													
														{isValidTarif ? (
															<div className="modal-tc">
																<div className="modal-tc_icon">
																	<IconCircleCheck />
																</div>
																<h4 className="modal-tc_title">{t('price_calculator.step_1.modal_tarif_code.success_title')} {tarifCode}</h4>
																<p className="modal-tc_text">0808.10.00.910R En envases inmediatos de contenido neto inferior o igual a 2,5 kg (Dec.PEN 509/07 Anexo III)
																II PRODUCTOS DEL REINO VEGETAL
																FRUTAS Y FRUTOS COMESTIBLES; CORTEZAS DE AGRIOS, CÍTRICOS, MELONES O SANDÍAS
																MANZANAS, PERAS Y MEMBRILLOS, FRESCOS.
																Manzanas
																Las demás</p>

																<button
																	onClick={handleCloseTarifPopup}
																	className="modal-tc_button"
																	type="button">
																	{t('price_calculator.step_1.modal_tarif_code.success_button')}
																</button>
															</div>
														) : (
															<div className="modal-tc">
																<div className="modal-tc_icon">
																	<IconCircleExclamation width={42} height={42} />
																</div>
																<h4 className="modal-tc_title">{t('price_calculator.step_1.modal_tarif_code.error_title')}  {tarifCode}</h4>
																<p className="modal-tc_text">{t('price_calculator.step_1.modal_tarif_code.error_description')}</p>

																<button
																	onClick={handleCloseTarifPopup}
																	className="modal-tc_button"
																	type="button">
																	{t('price_calculator.step_1.modal_tarif_code.error_button')}
																</button>
															</div>
														)}
												


												</PopUp>

											</div>
											<a
												href={`https://ec.europa.eu/taxation_customs/dds2/taric/taric_consultation.jsp?Lang=${lang}`}
												target="_blank"
												className="price-calculator__field-label">
												{t('price_calculator.step_1.tarif_code_label')}
											</a>
										</div>
										<div className="price-calculator-ml-sm price-calculator-mt">
											<CustomToolTip
												title=""
												tooltipText={
													<>
														{t('price_calculator.step_1.tarifCode.tooltip_first')}{' '}
														<a
															target="_blank"
															rel="noreferrer"
															href="https://ec.europa.eu/taxation_customs/dds2/taric/taric_consultation.jsp?Lang=es&Expand=true&SimDate=20230823#afterForm"
															className="price-calc__tootip-link">
															{t('price_calculator.step_1.tarifCode.tooltip_link')}
														</a>{' '}
														{t('price_calculator.step_1.tarifCode.tooltip_last')}
													</>
												}>
												<FontAwesomeIcon
													icon={faCircleQuestion}
													className="font-size: 18px"
												/>
											</CustomToolTip>
										</div>
									</div>
									{!!errors?.tarifCode && (
										<div className="error-container">
											<InfoIcon fill="#F8A0D2" />
											<div className="error-message">{t('price_calculator.step_1.error_message')}</div>
										</div>
									)}
								</div>
								<div className="col-12 col-sm-12 col-md-6 label-container">
									<label className="label-input">{t('price_calculator.step_1.productWeightKg.placeholder')}</label>
									<input
										{...register('productWeightKg', { required: true })}
										value={!!formOneValues?.productWeightKg ? formOneValues?.productWeightKg : ''}
										onChange={(e) => {
											if (e.target.value === '' || numberRegex.test(e.target.value)) {
												setFormOneValues({ ...formOneValues, productWeightKg: formatNumber(e.target.value) });
											}
										}}
										type="text"
										className="price-calculator__textbox-general-col2"
									/>

									{!!errors?.productWeightKg && (
										<div className="error-container">
											<InfoIcon fill="#F8A0D2" />
											<div className="error-message">{t('price_calculator.step_1.error_message')}</div>
										</div>
									)}
								</div>
							</div>

							<div className="row price-calculator__row mb-4">
								<div className="col-12 col-sm-12 col-md-6 ">
									<div className="price-calculator__field-with-icon label-container">
										<label className="label-input">{t('price_calculator.step_1.unitFobUsd.placeholder')}</label>
										<input
											{...register('unitFobUsd', { required: true })}
											value={!!formOneValues?.unitFobUsd ? formOneValues?.unitFobUsd : ''}
											onChange={(e) => {
												if (e.target.value === '' || numberRegex.test(e.target.value)) {
													setFormOneValues({ ...formOneValues, unitFobUsd: formatNumber(e.target.value) });
												}
											}}
											type="text"
											className="price-calculator__textbox-general-col2"
										/>
										<div className="price-calculator-ml-sm">
											<CustomToolTip
												title=""
												tooltipText={t('price_calculator.step_1.unitFobUsd.tooltip')}>
												<FontAwesomeIcon
													icon={faCircleQuestion}
													className="font-size: 18px"
												/>
											</CustomToolTip>
										</div>
									</div>
									{!!errors?.unitFobUsd && (
										<div className="error-container">
											<InfoIcon fill="#F8A0D2" />
											<div className="error-message">{t('price_calculator.step_1.error_message')}</div>
										</div>
									)}
								</div>
								<div className="col-12 col-sm-12 col-md-6">
									<div className="price-calculator__field-with-icon label-container">
										<label className="label-input">{t('price_calculator.step_1.quantityGoods.placeholder')}</label>
										<input
											{...register('quantityGoods', { required: true })}
											value={!!formOneValues?.quantityGoods ? formOneValues?.quantityGoods : ''}
											onChange={(e) => {
												if (e.target.value === '' || numberRegex.test(e.target.value)) {
													setFormOneValues({ ...formOneValues, quantityGoods: formatNumber(e.target.value) });
												}
											}}
											type="text"
											className="price-calculator__textbox-general-col2"
										/>
										<div className="price-calculator-ml-sm">
											<CustomToolTip
												title=""
												tooltipText={t('price_calculator.step_1.quantityGoods.tooltip')}>
												<FontAwesomeIcon
													icon={faCircleQuestion}
													className="font-size: 18px"
												/>
											</CustomToolTip>
										</div>
									</div>
									{!!errors?.quantityGoods && (
										<div className="error-container">
											<InfoIcon fill="#F8A0D2" />
											<div className="error-message">{t('price_calculator.step_1.error_message')}</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="price-calculator__container-footer">
						<div
							className="price-calculator__footer-link"
							onClick={handleClickOpen}>
							{t('price_calculator.footer.link')}
						</div>
						<button
							type="submit"
							className="price-calc__next">
							<div className="btn-test-next-text">{t('price_calculator.footer.button')}</div>
							<FontAwesomeIcon
								icon={faArrowRight}
								color="black"
							/>
						</button>
					</div>
				</div>
			</div>

			{isPopUpOpen && (
				<PopUp
					title={t('price_calculator.step_1.popup_select.title')}
					isOpen={isPopUpOpen}
					onClose={togglePopUp}>
					<div className="search-name">
						<input
							className="search-name-input"
							type="text"
							value={searchTerm}
							onChange={handleSearchChange}
							placeholder={t('price_calculator.step_1.popup_select.placeholder')}
						/>
						<button className="search-name-button">
							<IconSeach />
						</button>
					</div>
					<div className="seach-filter">
						<div className="seach-filter-results">
							{data.length} {t('price_calculator.step_1.popup_select.seach_result')}
						</div>
						<select
							className="seach-select"
							value={selectedFilter} // Sincronizar el estado con el valor seleccionado
							onChange={handleSelectChange} // Actualizar el estado al cambiar
						>
							<option
								value=""
								disabled>
								{data.length} {t('price_calculator.step_1.popup_select.seach_filter_default')}
							</option>
							<option value="ASC">ASC</option>
							<option value="DESC">DESC</option>
						</select>
					</div>

					<div className="list-calculos">
						{loading && <CircularLoading />}
						{error && <p>{t('price_calculator.step_1.popup_select.error_expired_message')} </p>}

						{filteredData.map((item) => (
							<div
								key={item.id}
								className={`list-calculos-item ${item.id === selectedItemId ? 'selected' : ''}`}
								onClick={() => toggleSelectItem(item.id)}>
								<div className="list-calculos-icon">
									<IconCalculo />
								</div>
								<p className="list-calculos-text">
									{item.name} ({new Date(item.date).toLocaleDateString()})
								</p>
								{item.id === selectedItemId && <IconCkeck fill="#BED5C6" />}
							</div>
						))}
					</div>
					<div className="popup-buttons">
						<button
							className="popup-button cancel-button"
							onClick={togglePopUp}>
							{t('price_calculator.step_1.popup_select.button_2')}
						</button>
						<button
							className={`popup-button save-button ${!selectedItemId ? 'disabled' : ''}`}
							onClick={handleButtonClick}>
							{t('price_calculator.step_1.popup_select.button_3')}
						</button>
					</div>
				</PopUp>
			)}
		</form>
	);
};

export default PriceCalculatorStepOne;
