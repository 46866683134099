import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { IconCirclexmark, IconLock } from "../../components/Icons";
import "./SuscripcionesChout.css";
import IconCircleExclamation from "../../components/Icons/IconCircleExclamation";
import { IconCkeckSolid } from "../../components/Icons/IconCkeckSolid";
import { ProductChildViewModel, postApiSubscriptionPayment, postApiSubscriptionValidateDiscount } from "../../api";
import CircularLoading from "../../shared/CircularLoading/CircularLoading";

interface StepProps {
  setStep: (number: number) => void;
  setIsOpen: (boolean: boolean) => void;
  productId: number;
  data: ProductChildViewModel[];
  productParentName: string;
}

export const SuscripcionesChout = ({ setStep, setIsOpen, productId, data, productParentName }: StepProps) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    codigo: "",
    mostrarDescuento: false,
    error: false,
    codigoAplicado: false,
    errorPago: false,
    precioDescuento: undefined as number | undefined,
    precioOriginal: undefined as number | undefined,
    porcentajeDescuento: 0,
    loading: false,
    codigoValido: ""
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);


  const product = data.find(item => item.id === productId);

  const handleCloseChout = async () => {
    if (state.errorPago) return;
    if (state.codigo && !state.codigoAplicado) {
      setState(prev => ({ ...prev, errorPago: true }));
      return;
    }
    
    setLoading(true);
    try {
      const response = await postApiSubscriptionPayment({
        productId: productId,
        couponCode: state.codigoValido || undefined
      });
      setStep(3);
    } catch (err) {
      setError("Error en el pago");
    } finally {
      setLoading(false);
    }
  };

  const handleAplicarClick = async () => {
    if (state.codigo.trim() === "" || state.loading) return;
    setState(prev => ({ ...prev, loading: true, errorPago: false, error: false }));

    try {
      const response = await postApiSubscriptionValidateDiscount({
        couponCode: state.codigo,
        productId: productId,
      });

      if (response.status === "Ok" && response.result) {
        const { price = 0, discountedPrice = 0, percent } = response.result;

        setState(prev => ({
          ...prev,
          mostrarDescuento: true,
          codigoAplicado: true,
          precioOriginal: price,
          precioDescuento: price - discountedPrice,
          porcentajeDescuento: percent ?? 0,
          codigoValido: state.codigo
        }));
      } else {
        setState(prev => ({ 
          ...prev, 
          mostrarDescuento: false, 
          error: true, 
          codigoAplicado: false,
          codigoValido: ""
        }));
      }
    } catch (error) {
      console.error("Error aplicando descuento:", error);
      setState(prev => ({ 
        ...prev, 
        mostrarDescuento: false, 
        error: true, 
        codigoAplicado: false,
        codigoValido: ""
      }));
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      codigo: "",
      mostrarDescuento: false,
      error: false,
      codigoAplicado: false,
      errorPago: false,
      precioDescuento: 0,
      precioOriginal: 0,
      porcentajeDescuento: 0,
      codigoValido: ""
    }));
  };

  const renderErrorMessage = () => {
    if (state.errorPago) return t('suscripcion.modal_chout.error_message_1');
    if (state.error) return t('suscripcion.modal_chout.error_message_2');
    return null;
  };

  const renderButtonContent = () => {
    if (state.loading) return <CircularLoading />;
    if (state.mostrarDescuento) return <IconCkeckSolid className="chout_submit_svg" fill="#1A2228" />;
    return t('suscripcion.modal_chout.button_apply');
  };

  
  if (loading) return (<div className="chout__loading"><CircularLoading  /></div>);
  
  if (error) {
    return (
      <>
        <h3 className="chout_title">Se ha producido un error</h3>
        <p className="chout_subtitle">  Ocurrió un problema al procesar los datos. Por favor, verifica tu conexión a Internet e intenta nuevamente.  
        </p>
        <button type="button" className="chout_next" onClick={() => setError(null)}>
          Reintentar
        </button>
      </>
    );
  }

  return (
    <div className="chout">
      <h3 className="chout_title">Pack {product?.name}</h3>
      <p className="chout_subtitle">
        {productParentName === "Price Calculator" ? t('suscripcion.modal_chout.subtitle_calculadora') : t('suscripcion.modal_chout.subtitle_comparador')}
        </p>
      <div className="chout_form">
        <div className="chout_form_body">
          <label className="chout_label" htmlFor="referenceInput">
            {t('suscripcion.modal_chout.inptu_label')}
          </label>
          <input
            id="referenceInput"
            className={`chout_input ${state.error ? "chout_input--error" : ""}`}
            type="text"
            placeholder="*******"
            autoComplete="off"
            value={state.codigo}
            onChange={(e) => setState(prev => ({
              ...prev,
              codigo: e.target.value,
              codigoAplicado: false,
              errorPago: false
            }))}
          />
          {(state.errorPago || state.error) && (
            <div className="chout_error">
              <IconCircleExclamation className="chout_error_svg" fill="#F8A0D2" />
              <p className="chout_error_message">{renderErrorMessage()}</p>
            </div>
          )}
          {state.codigo && (
            <button type="button" className="chout_reset" onClick={handleResetClick}>
              <IconCirclexmark className="chout_reset_svg" />
            </button>
          )}
        </div>
        <button
          type="button"
          className={`chout_submit ${state.codigo ? "chout_submit--active" : ""} ${state.mostrarDescuento ? "chout_submit--checked" : ""}`}
          onClick={handleAplicarClick}
          disabled={state.loading}
        >
          {renderButtonContent()}
        </button>
      </div>
      {state.mostrarDescuento ? (
        <div className="chout_discount">
          <div className="chout_discount_left">
            <p className="chout_discount_label">Descuento {state.porcentajeDescuento}%</p>
          </div>
          <div className="chout_discount_right">
            <p className="chout_discount_text">
              <span className="chout_discount_old">{state.precioOriginal} USD</span>
              <span>{state.precioDescuento} USD</span>
            </p>
          </div>
        </div>
      ) : (
        <div className="chout_to_pay">
          <p className="chout_to_pay_label">{t('suscripcion.modal_chout.pay_label')}</p>
          <p className="chout_to_pay_text">
            <span>{product?.price} USD</span>
          </p>
        </div>
      )}
      <div className="chout_footer">
        <div className="chout_footer_text">
          <IconLock className="chout_footer_text_svg" />
          <div dangerouslySetInnerHTML={{ __html: t('suscripcion.modal_chout.footer') }} />
        </div>
        <button type="button" className={`chout_next`} onClick={handleCloseChout}>
          {t('suscripcion.modal_chout.button')}
        </button>
      </div>
    </div>
  );
};
