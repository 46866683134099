import * as React from "react";
type IconCircleExclamationProps = React.SVGProps<SVGSVGElement>;

export const IconCircleExclamation: React.FC<IconCircleExclamationProps> = (props) => (
  <svg width={12} height={13} viewBox="0 0 12 13" fill="#F8A0D2" xmlns="http://www.w3.org/2000/svg" {...props }>
    <g clip-path="url(#clip0_1272_6814)">
    <path d="M6 12.5C7.5913 12.5 9.11742 11.8679 10.2426 10.7426C11.3679 9.61742 12 8.0913 12 6.5C12 4.9087 11.3679 3.38258 10.2426 2.25736C9.11742 1.13214 7.5913 0.5 6 0.5C4.4087 0.5 2.88258 1.13214 1.75736 2.25736C0.632141 3.38258 0 4.9087 0 6.5C0 8.0913 0.632141 9.61742 1.75736 10.7426C2.88258 11.8679 4.4087 12.5 6 12.5ZM6 3.5C6.31172 3.5 6.5625 3.75078 6.5625 4.0625V6.6875C6.5625 6.99922 6.31172 7.25 6 7.25C5.68828 7.25 5.4375 6.99922 5.4375 6.6875V4.0625C5.4375 3.75078 5.68828 3.5 6 3.5ZM5.25 8.75C5.25 8.55109 5.32902 8.36032 5.46967 8.21967C5.61032 8.07902 5.80109 8 6 8C6.19891 8 6.38968 8.07902 6.53033 8.21967C6.67098 8.36032 6.75 8.55109 6.75 8.75C6.75 8.94891 6.67098 9.13968 6.53033 9.28033C6.38968 9.42098 6.19891 9.5 6 9.5C5.80109 9.5 5.61032 9.42098 5.46967 9.28033C5.32902 9.13968 5.25 8.94891 5.25 8.75Z" />
    </g>
    <defs>
    <clipPath id="clip0_1272_6814">
    <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
    </defs>
  </svg>
  
);
export default IconCircleExclamation;