import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PopUp from '../../shared/PopUp/PopUp';
import './SuscripcionesPlanes.css';
import './popup-corp.css';
import { SuscripcionesPacks } from '../../pages/Suscripciones/SuscripcionesPacks';
import { SuscripcionesChout } from '../../pages/Suscripciones/SuscripcionesChout';
import { SuscripcionesChoutSucces } from '../../pages/Suscripciones/SuscripcionesChoutSucces';
import { ProductChildViewModel } from '../../api';

export const SuscripcionesPlanes = ({
	isOpen,
	setIsOpen,
	onClose,
	data,
	productIdsUses,
	refetch,
	productParentName

}: {
	isOpen: boolean;
	setIsOpen: (boolean: boolean) => void;
	onClose: () => void;
	data: ProductChildViewModel[];
	productIdsUses: number[];
	refetch: () => void;
	productParentName: string;
}) => {
	const {t} = useTranslation()
	const [step, setStep] = useState(1);
	const [isOpenCorp, setIsOpenCorp] = useState(false);
	const [isCheckOut, setIsCheckOut] = useState(false);
	const [productId, setProductId] = useState<number | null>(null);


	const handleCloseCorp = () => setIsOpenCorp(false);
	const handleCloseCheckOut = () => {
		setIsCheckOut(false);
		setIsOpen(false);
	};

	return (
		<>
			{/* PopUp Corporativo */}
			{isOpenCorp && (
				<PopUp
					isOpen={isOpenCorp}
					onClose={handleCloseCorp}
					classes="popup-corp">
					<div className="popup_corp_container">
						<h3 className="popup_corp_title">{t('suscripcion.modal_corpo.title')}</h3>
						<div className="popup_corp_body" dangerouslySetInnerHTML={{ __html: t('suscripcion.modal_corpo.body') }} />
							
						
						<div className="popup_corp_footer">
							<button
								className="popup_corp_button"
								onClick={handleCloseCorp}
								type="button">
								{t('suscripcion.modal_corpo.button')}
							</button>
						</div>
					</div>
				</PopUp>
			)}

			{/* PopUp Checkout */}
			{isCheckOut && step === 2 && (
				<PopUp
					isOpen={isCheckOut}
					onClose={handleCloseCheckOut}
					classes="popup-checkout">
					<SuscripcionesChout
						setStep={setStep}
						setIsOpen={setIsOpen}
						productId={productId || 0}
						data={data}
						productParentName={productParentName}
					/>
				</PopUp>
			)}
			{/* PopUp Checkout */}
			{isCheckOut && step === 3 && (
				<PopUp
					isOpen={isCheckOut}
					onClose={handleCloseCheckOut}
					classes="popup-message">
					<SuscripcionesChoutSucces
						setIsCheckOut={setIsCheckOut}
						setIsOpen={setIsOpen}
						setStep={setStep}
						refetch={refetch}
					/>
				</PopUp>
			)}

			{/* PopUp Packs (Siempre con "popup-packs") */}
			{isOpen && !isCheckOut && !isOpenCorp && (
				<PopUp
					isOpen={isOpen}
					onClose={onClose}
					classes="popup-packs">
					<SuscripcionesPacks
						setStep={setStep}
						setIsOpen={setIsOpen}
						setIsOpenCorp={setIsOpenCorp}
						setIsCheckOut={setIsCheckOut}
						data={data}
						setProductId={setProductId}
						productIdsUses={productIdsUses}
						productParentName={productParentName}

					/>
				</PopUp>
			)}
		</>
	);
};
