import * as React from "react";
type IconCalculoProps = React.SVGProps<SVGSVGElement>;

export const IconCalculo: React.FC<IconCalculoProps> = (props) => (
  <svg width={14} height={18} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_949_19553)">
<path d="M0 2.25C0 1.00898 1.00898 0 2.25 0H7.875V4.5C7.875 5.12227 8.37773 5.625 9 5.625H13.5V15.75C13.5 16.991 12.491 18 11.25 18H2.25C1.00898 18 0 16.991 0 15.75V2.25ZM13.5 4.5H9V0L13.5 4.5Z" fill="#D4D8DB"/>
</g>
<defs>
<clipPath id="clip0_949_19553">
<rect width="13.5" height="18" fill="white"/>
</clipPath>
</defs>
</svg>

  
);
export default IconCalculo;
