

import "react-phone-number-input/style.css";



import html2canvas from 'html2canvas-pro';


import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import jsPDF from 'jspdf';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import "react-phone-number-input/style.css";
import { Link } from 'react-router-dom';
import pdf from "../../assets/images/file-pdf-solid.png";
import CustomToolTip from '../../core/CustomToolTip/CustomToolTip';
import './PriceCalculator.css';



import { getApiRegionCountries, getApiSubRegionRegionName, usePostApiPriceCalculator } from '../../api/interconectar-api';
import CircularLoading from "../../shared/CircularLoading/CircularLoading";
import PopUp from "../../shared/PopUp/PopUp";
import IconError from "../Icons/IconError";
import IconSuccess from "../Icons/IconSuccess";
import CountryName from "./CountryName";
import { FormFour, FormOne, FormThree, FormTwo, Props } from './PriceCalculator.types';
import ReplaceCalculationPopup from "./ReplaceCalculationPopup";





import { PdfDownload } from "../PdfDownload/PdfDownload";
import { formatText } from "../../utils/formatText";
import { IconInstagram } from "../Icons/IconInstagram";
import { IconLinkedin } from "../Icons/IconLinkedin";
import { downloadPdf } from "../../utils/pdfUtils";


const PriceCalculatorResumen: React.FC<Props> = ({ resetFormClick }) => {
  const { mutate: priceCalculatorSave, isLoading: isLoadingPriceCalculator } = usePostApiPriceCalculator();
  const { t } = useTranslation();

  const formOne = JSON.parse(window.localStorage.getItem("formOne")!);
  const formTwo = JSON.parse(window.localStorage.getItem("formTwo")!);
  const formThree = JSON.parse(window.localStorage.getItem("formThree")!);
  const formFour = JSON.parse(window.localStorage.getItem("formFour")!);

  const eur = parseFloat(formOne.euroDollarRate);
  const fob = parseFloat(formOne.unitFobUsd) * eur;

  const fi = parseFloat(formTwo.internationalFreightValueUsd);
  const un = parseFloat(formOne.quantityGoods);
  const net = parseFloat(formOne.productWeightKg);
  const taxPercentage = parseFloat(formThree.adValoremTax);
  const taxValue = parseFloat(formThree.taxDeterminedByWeight);
  const gp = parseFloat(formThree.portExpensesUsd);
  const ga = parseFloat(formThree.customersExpensesValueUsd);
  const fe = parseFloat(formThree.costTransportationPortWarehouse);
  const ui = parseFloat(formFour.wholesalerMargin);
  const fei = parseFloat(formFour.costTransportationWholesalerRetailerUsd);
  const unf = parseFloat(formFour.quantityMerchandiseRetailer);
  const um = parseFloat(formFour.retailerMargin);

  const cif = fob + fi * eur / un;
  const ddp = (cif * (1 + taxPercentage / 100)) + (taxValue * net / 100) + (gp + ga + fe) / un;
  const pue = ((ddp * (1 + ui / 100)) + (fei / unf)) * (1 + um / 100);


  let euro = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
  });

  const [isSave, setIsSave] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [referenceName, setReferenceName] = useState("");
  const [showPopUpMessage, setShowPopUpMessagePopUp] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState("");
  const [popUpMessage, setPopUpMessage] = useState("");
  const [popUpType, setPopUpType] = useState<'success' | 'error' | null>(null);
  const selectedItemId = localStorage.getItem('selectedItemData');
  const userId = localStorage.getItem("userId") || "";

  const [CountriesOrigin, setCountriesOrigin] = useState<any[]>([]);
  const [loadingCountriesOrigin, setLoadingCountriesOrigin] = useState<boolean>(true);
  const [errorCountriesOrigin, setErrorCountriesOrigin] = useState<string | null>(null);

  const [countriesDestination, setCountriesDestination] = useState<any[]>([]);
  const [loadingCountriesDestination, setLoadingCountriesDestination] = useState<boolean>(true);
  const [errorCountriesDestination, setErrorCountriesDestination] = useState<string | null>(null);

  useEffect(() => {
    const formOne = localStorage.getItem("formOne");
    if (formOne) {
      const parsedFormOne = JSON.parse(formOne);
      setReferenceName(parsedFormOne.name || "Nombre predeterminado");
    } else {
      setReferenceName("Nombre predeterminado");
    }
  }, []);

  const handleClosePopUp = () => {
    setShowPopUpMessagePopUp(false);
  };

  const handleRetry = () => {
    setIsPopUpOpen(!isPopUpOpen);
    setShowPopUpMessagePopUp(false);
  };

  const handleReset = () => {
    if (localStorage.getItem('selectedItemData')) {
      localStorage.removeItem('selectedItemData');
    }
    setIsSave(false);
    resetFormClick();
  };

  const togglePopUp = () => {
    setIsPopUpOpen(!isPopUpOpen);
  };


  useEffect(() => {
    const fetchCountries = async () => {
      try {
        
        const data = await getApiRegionCountries();
        if (data.results) {
          setCountriesOrigin([...data.results]);
        }
      } catch (error) {
        
        setErrorCountriesOrigin(t('price_calculator.resume.error_message_countries_origin'));
      } finally {
        setLoadingCountriesOrigin(false);
      }
    };

    fetchCountries();
  }, []);


  useEffect(() => {
    const fetchCountries = async () => {
      try {
        
        const data = await getApiSubRegionRegionName("Europa");
        const storedData = JSON.parse(localStorage.getItem("formOne") || "{}");
        const excludedId = storedData?.countryOriginId;

        if (data.results) {
          const filteredCountries = data.results.map((region: any) => ({
            ...region,
            countries: region.countries.filter(
              (country: any) => country.id !== excludedId
            ),
          }));

          setCountriesDestination(filteredCountries);
        }
      } catch (error) {
        
        setErrorCountriesDestination(t('price_calculator.resume.error_message_countries_destination'));
      } finally {
        setLoadingCountriesDestination(false);
      }
    };

    fetchCountries();
  }, []);

  const saveFormClick = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formOne: FormOne = JSON.parse(localStorage.getItem("formOne") || "{}") as FormOne;
    const updatedFormOne = { ...formOne, name: referenceName };
    localStorage.setItem("formOne", JSON.stringify(updatedFormOne));
    const formTwo: FormTwo = JSON.parse(localStorage.getItem("formTwo") || "{}") as FormTwo;
    const formThree: FormThree = JSON.parse(localStorage.getItem("formThree") || "{}") as FormThree;
    const formFour: FormFour = JSON.parse(localStorage.getItem("formFour") || "{}") as FormFour;

    priceCalculatorSave({
      data: {
        name: updatedFormOne.name || "string",
        euroDollarRate: parseFloat(formOne.euroDollarRate || "0"),
        tarifCode: formOne.tarifCode || 'string',
        productWeightKg: parseFloat(formOne.productWeightKg || "0"),
        unitFobUsd: parseFloat(formOne.unitFobUsd || "0"),
        quantityGoods: parseInt(formOne.quantityGoods || "0", 10),
        meansTransportation: formTwo.meansTransportation || undefined,
        internationalFreightValueUsd: parseFloat(formTwo.internationalFreightValueUsd || "0"),
        airTotalWeightPlusPackaging: parseFloat(formTwo.airTotalWeightPlusPackaging || "0"),
        airTarifCode: formTwo.airTarifCode || "string",
        portExpensesUsd: parseFloat(formThree.portExpensesUsd || "0"),
        customersExpensesValueUsd: parseFloat(formThree.customersExpensesValueUsd || "0"),
        adValoremTax: parseFloat(formThree.adValoremTax || "0"),
        taxDeterminedByWeight: parseFloat(formThree.taxDeterminedByWeight || "0"),
        costTransportationPortWarehouse: parseFloat(formThree.costTransportationPortWarehouse || "0"),
        wholesalerMargin: parseFloat(formFour.wholesalerMargin || "0"),
        quantityMerchandiseRetailer: parseInt(formFour.quantityMerchandiseRetailer || "0", 10),
        costTransportationWholesalerRetailerUsd: parseFloat(formFour.costTransportationWholesalerRetailerUsd || "0"),
        marketingSegment: formFour.marketingSegment || undefined,
        retailerMargin: parseFloat(formFour.retailerMargin || "0"),
        userId,
        countryOriginId: formOne.countryOriginId || "string",
        countryDestinationId: formThree.countryDestinationId || "string",
        containerId: formTwo.containerId || undefined,
        portDestinationId: formTwo.portDestinationId || undefined,
        precioFob: fob || undefined,
        precioCif: cif || undefined,
        precioDDP: ddp || undefined,
        precioFinalWithoutIva: pue || undefined
      }
    }, {
      onSuccess(data, variables, context) {
        setIsPopUpOpen(false);
        setPopUpMessage("¡Los datos se enviaron correctamente!");
        setPopUpTitle(t("price_calculator.save.title_success"));
        setPopUpType('success');
        setShowPopUpMessagePopUp(true);
        setIsSave(true);
      },
      onError(error, variables, context) {
        setIsPopUpOpen(false);
        setPopUpMessage(t("price_calculator.save.title_error"));
        setPopUpTitle(t("price_calculator.save.text_error"));
        setPopUpType('error');
        setShowPopUpMessagePopUp(true);
        console.log(error, variables, context);
      },
    });
  };

  useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

  if (loadingCountriesDestination || loadingCountriesOrigin) {
    return <CircularLoading />;
  }

  if (errorCountriesDestination || errorCountriesOrigin) {

    return <div className='errorcalculator'>
      <div className='errorcalculator__icon'>
        <IconError />	
      </div>			
      <p className='errorcalculator__txt'>{errorCountriesDestination || errorCountriesOrigin}</p>
    </div>;
    }

  return (
    <>
    <div  className='price-calculator_resume'>
      <form>
        <div className="mb-5">
          <div className=" price-calculator_resume_container">
            {/* Primera file */}
            <div className="row text-light text-sm-left price-calculator_resume_container_row">
              <div className="col-sm-12">
                <div className="price-calculator_resume-title">
                  <span>{t('price_calculator.resume.title')}</span>
                  <CustomToolTip
                      title=""
                      tooltipText={<> {t('price_calculator.resume.title_tooltip')} <a href='mailto:info@exportopia.eu' className='price-calc__tootip-link'>info@exportopia.eu</a></>}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="font-size: 15px"
                    />
                  </CustomToolTip>
                </div>
                <div className="price-calculator_resume-subtitle">
                  <span>{formOne.name}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="price-calculator_resume_container-card">
            <div className="price-calculator_resume_card size-sm">
              <div className="card-body">
                <h5 className="price-calculator_resume_card-title">{euro.format(fob)}</h5>
                <p className="price-calculator_resume_card-text">{t('price_calculator.resume.fob')}</p>
              </div>
            </div>

            <div className="price-calculator_resume_card size-sm">
              <div className="card-body">
                <h5 className="price-calculator_resume_card-title">{euro.format(cif)}</h5>
                <p className="price-calculator_resume_card-text">{t('price_calculator.resume.cif')}</p>
              </div>
            </div>

            <div className="price-calculator_resume_card size-sm">
              <div className="card-body">
                <h5 className="price-calculator_resume_card-title">{euro.format(ddp)}</h5>
                <p className="price-calculator_resume_card-text">{t('price_calculator.resume.ddp')}</p>
              </div>
            </div>

            <div className="price-calculator_resume_card">
              <div className="card-body">
                <h5 className="price-calculator_resume_card-title">{euro.format(pue)}</h5>
                <p className="price-calculator_resume_card-text">{t('price_calculator.resume.total')}</p>
              </div>
            </div>
          </div>

          <div className="price-calculator_resume_card-link">
            <Link to="/pricecomparator">{t('price_calculator.resume.compare')}</Link>
          </div>
          
          {/* Primera file */}
          <div className="row mb-3 mt-4 text-light price-calculator__result">
            <div className="col-sm-3 col-md-4">
              <div className="card-body">
                <p className="price-calculator_resume-footer_title">{t('price_calculator.resume.section_1')}</p>
              </div>
            </div>
            <div className="col-sm-3 col-md-4">
              <div className="card-body">
                <p className="price-calculator_resume-footer_text">
                  {t('price_calculator.resume.section_1.title_1')}: {unf} {t('price_calculator.resume.section_1.unit')}
                </p>
                <p className="price-calculator_resume-footer_text">
                  {t('price_calculator.resume.section_1.title_2')}: 

                  
                <CountryName
                  countryOriginId={formOne.countryOriginId}
                  countriesOrigin={CountriesOrigin}
                  countriesDestination={countriesDestination}
                />


                </p>
              </div>
            </div>
            <div className="col-sm-3 col-md-4">
              <div className="card-body">
                <p className="price-calculator_resume-footer_text">
                  {t('price_calculator.resume.section_1.title_3')}: {unf * net} kg
                </p>
              </div>
            </div>
          </div>
          {/* Segunda file */}
          <div className="row mb-3 text-light price-calculator__result">
            <div className="col-sm-3 col-md-4">
              <div className="card-body">
                <p className="price-calculator_resume-footer_title">{t('price_calculator.resume.section_2')}</p>
              </div>
            </div>
            <div className="col-sm-3 col-md-4">
              <div className="card-body">
                <p className="price-calculator_resume-footer_text">
                {t('price_calculator.resume.section_2.title_1')}: {formTwo.meansTransportation}
                </p>
              </div>
            </div>
            <div className="col-sm-3 col-md-4">
              <div className="card-body">
                <p className="price-calculator_resume-footer_text">
                {t('price_calculator.resume.section_2.title_2')}: 
                  <CountryName
                    countryOriginId={formThree.countryDestinationId}
                    countriesOrigin={CountriesOrigin}
                    countriesDestination={countriesDestination}
                  />
                </p>
              </div>
            </div>
          </div>
          {/* Tercera file */}
          <div className="row mb-2 text-light price-calculator__result">
            <div className="col-sm-3 col-md-4">
              <div className="card-body">
                <p className="price-calculator_resume-footer_title">{t('price_calculator.resume.section_3')}</p>
              </div>
            </div>
            <div className="col-sm-3 col-md-4">
              <div className="card-body">
                <p className="price-calculator_resume-footer_text">
                {t('price_calculator.resume.section_3.title_1')}: {formFour.marketingSegment}
                </p>
              </div>
            </div>
            <div className="col-sm-3 col-md-4"></div>
          </div>
        </div>

        <div className="row price-calculator_resume-footer">
          <div className="col-sm-5 botonesCubicajeContainer">
            <div className="botones-price-calculator" onClick={() => downloadPdf('divToPrint', 'priceCalculator.pdf')} >
              <img className="file-pdf-solid-kDj" src={pdf} alt={t('price_calculator.resume.download_form')} />
              <div className="buttonsCubicaje">{t('price_calculator.resume.download')}</div>
            </div>
          </div>


          {!isSave && (
              
            <div
              className="col-sm-auto price-calculator_resume-footer_container price-calculator_resume-footer_container--save" 
              onClick={togglePopUp}
            >
              <div className="price-calculator_resume-footer_btn">
                {t("price_calculator.resume.save")}
              </div>
            </div>
          )}

          {isPopUpOpen && (
            <>
                {selectedItemId ? (
                    
                    <ReplaceCalculationPopup
                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}
                    togglePopUp={togglePopUp}
                    referenceName={referenceName}
                    setReferenceName={setReferenceName} 
                    setShowPopUpMessagePopUp={setShowPopUpMessagePopUp}
                    setPopUpMessage={setPopUpMessage}
                    setPopUpType={setPopUpType}
                    setPopUpTitle={setPopUpTitle}
                    calculationData={{
                      name: formOne.name || "string",
                      euroDollarRate: parseFloat(formOne.euroDollarRate || "0"),
                      tarifCode: formOne.tarifCode || "string",
                      productWeightKg: parseFloat(formOne.productWeightKg || "0"),
                      unitFobUsd: parseFloat(formOne.unitFobUsd || "0"),
                      quantityGoods: parseInt(formOne.quantityGoods || "0", 10),
                      meansTransportation: formTwo.meansTransportation || undefined,
                      internationalFreightValueUsd: parseFloat(formTwo.internationalFreightValueUsd || "0"),
                      airTotalWeightPlusPackaging: parseFloat(formTwo.airTotalWeightPlusPackaging || "0"),
                      airTarifCode: formTwo.airTarifCode || "string",
                      portExpensesUsd: parseFloat(formThree.portExpensesUsd || "0"),
                      customersExpensesValueUsd: parseFloat(formThree.customersExpensesValueUsd || "0"),
                      adValoremTax: parseFloat(formThree.adValoremTax || "0"),
                      taxDeterminedByWeight: parseFloat(formThree.taxDeterminedByWeight || "0"),
                      costTransportationPortWarehouse: parseFloat(formThree.costTransportationPortWarehouse || "0"),
                      wholesalerMargin: parseFloat(formFour.wholesalerMargin || "0"),
                      quantityMerchandiseRetailer: parseInt(formFour.quantityMerchandiseRetailer || "0", 10),
                      costTransportationWholesalerRetailerUsd: parseFloat(formFour.costTransportationWholesalerRetailerUsd || "0"),
                      marketingSegment: formFour.marketingSegment || undefined,
                      retailerMargin: parseFloat(formFour.retailerMargin || "0"),
                      userId,
                      countryOriginId: formOne.countryOriginId || "string",
                      countryDestinationId: formThree.countryDestinationId || "string",
                      containerId: formTwo.containerId || undefined,
                      portDestinationId: formTwo.portDestinationId || undefined,
                      precioFob: fob || undefined,
                      precioCif: cif || undefined,
                      precioDDP: ddp || undefined,
                      precioFinalWithoutIva: pue || undefined,
                    }}
                    />
                  
              ) : (
                  // Si no existe selectedItemId
                  <PopUp
                  title={t("price_calculator.save.title")}
                  message={t("price_calculator.save.description")}
                  isOpen={isPopUpOpen}
                  onClose={togglePopUp}
                  >

                  <div className="popup-edit">
                      <label className="popup-label" htmlFor="referenceInput">
                      {t("price_calculator.save.input_label")}
                      </label>
                      <input
                      id="referenceInput"
                      className="popup-input"
                      type="text"
                      value={referenceName}
                      onChange={(e) => setReferenceName(e.target.value)}
                      placeholder="Introduce el nombre de referencia"
                      />
                  </div>

                  <div className="popup-buttons">
                    <button 
                        className="popup-button cancel-button" 
                        onClick={togglePopUp}
                    >
                        {t("price_calculator.save.text_button_1")}
                    </button>
                      {isLoadingPriceCalculator ? (
                          <div className="price-calculator_resume-footer_loading">
                            <CircularLoading />
                          </div>
                        ): (
                          <button className="popup-button save-button" onClick={saveFormClick}>
                          {t("price_calculator.save.text_button_2")}
                        </button>
                        )}
                  </div>
                  </PopUp>
                  
                )}
            </>
          )}
            
          <div className="col-sm-auto price-calculator_resume-footer_container" onClick={handleReset}>
            <div className="price-calculator_resume-footer_btn">{t('price_calculator.resume.new')}</div>
          </div>
        </div>
      </form>
     

      <PdfDownload
        headerTitle = {t('price_calculator.header.title')}
        referenceName = {referenceName}
        
      >
        <>
          <div className="pdf_a4_content">
            <h3 className="pdf_a4_content_title">{t('price_calculator.resume.title')}</h3>
            <p className="pdf_a4_content_subtitle">{referenceName}</p>
            
            <div className="pdf_a4_table">
                <div className="pdf_a4_table_box">
                    <span className="pdf_a4_table_box_num">{euro.format(fob)}</span>
                    <span className="pdf_a4_table_box_text">{t('price_calculator.resume.fob')}</span>
                </div>
                <div className="pdf_a4_table_box">
                    <span className="pdf_a4_table_box_num">{euro.format(cif)}</span>
                    <span className="pdf_a4_table_box_text">{t('price_calculator.resume.cif')}</span>
                </div>
                <div className="pdf_a4_table_box">
                    <span className="pdf_a4_table_box_num">{euro.format(ddp)}</span>
                    <span className="pdf_a4_table_box_text">{t('price_calculator.resume.ddp')}</span>
                </div>
                <div className="pdf_a4_table_box pdf_a4_table_box_feature">
                    <span className="pdf_a4_table_box_num">{euro.format(pue)}</span>
                    <span className="pdf_a4_table_box_text">{t('price_calculator.resume.total')}</span>
                </div>
            </div>

            <div className="pdf_a4_details_content">
                <div className="pdf_a4_details">
                    <div className="pdf_a4_details_col">
                        <h4 className="pdf_a4_details_title">{t('price_calculator.resume.section_1')}</h4>
                    </div>
                    <div className="pdf_a4_details_col">
                        <p className="pdf_a4_details_text"> <span>{t('price_calculator.resume.section_1.title_1')} :</span> <span>{unf} {t('price_calculator.resume.section_1.unit')}</span></p>
                        <p className="pdf_a4_details_text"> <span>{t('price_calculator.resume.section_1.title_2')} :</span> 
                        <span>
                          <CountryName
                            countryOriginId={formOne.countryOriginId}
                            countriesOrigin={CountriesOrigin}
                            countriesDestination={countriesDestination}
                          />  
                        </span>
                        </p>
                    </div>
                    <div className="pdf_a4_details_col">
                        <p className="pdf_a4_details_text"> <span>{t('price_calculator.resume.section_1.title_3')} :</span> <span>{unf * net}</span></p>
                    </div>
                </div>
                <div className="pdf_a4_details">
                    <div className="pdf_a4_details_col">
                        <h4 className="pdf_a4_details_title">{t('price_calculator.resume.section_2')}</h4>
                    </div>
                    <div className="pdf_a4_details_col">
                        <p className="pdf_a4_details_text"> <span>{t('price_calculator.resume.section_2.title_1')} :</span> <span>{formTwo.meansTransportation}</span></p>
                        
                    </div>
                    <div className="pdf_a4_details_col">
                        <p className="pdf_a4_details_text"> <span>{t('price_calculator.resume.section_2.title_2')} :</span> 
                        <span>
                          <CountryName
                            countryOriginId={formThree.countryDestinationId}
                            countriesOrigin={CountriesOrigin}
                            countriesDestination={countriesDestination}
                          />
                        </span>
                      </p>
                    </div>
                </div>
                <div className="pdf_a4_details">
                    <div className="pdf_a4_details_col">
                        <h4 className="pdf_a4_details_title">{t('price_calculator.resume.section_3')}</h4>
                    </div>
                    <div className="pdf_a4_details_col">
                        <p className="pdf_a4_details_text"> <span>{t('price_calculator.resume.section_3.title_1')} :</span> <span>{formFour.marketingSegment}</span></p>
                    </div>
                </div>
            </div>

          </div>
          <div className="pdf_a4_num">
            <div className="pdf_a4_num_label pdf_a4_num_fob">{euro.format(fob)}</div>
            <div className="pdf_a4_num_label pdf_a4_num_cif">{euro.format(cif)}</div>
            <div className="pdf_a4_num_label pdf_a4_num_ddp">{euro.format(ddp)}</div>
            <div className="pdf_a4_num_label pdf_a4_num_pue">
              <span className="pdf_a4_num_pue_num">{euro.format(pue)}</span>
              <span className="pdf_a4_num_pue_txt" >{t('price_calculator.resume.total')}</span>
            </div>
          </div>
           
          <div className="pdf_a4_terms">

            <h4 className="pdf_a4_terms_title">{t('price_calculator.download.title')}</h4>
            <p>{t('price_calculator.download.text_1')}</p>

            <p dangerouslySetInnerHTML={{ __html: formatText(t('price_calculator.download.text_2')) }} />

            <div className="pdf_a4_terms_footer">
                <h5 className="pdf_a4_terms_subtitle">{t('price_calculator.download.subtitle')}</h5>
                <p dangerouslySetInnerHTML={{ __html: formatText(t('price_calculator.download.text_3')) }} />
            </div>
          </div>

          <div className="pdf_a4_alert">
            <div className="icon">
            <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5640_372)">
                <path d="M13.6093 10.2382V8.53484H8.90097C8.87741 8.48315 8.85254 8.43145 8.82898 8.38105C10.1563 7.41048 11.4835 6.4412 12.8108 5.47062C12.8017 5.41893 12.7925 5.36853 12.7833 5.31683H10.4586C11.4835 4.2377 12.4273 3.24386 13.4522 2.16602C12.9993 2.14405 12.5896 2.12466 11.9692 2.09494C12.9326 1.33373 13.7637 0.678497 14.6224 0C15.4706 0.672035 16.29 1.3221 17.2456 2.07943C16.6579 2.11303 16.2547 2.1363 15.7887 2.16214C16.8136 3.20121 17.7966 4.19763 18.8687 5.28194H16.4563C16.434 5.33492 16.4118 5.38662 16.3895 5.43961C17.7207 6.41276 19.0532 7.38463 20.5376 8.47023H15.6722V10.224H22.2667C22.2851 10.4837 22.3073 10.6505 22.3073 10.8172C22.3086 13.8116 22.3086 16.8073 22.3047 19.8018C22.3047 20.0835 22.4107 20.4428 21.9107 20.4428C20.1488 20.4389 18.387 20.4415 16.5283 20.4415C17.3202 19.7901 18.0558 19.237 18.7299 18.618C19.4773 17.9304 19.9944 17.0917 20.1659 16.0707C20.3085 15.2293 20.0742 14.6594 19.315 14.2239C17.9249 13.4252 16.3686 13.1887 14.8057 13.0362C11.9037 12.7532 8.99783 12.5218 6.09456 12.253C5.73067 12.2194 5.36809 12.1108 5.01991 11.9945C4.52774 11.8304 4.60104 11.5215 4.94922 11.307C5.29348 11.0937 5.6914 10.9477 6.08409 10.8288C8.09334 10.2201 10.1615 10.1878 12.2388 10.2369C12.6721 10.2472 13.1067 10.2382 13.6106 10.2382H13.6093Z" fill="#387E22"/>
                <path d="M0 10.2638H5.02247C4.36538 10.4086 3.73053 10.5365 3.10092 10.6929C2.82866 10.7601 2.52105 10.8299 2.31947 11.0005C2.04197 11.2357 1.66892 11.5704 1.67285 11.8573C1.67678 12.1533 2.03019 12.6146 2.32471 12.7064C3.35486 13.0243 4.41512 13.2763 5.48192 13.4379C7.24377 13.7067 9.02265 13.8695 10.7884 14.1151C11.2112 14.1732 11.6353 14.3335 12.0189 14.5248C13.0215 15.0262 13.2165 15.8779 12.3605 16.5758C11.4848 17.2905 10.507 17.9793 9.45722 18.3709C7.36551 19.1502 5.21489 19.8028 3.04464 20.3366C2.1035 20.5679 1.05502 20.3754 0 20.3754V10.2612L0 10.2638Z" fill="#387E22"/>
                </g>
                <defs>
                <clipPath id="clip0_5640_372">
                <rect width="22.3125" height="20.4531" fill="white"/>
                </clipPath>
                </defs>
            </svg>

            </div>
            <p className="pdf_a4_alert_text">{t('price_calculator.download.alert_text')}</p>
          </div>

          <div className="pdf_a4_footer pdf_a4_footer--col">
            <div className="pdf_a4_footer_left">
              <span className="pdf_a4_footer_link">https://www.exportopia.eu/</span>
              <span className="pdf_a4_footer_ig"><IconInstagram /> exportopia</span>
              <span className="pdf_a4_footer_in"><IconLinkedin /> Exportopia</span>
            </div>
            <div className="pdf_a4_footer_paginator">
              <span className="pdf_a4_footer_paginator_text">{t('price_comparator.download.pagination.page')}</span>
              <span className="pdf_a4_footer_paginator_actual">{ 1}</span>
              <span className="pdf_a4_footer_paginator_div"> {t('price_comparator.download.pagination.of')} </span>
              <span className="pdf_a4_footer_paginator_lenght">{ 1}</span>
            </div>
          </div>
        </>
      </PdfDownload>
    </div>
    {showPopUpMessage && (
        <PopUp onClose={handleClosePopUp} isOpen={showPopUpMessage}>
          <div className="popup-icon">
            {popUpType === 'success' ? <IconSuccess /> : <IconError />}
          </div>
          <h2 className="popup-action-title">{popUpTitle}</h2>
          {popUpType !== 'success' && (
            <p className="popup-action-message">{popUpMessage} </p>
          )}
          <div className="popup-buttons">
            {popUpType === 'success' ? (
              <button className="popup-button save-button" onClick={handleReset}>{t("price_calculator.save.button_success")}</button>
            ) : (
              <>
                <button className="popup-button cancel-button" onClick={handleClosePopUp}>{t("price_calculator.save.text_button_1_error")}</button>
                
                <button className="popup-button save-button" onClick={handleRetry}>{t("price_calculator.save.text_button_2_error")}</button>
              </>
            )}
          </div>
        </PopUp>
      )}
    </>
  );
};

export default PriceCalculatorResumen;
