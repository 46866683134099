import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PriceCalculatorStepOne from './PriceCalculatorStepOne';
import PriceCalculatorStepTwo from './PriceCalculatorStepTwo';
import PriceCalculatorStepThree from './PriceCalculatorStepThree';
import PriceCalculatorStepFour from './PriceCalculatorStepFour';
import PriceCalculatorResumen from './PriceCalculatorResume';
import './PriceCalculator.css';

interface Props {
  onShowtitle: (itemshow: boolean) => void;
  handleClose: () => void;
  handleClickOpen: () => void;
  showVideoComponent: boolean;
}

const PriceCalculator: React.FC<Props> = ({onShowtitle, handleClose, handleClickOpen , showVideoComponent}) => {
  const {t} = useTranslation()
  const [activeTab, setActiveTab] = useState(0);
  const [summaryTab, setSummaryTab] = useState(false);


  useEffect(() => {
    const selectedItemData = localStorage.getItem('selectedItemData');
    if (selectedItemData) {
      localStorage.removeItem('selectedItemData');
    } 
  }, []); 

  const tabs = [
    {
      title: t('price_calculator.tab.title_1'),
      id: 0
    },
    {
      title: t('price_calculator.tab.title_2'),
      id: 1
    },
    {
      title: t('price_calculator.tab.title_3'),
      id: 2
    },
    {
      title: t('price_calculator.tab.title_4'),
      id: 3
    },
  ];

  const handleTabClick = (index: number) => { 
    if (activeTab > index) {
      setActiveTab(index);
    }
  };

  const renderSwitch = (activeTab: Number) => {
    switch (activeTab) {
      case 0:
        return <PriceCalculatorStepOne handleClickOpen={handleClickOpen} handleContinueClick={handleContinueClick} />
      case 1:
        return <PriceCalculatorStepTwo handleClickOpen={handleClickOpen} handleContinueClick={handleContinueClick} />
      case 2:
        return <PriceCalculatorStepThree handleClickOpen={handleClickOpen} handleContinueClick={handleContinueClick} />
      case 3:
        return <PriceCalculatorStepFour handleClickOpen={handleClickOpen} handleContinueClick={handleContinueClick} />
    }
  };

  const handleContinueClick = () => {
    setSummaryTab(false);
    onShowtitle(true);
    if (activeTab < tabs.length - 1) {
      setActiveTab(activeTab + 1);
    } else {
      onShowtitle(false);
      setSummaryTab(true);
    }
  };

  useEffect(() => {
    if(!showVideoComponent) {
      resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function resetForm() {
    onShowtitle(true);
    setSummaryTab(false);
    setActiveTab(0);
    window.localStorage.removeItem("formOne");
    window.localStorage.removeItem("formTwo");
    window.localStorage.removeItem("formThree");
    window.localStorage.removeItem("formFour");
  }

  return (
    <div className="price-calculator__container">
      <div className='price-calculator__price-calculator-container-main'>
        {summaryTab ? (
          <PriceCalculatorResumen resetFormClick={resetForm} />
          ) : (
        <>
            <div className="price-calculator__tabs">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className={`tab-price-calculator ${
                    activeTab === index ? "tab-active" : ""
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  <div
                    className={`price-calculator__circle ${
                      activeTab === index ? "price-calculator__circle--active" : ""
                    }`}
                  >
                    {index + 1}
                  </div>
                  <div
                    className={`text-price-calculator ${
                      activeTab === index ? "text-price-calculator-active" : ""
                    }`}
                  >
                    {tab.title}
                  </div>
                </div>
              ))}
            </div>
            <div className="price-calc__tab-container price-calculator__tab-container">
              <div className="price-calculator-container_father">
                {renderSwitch(activeTab)}
              </div>
            </div>
        </>
        )}
      </div>
    </div>
  )
}

export default PriceCalculator;