import React, { useState } from "react";
import './SuscripcionesPacks.css';
import { useTranslation } from 'react-i18next';
import { IconPremium } from "../../components/Icons";
import { IconCkeckSolid } from "../../components/Icons/IconCkeckSolid";
import { ProductChildViewModel } from "../../api";


// Definimos el tipo de las props
interface StepProps {
    
    setStep: (number: number) => void;    
    setIsOpenCorp: (boolean: boolean) => void;
    setIsCheckOut: (boolean: boolean) => void;
    setIsOpen: (boolean: boolean) => void;
    data: ProductChildViewModel[];
    setProductId: (id: number) => void; 
    productIdsUses: number[];
    productParentName: string;

  }
  
export const SuscripcionesPacks = ({ setStep, setIsOpen, setIsOpenCorp, setIsCheckOut, data , setProductId, productIdsUses, productParentName }: StepProps) => {
    const {t} = useTranslation()
    // console.log("🚀 ~ SuscripcionesPacks ~ data:", data)

  const handleOpenCheckOut = (productId: number) => {
    setIsCheckOut(true);
    setStep(2)
    setProductId(productId); 

  };
  const handleOpenCorp = () => {
    setIsOpenCorp(true);
    setIsOpen(false); 
  };

  return <>

    
    <div className="packs">
        <div className="packs_header">

            <h2 className="packs_title">
                {productParentName === "Price Calculator" 
                    ? t('suscripcion.modal_packs.title_calculadora')
                    : t('suscripcion.modal_packs.title_comparador')
                } 
                <span className="packs_title_label"><IconPremium className="packs_title_label_icon"/> </span>
            </h2>
            <p className="packs_subtitle">                
              {productParentName === "Price Calculator" ? t('suscripcion.modal_packs.description_calculadora') : t('suscripcion.modal_packs.description_comparador')}
              
            </p>
        </div>
        <div className="packs_body">
            <div className="packs_items">
                
            {data.map((plan) => (
                <div
                key={plan.id}
                className={`packs_item ${plan.name === "Corporativo" ? "packs_item--grey" : ""}`}
                >
                <div className="packs_items_top">
                    <h3 className="packs_items_title">{plan.name}</h3>
                    <p className="packs_items_desc">
                    {plan.uses && plan.uses.includes(">") ? "+50 " : plan.uses} {" "}
                    {productParentName === "Price Calculator" && t('suscripcion.modal_packs.calculations')}
                    
                     
                    </p>
                </div>
                <div className="packs_items_bottom">
                    {plan.name !== "Corporativo" ? (
                    <div className="packs_items_info">
                        <div className="packs_unique">
                        <p className="packs_unique_title">{t('suscripcion.modal_packs.pack_unique')}</p>
                        <p className="packs_unique_price">{plan.price} USD</p>
                        </div>
                    </div>
                    ) : (
                    <div className="packs_unique"></div>
                    )}
                    <button
                      type="button"
                      className={`packs_items_button ${plan.name === "Corporativo" ? "packs_items_button--grey" : ""}`}
                      onClick={plan.name === "Corporativo" ? handleOpenCorp : () => handleOpenCheckOut(plan.id || 0)}
                    >
                      {plan.name === "Corporativo" ? t('suscripcion.modal_packs.button_consult') : t('suscripcion.modal_packs.button_buy')}
                    </button>
                </div>

                {/* Para desactivar los ya usados */}
                {productIdsUses.includes(plan.id ?? 0) && (
                    <div className="packs_item_overflow">
                        <div className="packs_item_overflow_circle">
                            <IconCkeckSolid className="packs_item_svg" />
                        </div>
                    </div>
                )}
                </div>
            ))}
                

            </div>
        </div>
        {productParentName === "Price Calculator" ? ( 
            <div className="packs_footer" dangerouslySetInnerHTML={{ __html: t('suscripcion.modal_packs.footer_calculadora') }} />
        ) : (
            <div className="packs_footer" dangerouslySetInnerHTML={{ __html: t('suscripcion.modal_packs.footer_comparador') }} />
        )}
        
    </div>

    
  </>;
};
