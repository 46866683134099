import React, { useState, useEffect, useRef } from 'react';
import IconFlagEs from '../Icons/IconFlagEs';
import IconFlagEn from '../Icons/IconFlagEn';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import './LangDropdown.css';

export const LangDropdown: React.FC = () => {
  const { i18n } = useTranslation();
  const [isDropdownUserOpen, setIsDropdownUserOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Función para alternar el dropdown
  const toggleDropdownUser = () => {
    setIsDropdownUserOpen((prev) => !prev);
  };

  // Función para cambiar el idioma y cerrar el dropdown
  const toggleLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setIsDropdownUserOpen(false); // Cierra el dropdown al cambiar el idioma
  };

  // Cierra el dropdown si se hace clic fuera
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current && 
        event.target instanceof Node && 
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownUserOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="lang-dropdown" ref={dropdownRef}>
      <button
        type="button"
        className="lang-dropdown__toggle"
        onClick={toggleDropdownUser}
      >
        {i18n.language === 'es' ? <IconFlagEs /> : <IconFlagEn />}
        <FontAwesomeIcon
          className="lang-dropdown__icon"
          icon={isDropdownUserOpen ? faCaretUp : faCaretDown}
          color="#FFFFFF"
        />
      </button>
      <div className={`lang-dropdown__content ${isDropdownUserOpen ? "active" : ""}`}>
        <button
          type="button"
          className={`lang-dropdown__lang ${i18n.language === "es" ? "selected" : ""}`}
          onClick={() => toggleLanguage('es')}
        >
          <IconFlagEs />
        </button>
        <button
          type="button"
          className={`lang-dropdown__lang ${i18n.language === "en" ? "selected" : ""}`}
          onClick={() => toggleLanguage('en')}
        >
          <IconFlagEn />
        </button>
      </div>
    </div>
  );
};
