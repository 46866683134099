/* eslint-disable react/style-prop-object */
import React, { FC, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faMagnifyingGlass,
  faBookmark,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faLinkedin,
  faSquareFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import logo from "../../assets/images/logo.svg";
import perfil from "../../assets/images/perfil.svg";
import preferencias from "../../assets/images/preferencias.svg";
import guardados from "../../assets/images/guardados.svg";
import notificaciones from "../../assets/images/notificaciones.svg";
import servicios from "../../assets/images/servicios.svg";
import nosotros from "../../assets/images/nosotros.svg";
import suggestions from "../../assets/images/suggestions.svg";
import "./Header.css";
import i18n from "../../i18n";
import { Button, Icon } from "@mui/material";
import {IconExporterTest, IconCubicaje, IconCalcularPrecios, IconPriceComparator, IconPreferencias, IconLogo, IconLogoMobile} from "../Icons";
import HeaderDropdownMobile from "./HeaderDropdownMobile";
import { IconSuscripciones } from "../Icons/IconSuscripciones";
import { useGetApiNews } from "../../api/news.api";
import IconClose from "../Icons/IconClose";
import { LangDropdown } from "../LangDropdown/LangDropdown";
import IconFlagEs from "../Icons/IconFlagEs";
import IconFlagEn from "../Icons/IconFlagEn";



const Header: FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useAuth();
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [isDropdownUserOpen, setIsDropdownUserOpen] = useState(false);
  const [isDropdownCompanyOpen, setIsDropdownCompanyOpen] = useState(false);
  const userFullName = localStorage.getItem("alias");
  const userEmail = localStorage.getItem("username");
  const navigate = useNavigate();
  const [isActiveSearch, setIsActiveSearch] = useState(false);

  
  const dropdownUserRef = useRef<HTMLDivElement>(null);
  const buttonUserRef = useRef<HTMLDivElement>(null);
    
  const dropdownCompanyRef = useRef<HTMLDivElement>(null);
  const buttonCompanyRef = useRef<HTMLDivElement>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const { data: dataNewsList, mutate: newsList } = useGetApiNews({}); // Pass an empty object as the argument to the newsList // Manejar cambios en el input
  const handleChange = (e: { target: { value: any; }; }) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Llamar a la API si hay 3 o más caracteres
    if (value.length >= 3) {
      newsList({}); // Pasamos un objeto vacío
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && searchTerm.length >= 3) {
      navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
      setSearchTerm(""); // Limpiar el input después de la búsqueda
    }
  };
  const handleSubmit = () => {
    navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
    setSearchTerm(""); // Limpiar el input después de la búsqueda
    
  };
  

  
  // Filtrar noticias por título
  const filteredNews = searchTerm.length >= 3
    ? dataNewsList?.filter((news) =>
        news.title.toLowerCase().includes(searchTerm.toLowerCase())
      ) || []
    : [];

  // Redirigir al hacer clic en una noticia
  const handleClick = (id: any) => {
    setSearchTerm(""); // Limpiar el input
    navigate(`/noticia/${id}`); // Redirige a la página de la noticia
  };
  
  
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    dispatch({ type: "LOGOUT" });
    setIsLandingPage(true);
    navigate("/home");
  };

  const handleLogIn = () => {
    navigate("/login");
    setIsLandingPage(false);
  };

  const handleHome = () => {
    navigate("/home");
    setIsLandingPage(true);
  };

  const handleDropdownCompanyToggle = () => {
    setIsDropdownCompanyOpen(!isDropdownCompanyOpen);
  };

  const handleDropdownUserToggle = () => {
    setIsDropdownUserOpen(!isDropdownUserOpen);
    
  };
  


  // Maneja los clics fuera del dropdown User
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        if (
        dropdownUserRef.current && !dropdownUserRef.current.contains(event.target as Node) &&
        buttonUserRef.current &&
        !buttonUserRef.current.contains(event.target as Node)
    ) {
          setIsDropdownUserOpen(false);
        }
    };

    if (isDropdownUserOpen) {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);
    }

    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("touchstart", handleClickOutside);
    };
}, [isDropdownUserOpen]);


 
  // Maneja los clics fuera del dropdown Company
    useEffect(() => {
      const handleClickCompanyOutside = (event: MouseEvent | TouchEvent) => {
          if (
          dropdownCompanyRef.current && !dropdownCompanyRef.current.contains(event.target as Node) &&
          buttonCompanyRef.current &&
          !buttonCompanyRef.current.contains(event.target as Node)
      ) {
          setIsDropdownCompanyOpen(false);
          }
      };

      if (isDropdownCompanyOpen) {
          document.addEventListener("mousedown", handleClickCompanyOutside);
          document.addEventListener("touchstart", handleClickCompanyOutside);
      }

      return () => {
          document.removeEventListener("mousedown", handleClickCompanyOutside);
          document.removeEventListener("touchstart", handleClickCompanyOutside);
      };
  }, [isDropdownCompanyOpen]);
  
  const inputRef = useRef<HTMLInputElement>(null);
  const toggleActiveSearch = () => {
    setIsActiveSearch(!isActiveSearch);

  };
  useEffect(() => {
    // Establecer foco en el input cuando isActiveSearch es true
    if (isActiveSearch && inputRef.current) {
        inputRef.current.focus();
    }
}, [isActiveSearch]); 
  
  return (
    <>
      {state.isAuthenticated ? (
        <header className="navbar navbar-expand-lg">
          <div className="navbar__row">
              <div className={"header__top"}>
                <div
                  className=" header__logo"
                  ref={buttonCompanyRef}
                  onClick={handleDropdownCompanyToggle}
                >
                
                    <IconLogo className={`hide-mobile interconectar-menu ${
                      state.isAuthenticated ? "" : "interconectar-menu-login"
                    }`} />

                    <IconLogoMobile  className="hide-desktop " />
                
                    {state.isAuthenticated && (
                        <FontAwesomeIcon
                          icon={isDropdownCompanyOpen ? faCaretUp : faCaretDown}
                          color="#FFFFFF"
                        />
                      )}
                      
                </div>
                
                <div className="header__right">
                      

                {state.isAuthenticated && (
                  <>
                    <div className="frame-20 header__home" onClick={handleHome}>
                      <FontAwesomeIcon icon={faHome} color="#e3efea" />
                    </div>
                    <div className="header__search__toggle"  onClick={toggleActiveSearch}>
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          color="#FFFFFF"
                        />
                      </div>
                    <div className={`header__search ${isActiveSearch ? "active" : ""}`}>
                    <input
                      type="text"
                      ref={inputRef}
                      className="buscar-noticia header__search__input"
                      placeholder="Buscar noticia..."
                      value={searchTerm}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown} // Agregar el evento para detectar Enter

                      />
                      {filteredNews.length > 0 && (
                        <ul className="filter-search">
                          {filteredNews.map((news) => (
                            <li
                              key={news.id}
                              className="filter-search__item"
                              onClick={() => handleClick(news.id)}
                            >
                              <div className="filter-search__figure">
                                <img  src={news.image} alt={news.title} />
                              </div>
                              <div className="filter-search__data">

                              <h2 className="search__title">{news.title}</h2>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                      <button type="button" className="frame-21" onClick={handleSubmit}>
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          color="#FFFFFF"
                        />
                      </button>
                      <button type="button" className="header__search__close" onClick={toggleActiveSearch}>
                        <IconClose fill="#364651" />
                      </button>
                    </div>
                    <HeaderDropdownMobile />
                    <div className="header__tools">
                      <div className="header__tools__text">{t("header.tools")}</div>
                      <div className="header__tools__block">
                        <Link to="/exportertest">
                          <IconExporterTest fill="#425563"  />
                        </Link>
                      </div>
                      <div className="header__tools__block">
                        <Link to="/cubicaje">
                          <IconCubicaje />
                        </Link>
                      </div>
                      <div className="header__tools__block">
                        <Link to="/calcularprecios">
                          <IconCalcularPrecios />
                        </Link>
                      </div>
                      <div className="header__tools__block">
                        <Link to="/pricecomparator">
                          <IconPriceComparator />
                        </Link>
                      </div>
                    </div>
                    <div
                      className={`${
                        state.isAuthenticated ? "header__account" : "frame-login"
                      }`}
                    >
                      <div className="header__account__text">{t("header.account")}</div>
                      <div className="header__account__preferences">
                        <IconPreferencias />
                      </div>
                      <div className="header__account__bookmark">
                        <FontAwesomeIcon icon={faBookmark} color="#425563" />
                      </div>
                      <div className="header__user" 
                      ref={buttonUserRef}
                      onClick={handleDropdownUserToggle}
                      
                      
                      >
                        <div className="frame-31">
                          <div className="d">{userFullName?.charAt(0)}</div>
                        </div>
                        <FontAwesomeIcon
                          icon={isDropdownUserOpen ? faCaretUp : faCaretDown}
                          color="#FFFFFF"
                        />
                      </div>
                    </div>
                  </>
                )}
                {state.isAuthenticated && isDropdownCompanyOpen && (
                  <div
                    ref={dropdownCompanyRef}
                    onClick={handleDropdownCompanyToggle}
                    className={`dropdown-menu-company ${
                      isDropdownCompanyOpen ? "open" : ""
                    }`}
                  >
                    <div className="frame-80">
                      <div
                        className="frame-79"
                        onClick={() => navigate("/acerca-interconectar")}
                      >
                        <img src={nosotros} alt="Link Solid" />
                        <div className="text-btn">{t("header.about")}</div>
                      </div>
                      <div
                        className="frame-78"
                        onClick={() => navigate("/servicios")}
                      >
                        <img src={servicios} alt="Link Solid" />
                        <div className="text-btn">{t("header.services")}</div>
                      </div>
                      <div className="frame-81">
                        <img src={suggestions} alt="Link Solid" />
                        <div className="text-btn">
                          {t("header.suggestions")}
                        </div>
                      </div>
                    </div>
                    <div className="frame-83">
                      <div className="frame-98">
                        <FontAwesomeIcon icon={faInstagram} />
                      </div>
                      <div className="frame-99">
                        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/exportopia/">
                          <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                      </div>
                      <div className="frame-100">
                        <FontAwesomeIcon icon={faSquareFacebook} />
                      </div>
                      <div className="frame-101">
                        <a target="_blank" rel="noreferrer" href="https://youtube.com/@exportopiaoficial?si=27tsAYLZOajDJI2q">
                          <FontAwesomeIcon icon={faYoutube} />
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                {isDropdownUserOpen && (
                  <div
                    ref={dropdownUserRef}
                    
                    className={`dropdown__user ${
                      isDropdownUserOpen ? "open" : ""
                    }`}
                  >
                    <div className="dropdown__user__header">
                      <div className="dropdown__user__name">
                        <div className="dropdown__user__icon ">
                          <div className="dropdown__user__inner ">
                            {userFullName?.charAt(0)}
                          </div>
                        </div>
                        <div className="dropdown__user__language ">
                          {/* <img
                        src="https://cdn-icons-png.flaticon.com/512/197/197593.png"
                        alt="ES"
                      /> */}
                          <button
                            onClick={() => i18n.changeLanguage("es")}
                            className={i18n.language === "es" ? "btnlang-selected btnlang" : "btnlang"}

                          >
                            <IconFlagEs />
                          </button>

                          <button
                            onClick={() => i18n.changeLanguage("en")}
                            className={i18n.language === "en" ? "btnlang-selected btnlang" : "btnlang"}
                            
                          >
                            <IconFlagEn />
                          </button>
                          {/* <FontAwesomeIcon icon={faCaretDown} color="white" /> */}
                        </div>
                      </div>
                      <div className="dropdown__user__info">
                        <div className="dropdown__user__title">{userFullName}</div>
                        <div className="dropdown__user__email">{userEmail}</div>
                      </div>
                    </div>
                    <div className="dropdown__user__otions" onClick={handleDropdownUserToggle}>
                      <div className="dropdown__user__option">
                        <div className="dropdown__user__inner">
                          <img src={perfil} alt="Link Solid" />
                        </div>
                        <div className="dropdown__user__text">
                          {t("header.profile.account")}
                        </div>
                      </div>
                      <Link
                        to="/preferencias"
                        className="link linkPreferencesText"
                      >
                        <div className="dropdown__user__option option">
                          <div className="dropdown__user__inner">
                            <img src={preferencias} alt="Link Solid" />
                          </div>
                          <div className="dropdown__user__text">
                            {t("header.profile.preferences")}
                          </div>
                        </div>
                      </Link>
                      <div className="dropdown__user__option option">
                        <div className="dropdown__user__inner">
                          <img src={guardados} alt="Link Solid" />
                        </div>
                        <div className="dropdown__user__text">{t("header.profile.saved")}</div>
                      </div>
                      <div className="dropdown__user__option option">
                        <div className="dropdown__user__inner">
                          <img src={notificaciones} alt="Link Solid" />
                        </div>
                        <div className="dropdown__user__text">
                          {t("header.profile.notifications")}
                        </div>
                      </div>
                      <div className="dropdown__suscripciones">
                      <Link
                        to="/suscripciones"
                        className="link link-suscripciones"
                      >
                        <IconSuscripciones />
                        
                        {t("header.profile.packs")}
                      </Link>
                      
                      </div>
                    </div>
                    <div className="dropdown__user__footer">
                      <div className="dropdown__user__guide ">
                        {t("header.profile.guide")}
                        <div className="dropdown__user__inner icon-inner">
                          <img
                            src="https://static.vecteezy.com/system/resources/previews/009/592/988/large_2x/3d-rendering-blue-and-yellow-color-user-icon-isolated-free-png.png"
                            alt="guia"
                            height={"20px"}
                          />
                        </div>
                        <FontAwesomeIcon icon={faCaretDown} />
                      </div>
                      <Link
                        to="/home"
                        className="logout"
                        onClick={handleLogout}
                      >
                        {t("header.profile.logout")}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              </div>
          </div>
        </header>
      ) : (
        <header className="navbar navbar navbar-expand-lg">
          <div className="navbar__row ">
                <div
                  className=" header__logo"
                  onClick={handleDropdownCompanyToggle}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    onClick={handleHome}
                    className={`interconectar-menu ${
                      state.isAuthenticated ? "" : "interconectar-menu-login"
                    }`}
                  />
                </div>

                <div className="navbar__right">
                    {!state.isAuthenticated && (
                      <div className="d-flex">
                        <div className="header__btn-login" onClick={handleLogIn}>
                          <div>{t("header.login")}</div>
                        </div>
                        {/* <div className="language-icon">
                          <FontAwesomeIcon icon={faCaretDown} color="white" />
                        </div> */}
                      </div>
                    )}

                    <LangDropdown />
                </div>

                {/* {!state.isAuthenticated && !isLandingPage && (
                  <div className="header__contenedor-img-arrow-lng">
                    <div className="language-icon">
                      <FontAwesomeIcon icon={faCaretDown} color="white" />
                    </div>
                  </div>
                )} */}
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
