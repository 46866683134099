import * as React from 'react';

type IconPremiumProps = React.SVGProps<SVGSVGElement>;

export const IconPremium: React.FC<IconPremiumProps> = (props) => (
	<svg width={16} height={13} viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M8.27679 2.48214C8.58214 2.29464 8.78571 1.95446 8.78571 1.57143C8.78571 0.979464 8.30625 0.5 7.71429 0.5C7.12232 0.5 6.64286 0.979464 6.64286 1.57143C6.64286 1.95714 6.84643 2.29464 7.15179 2.48214L5.61696 5.55179C5.37321 6.03929 4.74107 6.17857 4.31518 5.83839L1.92857 3.92857C2.0625 3.74911 2.14286 3.52679 2.14286 3.28571C2.14286 2.69375 1.66339 2.21429 1.07143 2.21429C0.479464 2.21429 0 2.69375 0 3.28571C0 3.87768 0.479464 4.35714 1.07143 4.35714C1.07679 4.35714 1.08482 4.35714 1.09018 4.35714L2.31429 11.0911C2.46161 11.9054 3.17143 12.5 4.00179 12.5H11.4268C12.2545 12.5 12.9643 11.908 13.1143 11.0911L14.3384 4.35714C14.3438 4.35714 14.3518 4.35714 14.3571 4.35714C14.9491 4.35714 15.4286 3.87768 15.4286 3.28571C15.4286 2.69375 14.9491 2.21429 14.3571 2.21429C13.7652 2.21429 13.2857 2.69375 13.2857 3.28571C13.2857 3.52679 13.3661 3.74911 13.5 3.92857L11.1134 5.83839C10.6875 6.17857 10.0554 6.03929 9.81161 5.55179L8.27679 2.48214Z" fill="#1A2228"/>
	</svg>


);


