import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { putApiPriceCalculatorId, usePostApiPriceCalculator } from "../../api";
import CircularLoading from "../../shared/CircularLoading/CircularLoading";
import PopUp from "../../shared/PopUp/PopUp";
import IconCalculo from "../Icons/IconCalculo";
import { ReplaceCalculationPopupProps } from "./PriceCalculator.types";



const ReplaceCalculationPopup: React.FC<ReplaceCalculationPopupProps> = ({
    isPopUpOpen,
    setIsPopUpOpen,
    togglePopUp,
    referenceName,
    setReferenceName,
    calculationData,
    setShowPopUpMessagePopUp,
    setPopUpMessage,
    setPopUpType,
    setPopUpTitle
}) => {
    const {t} = useTranslation()

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { mutate: priceCalculatorSave, isLoading: isLoadingPriceCalculator } = usePostApiPriceCalculator();

    
    if (!isPopUpOpen) return null;


    const handleButtonSave = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const updatedCalculationData = {
            ...calculationData,
            name: referenceName
        };

        priceCalculatorSave({
            data: updatedCalculationData
          }, {
            onSuccess(data, variables, context) {
                setPopUpMessage(t('price_calculator.step_4.popup_replace.success_text'));  
                setPopUpTitle(t("price_calculator.save.title_success"));
                setPopUpType('success');
                setIsLoading(false);
                setIsPopUpOpen(false);
                setShowPopUpMessagePopUp(true);  
            },
            onError(error, variables, context) {
                setPopUpMessage(t("price_calculator.save.title_error"));
                setPopUpTitle(t("price_calculator.save.text_error"));
                setErrorMessage(error.message || "Ocurrió un error inesperado.");
                setPopUpType('error');
                setIsLoading(false);
                setIsPopUpOpen(false);
                setShowPopUpMessagePopUp(true);  
            },
          });
    }
    
    

    const handleButtonEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!referenceName) {
            setErrorMessage("El campo de nombre de referencia no puede estar vacío.");
            return;
        }

        const updatedCalculationData = {
            ...calculationData,
            name: referenceName
        };
        
        setIsLoading(true);
        setErrorMessage(null);
        const storedItemData = localStorage.getItem("selectedItemData");
        let id = "";

        if (storedItemData) {
            try {
                const parsedData = JSON.parse(storedItemData);
                id = parsedData.id;
            } catch (error) {
                console.error("Error parsing localStorage data: ", error);
            }
        }

        if (id) {
            putApiPriceCalculatorId(id, updatedCalculationData)
                .then(response => {
                   
                    setPopUpMessage(t('price_calculator.step_4.popup_replace.success_text'));  
                    setPopUpTitle(t('price_calculator.step_4.popup_replace.success_title'));
                    setPopUpType('success');
                    setIsLoading(false);
                    setIsPopUpOpen(false);
                    setShowPopUpMessagePopUp(true);  
                })
                .catch(error => {
                    
                    setPopUpMessage(t('price_calculator.step_4.popup_replace.error_text'));  
                    setPopUpTitle(t('price_calculator.step_4.popup_replace.error_title'));
                    setErrorMessage(error.message || "Ocurrió un error inesperado.");
                    setPopUpType('error');
                    setIsLoading(false);
                    setIsPopUpOpen(false);
                    setShowPopUpMessagePopUp(true);  
                });
        } 
    };

    return (
        <PopUp
            title={t('price_calculator.step_4.popup_replace.title')}
            message={t('price_calculator.step_4.popup_replace.description')}
            isOpen={isPopUpOpen}
            onClose={togglePopUp}
        >
            
            {errorMessage && (
                <p>{errorMessage}</p>
            )}
            {/* <div className="popup-replace">
                <IconCalculo className="popup-replace-icon" />
                <input
                    id="referenceInput"
                    className="popup-input-replace"
                    type="text"
                    value={referenceName}
                    onChange={(e) => setReferenceName(e.target.value)}
                    placeholder={t('price_calculator.step_4.popup_replace.placeholder')}
                />
            </div> */}

            <div className="popup-buttons">
                
                {isLoading || isLoadingPriceCalculator ? (
                    <div className="replace-loading">
                        <CircularLoading />
                    </div>
                ): (
                    <>
                    
                    <button     
                        className="popup-button replace-button"
                        onClick={handleButtonEdit}
                    >
                        {t('price_calculator.step_4.popup_replace.button_2')}
                    </button>
                    <button
                        className="popup-button  save-button"
                        onClick={handleButtonSave}
                    >
                        {t('price_calculator.step_4.popup_replace.button_1')}
                    </button>
                    </>
                )}
                
            </div>
        </PopUp>
    );
};

export default ReplaceCalculationPopup;
