import * as React from "react";
type IconCkeckProps = React.SVGProps<SVGSVGElement>;

export const IconCkeck: React.FC<IconCkeckProps> = (props) => (
  <svg width={16} height={18} viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg"  fill="white"  {...props}>
  <g clip-path="url(#clip0_949_19560)">
  <path d="M13.7066 3.2937C14.0973 3.68433 14.0973 4.3187 13.7066 4.70933L5.70664 12.7093C5.31602 13.1 4.68164 13.1 4.29102 12.7093L0.291016 8.70933C-0.0996094 8.3187 -0.0996094 7.68433 0.291016 7.2937C0.681641 6.90308 1.31602 6.90308 1.70664 7.2937L5.00039 10.5843L12.2941 3.2937C12.6848 2.90308 13.3191 2.90308 13.7098 3.2937H13.7066Z" />
  </g>
  <defs>
  <clipPath id="clip0_949_19560">
  <rect width="14" height="16" />
  </clipPath>
  </defs>
  </svg>
  
);
export default IconCkeck;
