import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Flag to track refresh token requests
let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

// Crear una variable para almacenar el dispatch
let authDispatch: any = null;

// Función para establecer el dispatch
export const setAuthDispatch = (dispatch: any) => {
    authDispatch = dispatch;
};

const onTokenRefreshed = (token: string) => {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

// Request interceptor to attach token
AXIOS_INSTANCE.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Response interceptor to handle token refresh
AXIOS_INSTANCE.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addRefreshSubscriber((token) => {
            if (originalRequest.headers) {
              originalRequest.headers.Authorization = `Bearer ${token}`;
            }
            resolve(AXIOS_INSTANCE(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;


      try {
        const { data } = await Axios.post<any>(
          `${process.env.REACT_APP_API_URL}/api/User/refresh-token`,
          { refreshToken: localStorage.getItem("refreshToken") }
        );

        localStorage.setItem('token', data.result?.accessToken);
        localStorage.setItem('refreshToken', data.result?.refreshToken);
        onTokenRefreshed(data.result?.accessToken);

        if (authDispatch) {
            authDispatch({ type: "LOGIN", payload: data.result?.accessToken });
        }
        
        isRefreshing = false;

        originalRequest.headers = originalRequest.headers || {};
        originalRequest.headers.Authorization = `Bearer ${data.result?.accessToken}`;
        return AXIOS_INSTANCE(originalRequest);
      } catch (refreshError) {
        isRefreshing = false;
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");

        if (authDispatch) {
            authDispatch({ type: "LOGOUT" });
        }

        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

// Custom instance function
export const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(
    ({ data }) => data
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by react Query');
  };

  return promise;
};

export default customInstance;

export interface ErrorType<Error> extends AxiosError<Error> {}
