import { useTranslation } from 'react-i18next';
import { IconMailSucces } from '../../components/Icons/IconMailSucces';
import './SuscripcionesChoutSucces.css';

interface StepProps {
	setStep: (number: number) => void;
	setIsOpen: (value: boolean) => void;
	setIsCheckOut: (value: boolean) => void;
	refetch: () => void;
}
export const SuscripcionesChoutSucces = ({ setStep, setIsOpen, setIsCheckOut, refetch }: StepProps) => {
	const {t} = useTranslation()
	

	const handleClick = () => {
		refetch(); // Refresh the packsUser data
		setIsOpen(false); // Activa el pack
		setIsCheckOut(false); // Activa el pack
		setStep(1); // Avanza al siguiente paso
	};

	return (
		<>
			<div className="chs_modal">
				<div className="chs_modal_icon">
					<IconMailSucces />
				</div>
				<h3 className="chs_modal_title">{t('suscripcion.modal_chout_succes.title')}</h3>
				<div className="chs_modal_body" dangerouslySetInnerHTML={{ __html: t('suscripcion.modal_chout_succes.body') }} />
				<div className="chs_modal_actions">
					<button
						type="button"
						className="chs_modal_button"
						onClick={handleClick}>
						{t('suscripcion.modal_chout_succes.button')}
					</button>
				</div>
			</div>
		</>
	);
};
